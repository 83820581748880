import { Component, Input, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MathfieldElement } from 'mathlive';
import { Subscription } from 'rxjs';

import { MathService } from '../../shared/commands/math.service';
import { MapStateService } from '../../shared/map-state.service';
import { SmService } from 'supermappe-core';
import { CALLER, TtsService } from 'src/app/shared/commands/tts.service';
import { MathKeyboardService } from 'src/app/shared/virtual-keyboards/math-keyboard.service';
import { UserPreferenceService } from 'src/app/shared/user-preference.service';
import { ConfirmationService } from 'src/app/shared/dialog/confirmation.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';


@Component({
    selector: 'app-math-editor',
    templateUrl: './math-editor.component.html',
    styleUrls: ['./math-editor.component.scss']
})
export class MathEditorComponent implements OnInit, OnDestroy {

    @Input() latex: string = '';

    private mathToggleRequestSubscription: Subscription | undefined;
    private onErrorImageEmitterSubscription: Subscription | undefined;
    private onClearErrorSubscription: Subscription | undefined;
    private onSetLatexInEditorSubscription: Subscription | undefined;
    private onUserPrefsChangeSubscription: Subscription | undefined;

    private mfe: MathfieldElement | null = null;
    private suggestionPopOver: HTMLElement | null = null;
    private cosoPopOver: HTMLElement | null = null;
    private keyboardContainer: HTMLElement | null = null;

    public isLatexMode: boolean = false;

    public isLatexChanged: boolean = false;

    public descErrorFormula: string = '';
    public descErrorImage: string = '';
    private isSelectionChanged: boolean = false;

    private originalMenuItems: any;

    //TTS
    public isTalking: boolean = false;
    private onTTSTalkingChanged: Subscription | undefined;
    private onTTSTalkingStart: Subscription | undefined;
    private onTTSTalkingEnd: Subscription | undefined;

    //LAYOUT
    public isHeaderCompressed = false;

    constructor(
        public mathService: MathService,
        private smService: SmService,
        private mapStateService: MapStateService,
        private translateService: TranslateService,
        private ttsService: TtsService,
        private mathKeyboardService: MathKeyboardService,
        private userPreferenceService: UserPreferenceService,
        private confirmationService: ConfirmationService,
        private breakpointObserver: BreakpointObserver
    ) {
        (window as any).mathEditor = this;
    }

    ngOnInit(): void {
        this.mfe = this.mathService.getMathFieldElement();
        if (this.mfe) {
            this.keyboardContainer = document.getElementById('keyboard-container');
            this.mfe.mathVirtualKeyboardPolicy = 'manual';
            this.originalMenuItems = [...this.mfe.menuItems];
        }

        // Richiede le tastiere al service da potenziare magari con un parametro in input del componente o in base alla configurazione utente
        const language = localStorage.getItem('locale') || 'it';
        window.mathVirtualKeyboard.layouts = this.mathKeyboardService.getKeyboardLayouts(language);

        //this.mfe.menuItems = [];
        if (this.keyboardContainer)
            window.mathVirtualKeyboard.container = this.keyboardContainer;

        this.mathToggleRequestSubscription = this.mathService.mathToggleRequest.subscribe((open: boolean) => {
            //console.log('IS MATH OPEN: ' + this.mathService.isMathOpen);
        });

        this.onErrorImageEmitterSubscription = this.mathService.onErrorImageEmitter.subscribe((error) => {
            this.descErrorImage = error || this.translateService.instant('MATH_ERROR_IMAGE');
        });

        this.onClearErrorSubscription = this.mapStateService.onClearMathErrors.subscribe({
            next: () => {
                this.descErrorFormula = '';
                this.descErrorImage = '';
                this.isSelectionChanged = true;
                this.updateLatexChanged();
            }
        });

        this.onSetLatexInEditorSubscription = this.mathService.onSetLatexInEditor.subscribe({
            next: (openKeyboard: boolean) => {
                this.descErrorFormula = '';
                this.descErrorImage = '';
                this.updateLatexChanged();
                if (openKeyboard)
                    this.mfe?.focus();
                else {
                    this.mfe?.blur();
                    window.mathVirtualKeyboard.hide();
                }
            }
        });

        this.onUserPrefsChangeSubscription = this.userPreferenceService.onUserPrefsChange.subscribe((value: boolean) => {
            if (value) {
                const prefs = this.userPreferenceService.userPrefs;
                if (prefs && prefs.math.keyboardLayouts) {
                    const language = localStorage.getItem('locale') || 'it';
                    window.mathVirtualKeyboard.layouts = this.mathKeyboardService.getKeyboardLayouts(language);
                }
            }
        });

        this.breakpointObserver
            .observe(['(max-width:600px)'])
            .subscribe((state: BreakpointState) => {
                this.isHeaderCompressed = state.matches;
            });

        this.onTTSTalkingStart = this.ttsService.TalkingStart.subscribe(() => {
            this.isTalking = true;
        });

        this.onTTSTalkingEnd = this.ttsService.TalkingEnd.subscribe(() => {
            this.isTalking = false;
        });

        this.onTTSTalkingChanged = this.ttsService.isTalkingStateChanged.subscribe((talking: boolean) => {
            this.isTalking = talking;
        });


        this.registerMathFieldEvents();
    }

    updateLatexChanged() {
        const startLatex: string = this.mathService.startLatex;
        const currentLatex: string = this.mfe?.getValue() || '';
        this.isLatexChanged = (currentLatex !== startLatex) || this.isSelectionChanged;
        // console.log('isLatexChanged: ' + this.isLatexChanged);
        this.isSelectionChanged = false;
    }

    onConfirmToolPressed() {
        if (this.mfe?.mode === 'latex')
            this.mfe.executeCommand('complete');
        setTimeout(() => {
            this.descErrorImage = '';
            this.mathService.commitEdit(false);
        }, 10);

    }

    onCancelToolPressed() {
        this.mathService.setLatexInEditor('');
        this.descErrorFormula = '';
        this.descErrorImage = '';
    }

    onCloseToolPressed() {
        this.mathService.openMath(false);
    }

    onToggleLatexModePressed() {
        this.toggleLatexMode();
    }

    toggleLatexMode() {
        if (this.mfe) {
            this.mfe.executeCommand('selectAll');
            if (this.mfe.mode === 'math') {
                this.mfe.executeCommand(['switchMode', 'latex']);
                this.mfe.executeCommand('moveToMathfieldEnd');
                this.mfe.insert(' ', { focus: true, insertionMode: 'insertAfter', scrollIntoView: true });
                this.mfe.menuItems = [];
            } else {
                //this.mfe.executeCommand(['switchMode', 'math']); // per monito futuro...questa chiamata fa cose strane se il campo e' in latex
                this.mfe.executeCommand('complete');//questo evento forza la fine della scrittura in latex
                this.mfe.focus();
                this.mfe.executeCommand('moveToMathfieldEnd');
                this.mfe.menuItems = [...this.originalMenuItems];
            }
        }
    }

    ngOnDestroy(): void {
        window.mathVirtualKeyboard.hide();
        if (this.mathToggleRequestSubscription) this.mathToggleRequestSubscription.unsubscribe();
        if (this.onErrorImageEmitterSubscription) this.onErrorImageEmitterSubscription.unsubscribe();
        if (this.onClearErrorSubscription) this.onClearErrorSubscription.unsubscribe();
        if (this.onSetLatexInEditorSubscription) this.onSetLatexInEditorSubscription.unsubscribe();
        if (this.onUserPrefsChangeSubscription) this.onUserPrefsChangeSubscription.unsubscribe();
        if (this.onTTSTalkingChanged) { this.onTTSTalkingChanged.unsubscribe(); }
        if (this.onTTSTalkingStart) { this.onTTSTalkingStart.unsubscribe(); }
        if (this.onTTSTalkingEnd) { this.onTTSTalkingEnd.unsubscribe(); }
    }

    setZindexMathfieldPopOver() {
        if (this.suggestionPopOver === null) {
            this.suggestionPopOver = document.getElementById('mathlive-suggestion-popover');
            if (this.suggestionPopOver != null)
                this.suggestionPopOver.style.zIndex = '10';
        }

        if (this.cosoPopOver === null) {
            this.cosoPopOver = document.getElementById('mathlive-environment-popover');
            if (this.cosoPopOver != null)
                this.cosoPopOver.style.zIndex = '10';
        }
    }

    checkErrorFormula(): string {
        let s: string = '';
        if (this.mfe) {
            const latex = this.mfe.getValue();
            if (latex.indexOf('\\placeholder') >= 0) {
                s += this.translateService.instant('MATH_ERROR_FORMULA_PLACEHOLDER') + ' '
            }
        }
        return s;
    }

    private updateFormula() {
        this.mathService.getMathLiveLatex();
        this.descErrorFormula = this.checkErrorFormula();
        this.updateLatexChanged();
    }

    onLatexInfoPressed(): void {
        const title = this.translateService.instant('MATH_LATEX_INFO');
        const message = this.translateService.instant('MATH_LATEX_INFO_MESSAGE');
        const ok = this.translateService.instant('BUTTON_OK');
        this.confirmationService.confirm(title, message, ok, '');
    }

    onReadLatexPressed() {
        if (this.mfe) {
            const latex = this.mfe.getValue();

            let language = localStorage.getItem('locale') || 'it';
            if (!language.includes('it'))
                language = 'en';

            if (this.descErrorFormula) {
                this.ttsService.speak(CALLER.math, this.descErrorFormula, language, true);
                return;
            }

            let latexText = '';

            latexText = this.mathService.readLatex(latex, language);

            console.log('*****Read math: ' + latexText);
            if (latexText)
                this.ttsService.speak(CALLER.math, latexText, language, true);
        }
    }

    onStopReadLatexPressed() {
        this.ttsService.stop();
    }

    //EVENTS
    registerMathFieldEvents() {
        if (!this.mfe) return;

        window.mathVirtualKeyboard.addEventListener('geometrychange', (event) => {
            //console.log(window.mathVirtualKeyboard.boundingRect.height);
            if (this.keyboardContainer)
                this.keyboardContainer.style.height = window.mathVirtualKeyboard.boundingRect.height + 'px';
        });

        //evento di commit nel mathfield
        // this.mfe.addEventListener('change', (ev: any) => {
        //     console.log('+++CHANGE');
        // });

        this.mfe.addEventListener('keydown', (ev: any) => {
            if (ev.code === 'Backquote' && !this.isLatexMode) {
                ev.preventDefault();
                this.toggleLatexMode();
            }
        });

        //evento quando il mathfield e' stato modificato
        this.mfe.addEventListener('input', (ev: any) => {
            //console.log('+++INPUT ' + ev.data);
            // if (ev.data === 'insertLineBreak') {
            //     this.mathService.commitEdit(false);
            // } else {
            // if (ev.data === '\\') {
            //     ev.preventDefault();
            //     this.toggleLatexMode();
            // }
            this.updateFormula();
            // }
        });

        //evento generico DOM di focus
        /*this.mfe.addEventListener('focusout', (ev: any) => {
            console.log('+++FOCUS OUT');
            ev.preventDefault();
        });*/

        //evento mathfield quando guadagna focus
        this.mfe.addEventListener('focus', (ev: any) => {
            //console.log('+++FOCUS IN');
            this.smService.setEnableKeyPresses(false);
            window.mathVirtualKeyboard.show();
            this.registerKeycapEvents();
            if (this.keyboardContainer)
                this.keyboardContainer.style.height = window.mathVirtualKeyboard.boundingRect.height + 'px';
        });

        //evento mathfield quando perde focus
        this.mfe.addEventListener('blur', (ev: any) => {
            //console.log('+++FOCUS OUT');
            this.smService.setEnableKeyPresses(true);
            ev.preventDefault();
        });


        //evento solo in modo normale...non latex
        /*this.mfe.addEventListener('beforeinput', (ev) => {
            if (ev.inputType === 'insertText')
                console.log(ev.data?.toString());
        });*/

        this.mfe.addEventListener('change', (ev: any) => {
            if (this.mfe?.hasFocus() === true) {
                this.updateFormula();
                this.onConfirmToolPressed();
            }
        })

        //evento per gestire math e latex mode
        this.mfe.addEventListener('mode-change', () => {
            setTimeout(() => {
                console.log('+++CHANGE FIELD MODE ' + this.mfe?.mode);
                if (this.mfe) {
                    this.isLatexMode = this.mfe.mode === 'latex';
                }
            }, 100);
        });

        this.mfe.addEventListener('selection-change', () => {
            //console.log('+++Selection change');
            this.setZindexMathfieldPopOver();
        });

    }

    private registerKeycapEvents() {
        setTimeout(() => {
            const mvk: any = window.mathVirtualKeyboard;
            if (mvk && mvk.keycapRegistry) {
                const keys = Object.keys(mvk.keycapRegistry);
                for (let i = 0; i < keys.length; i++) {
                    const key = keys[i];
                    const kcElem = document.getElementById(key);
                    if (kcElem) {
                        kcElem.addEventListener('mouseover', (event: any) => {
                            // console.log('^^^^^^^^^^^^^^^^^^^^^^^^^ISSHIFTED:' + mvk.isShifted);
                            const kcId = (event.toElement.id ? event.toElement.id : '');
                            if (kcId) {
                                const text = event.toElement.getAttribute('data-tooltip');
                                this.ttsService.speak(CALLER.math, text, this.translateService.currentLang, false);
                            } else {
                                // console.log(`*****kcId not found: ${kcId}`);
                            }
                        });
                    } else {
                        // console.log(`*****kcElem not found id: ${key}`);
                    }
                }
            }
        }, 500);
    }

}
