import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Logger } from './logger.service';

const logger: Logger = new Logger('DeviceService');

@Injectable({
    providedIn: 'root'
})
export class DeviceService {

    constructor(public deviceDetectorService: DeviceDetectorService) { }

    /**
     * @desc returns all the info of the device
     */
    private get deviceInfo() {
        return this.deviceDetectorService.getDeviceInfo();
    }

    /**
     * @desc Check if the device is in desktop mode and is not a tablet
     */
    public isDesktopDevice(): boolean {
        return this.deviceDetectorService.isDesktop() && !this.isTabletDevice();
    }

    /**
     * @desc Check if the device is in mobile mode or is a tablet
     */
    public isMobileOrTabletDevice() {
        return this.isMobileMode() || this.isTabletDevice();
    }

    /**
     * @desc Check if the device is in mobile mode 
     */
    public isMobileMode(): boolean {
        return this.deviceDetectorService.isMobile();
    }

    /**
     * @desc Check if the device is in desktop mode 
     */
    public isDesktopMode(): boolean {
        return this.deviceDetectorService.isDesktop();
    }

    public isChromeOS(): boolean {
        return (/\bCrOS\b/.test(this.deviceDetectorService.userAgent));
    }

    /**
     * @desc Check if the device is a tablet 
     */
    public isTabletDevice(): boolean {
        return this.deviceDetectorService.isTablet();
    }

    /**
     * @desc Check if the device is running on windows 
     */
    public isWindows(): boolean {
        return this.deviceInfo.os.toLowerCase().includes("windows") && !this.isTabletDevice();
    }

    /**
     * @desc Check if the device is running on osx and not a tablet
     */
    public isOsX(): boolean {
        return this.deviceInfo.os.toLowerCase().includes("mac") && !this.isTabletDevice();
    }

    /**
     * @desc Check if the device is running on ios 
     */
    public isIOS() {
        return this.deviceInfo.os.toLowerCase().includes("ios");
    }

    private isIOSAlt() {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform) //dice che e' deprecato ma e' un'alternativa se non va quello principale
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    /**
     * @desc Check if the device is running on linux
     */
    public isLinux(): boolean {
        return this.deviceInfo.os.toLowerCase().includes("linux");
    }

    /**
     * @desc Check if the device is running on android 
     */
    public isAndroid(): boolean {
        return this.deviceInfo.device.toLowerCase().includes("android");
    }

    /**
     * @desc Check if the browser is safari
     */
    public isSafari() {
        return this.deviceInfo.browser.toLowerCase().includes("safari");
    }

    private isSafariAlt() {
        return navigator.userAgent.match(/safari/i);
    }

    /**
     * @desc Check if the browser is chrome
     */
    public isChrome() {
        return this.deviceInfo.browser.toLowerCase().includes("chrome");
    }

    public isInIframe(): boolean {
        try {
            return (window.self !== window.top);
        } catch (e) {
            return true;
        }
    }

    public isPWA(): boolean {
        return window.matchMedia('(display-mode: standalone)').matches;
    }

    /**
     * Controlla se il dispositivo che sta usando l'utente è dotato di fotocamera.
     */
    public hasCamera(): Promise<boolean> {
        return new Promise((resolve) => {
            // @ts-ignore
            if (!navigator && !navigator.mediaDevices) {
                logger.error('Non riesco a controllare se ci sono fotocamere');
                return resolve(false);
            }
            navigator.mediaDevices.enumerateDevices()
                .then((devices: any[]) => {
                    const cameras = devices.filter((d) => d.kind === 'videoinput');
                    return resolve((cameras.length > 0));
                });
        });
    }
}
