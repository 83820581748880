import { EventEmitter, inject, Injectable } from '@angular/core';
import { Auth, createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, linkWithPopup, sendEmailVerification, signInWithCredential, signInWithEmailAndPassword, User, UserCredential } from '@angular/fire/auth';
import { getDownloadURL, StorageReference } from '@angular/fire/storage';
import { EmailAuthProvider, isSignInWithEmailLink, reauthenticateWithCredential, sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailLink, unlink, updatePassword, updateProfile } from 'firebase/auth';
import { catchError, from, map, Observable, ReplaySubject } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { ResourceUpload } from './resource-upload';
import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TokenInterceptor } from '../http/token.interceptor';

export interface FirebaseUser {
    email: string;
    uid: string;
    tokenId: string;
}

const routes = {

    apiRequestAccess: '/api/requestAccess2gen',

};
const firebaseUserKey = 'fbcredentials';

const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: window.origin + '/finish-sign-up',
    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //     bundleId: 'it.anastasis.supermappex.ios'
    // },
    // android: {
    //     packageName: 'it.anastasis.supermappex',
    //     installApp: true,
    //     minimumVersion: '12'
    // }
};

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class FirebaseAuthService {

    public onTokenReady = new EventEmitter();
    private hasEmitted: boolean = false;

    public changedProfile: EventEmitter<User | null> = new EventEmitter<User | null>();

    private currentUser: User | undefined;
    //  private auth: Auth | undefined;
    private idToken: string = '';
    public isInitialized: boolean = false;
    public isPasswordLessUser: boolean = false;
    public isUserLoggedOut: boolean = false;


    public auth: Auth = inject(Auth)
    constructor(
        private firebaseService: FirebaseService,
        private cookieService: CookieService,
        private translateService: TranslateService,
        private http: HttpClient
    ) {
        this.reloadCredentials();
        try {
            this.auth.onIdTokenChanged((_user: any) => {

                if (_user) {
                    this.currentUser = _user;
                    _user.getIdToken().then((token: any) => {
                        const storage = localStorage;
                        this.idToken = token;
                        storage.setItem('token', this.idToken);
                        this.checkAndEmitTokenReady();
                    });
                }
                //  this.setFirebaseUser(_user, true);
                this.isInitialized = true;
                // console.log(`getCurrentFirebaseUser - onidTokenChanged - Firebase user: ${JSON.stringify(_user)}`);
            });
        } catch {
        }

        this.auth.onAuthStateChanged((_user: any) => {
            this.currentUser = _user;
            if (_user) {
                _user.getIdToken().then((token: any) => {
                    const storage = localStorage;
                    this.idToken = token;
                    storage.setItem('token', this.idToken);
                });
            }
            this.setFirebaseUser(_user, true);
            this.isInitialized = true;
            // console.log(`getCurrentFirebaseUser - onAuthStateChanged - Firebase user: ${JSON.stringify(_user)}`);
        });
    }


    getFirebaseUser(): User | null | undefined {
        return this.reloadCredentials();
    }

    linkwithGoogle(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (this.auth.currentUser) {
                const provider = new GoogleAuthProvider();
                if (this.auth.currentUser.email) {
                    provider.setCustomParameters({
                        'login_hint': '',
                        'prompt': 'select_account'
                    });
                }
                // const cookies = this.cookieService.getAll();
                const guiLangCookie = this.cookieService.get('GUILanguage');
                provider.setDefaultLanguage(guiLangCookie ? guiLangCookie : 'it');
                provider.addScope('profile');
                provider.addScope('email');
                provider.addScope('https://www.googleapis.com/auth/drive.file');

                provider.addScope('https://www.googleapis.com/auth/drive.install');
                // provider.addScope('https://www.googleapis.com/auth/documents');
                // provider.addScope('https://www.googleapis.com/auth/docs');

                // provider.addScope('https://www.googleapis.com/auth/classroom.courses.readonly');
                //
                linkWithPopup(this.auth.currentUser, provider).then((result: any) => {
                    // Accounts successfully linked.
                    const credential = GoogleAuthProvider.credentialFromResult(result);
                    const tokenResponse = result._tokenResponse;
                    const user = result.user;
                    this.currentUser = result.user;
                    this.setFirebaseUser(result.user);
                    return resolve({ credential: credential, user: user, tokenResponse: tokenResponse });
                    // ...
                }).catch((error) => {
                    // Handle Errors here.
                    // ...
                    return reject(error);
                });
            } else {
                return reject('no user');
            }
        });
    }

    unlinkGoogle() {
        return new Promise((resolve, reject) => {
            if (this.auth.currentUser) {
                unlink(this.auth.currentUser, 'google.com').then(() => {
                    return resolve(true);

                }).catch((error) => {
                    return resolve(false);
                });
            }

        });

    }
    /**
    * Sets the user credentials.
    * The credentials may be persisted across sessions by setting the `remember` parameter to true.
    * Otherwise, the credentials are only persisted for the current session.
    * param {Credentials=} credentials The user credentials.
    * param {boolean=} remember True to remember credentials across sessions.
    */
    public setFirebaseUser(firebaseUser?: User, remember?: boolean) {
        this.currentUser = firebaseUser || undefined;
        remember = remember || true;
        if (this.currentUser) {
            const storage = remember ? localStorage : sessionStorage;
            storage.setItem(firebaseUserKey, JSON.stringify(this.currentUser));
        } else {
            sessionStorage.removeItem(firebaseUserKey);
            localStorage.removeItem(firebaseUserKey);
        }
    }

    reloadCredentials(): User | null | undefined {
        const savedCredentials = localStorage.getItem(firebaseUserKey);
        if (savedCredentials) {
            this.currentUser = JSON.parse(savedCredentials);
        }
        const token = localStorage.getItem('token');
        this.idToken = token ? token : '';
        return this.currentUser;
    }


    createUser(email: string, password: string, fullName: string, imageURL: any) {


        return new Promise((resolve, reject) => {
            let firebaseUser = {};

            return createUserWithEmailAndPassword(this.auth, email, password)
                .then((userCredential: UserCredential) => {
                    // Signed in 
                    firebaseUser = userCredential.user;
                    // if (this.auth.currentUser !== null)
                    //     sendEmailVerification(this.auth.currentUser, actionCodeSettings);

                    this.currentUser = userCredential.user;
                    this.setFirebaseUser(this.currentUser);
                    if (fullName !== '') {

                        // to do upload image and save in firebase
                        return this.updateUserProfile(fullName, imageURL);
                    } else {
                        return Promise.resolve(this.auth.currentUser)
                    }


                }).then((user: User) => {
                    firebaseUser = user;

                    resolve(firebaseUser);

                })
                .catch((error) => {

                    const errorMessage = error;
                    // The email of the user's account used.



                    const firebaseUser: FirebaseUser = {
                        email: email,
                        uid: '',
                        tokenId: ''
                    };
                    reject(errorMessage);
                });
        });
    }

    updateUserFullName(fullName: string): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            Promise.resolve().then(() => {
                const user = getAuth().currentUser;

                if (user) {
                    return updateProfile(user, {
                        displayName: fullName, photoURL: user.photoURL
                    });
                } else {
                    return Promise.resolve();
                }
            }).then(() => {

                if (this.auth.currentUser) {
                    this.setFirebaseUser(this.auth.currentUser);

                }

                return resolve(this.auth.currentUser);
            }).then(() => {
                return resolve('');
            }).catch((error) => {
                return reject(error);
            });
        });
    }

    updateUserProfile(fullName: string, imageURL: any): Promise<any> {
        if (imageURL) {
            return new Promise<any>((resolve, reject) => {

                return this.updateUserProfileImage(imageURL)

                    .then((ref) => {
                        return getDownloadURL(ref);
                    }).then((url: string) => {
                        return Promise.resolve(url)
                    }).then((url: string) => {
                        this.auth = getAuth();


                        if (this.auth.currentUser) {
                            return updateProfile(this.auth.currentUser, {
                                displayName: fullName, photoURL: url
                            });
                        } else {
                            return Promise.resolve();
                        }
                    }).then(() => {
                        if (this.auth.currentUser) {
                            this.setFirebaseUser(this.auth.currentUser);

                        }

                        this.changedProfile.emit(this.auth.currentUser);
                        return resolve(this.auth.currentUser);
                    }).catch((error) => {
                        return reject(error);
                    })
            });
        } else {
            if (this.auth.currentUser) {
                const currentuser = this.auth.currentUser;
                return new Promise<any>((resolve, reject) => {

                    return updateProfile(currentuser, {
                        displayName: fullName
                    }).then(() => {
                        this.changedProfile.emit(this.auth.currentUser);
                        return resolve(this.auth.currentUser);

                    }).catch((error) => {
                        return reject(error);
                    })


                });
            } else {
                return Promise.resolve();
            }
        }
    }

    updatePassword(password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Promise.resolve().then(() => {



                if (this.auth.currentUser) {

                    return updatePassword(this.auth.currentUser, password);

                } else {
                    return Promise.resolve();
                }
            }).then(() => {
                this.isPasswordLessUser = false;
                return resolve('');
            }).catch((error) => {

                return reject(error);
            });

        });



    }

    removeUserProfileImage(): Promise<any> {

        return new Promise<any>((resolve, reject) => {
            const user = this.auth.currentUser;
            if (user) {

                updateProfile(user, { photoURL: '' }).then(() => {
                    if (getAuth().currentUser !== null) {
                        return getAuth().currentUser?.reload();

                    } else {
                        return Promise.resolve();
                    }

                }).then(() => {
                    const workResource: string = 'maps/' + user?.uid + '/profile-image';
                    return this.firebaseService.deleteResource(workResource);
                }).then(() => {
                    this.auth = getAuth();

                    if (this.auth.currentUser) {
                        this.setFirebaseUser(this.auth.currentUser);
                    }

                    this.changedProfile.emit(this.auth.currentUser);
                    return resolve(this.auth.currentUser);


                }).catch((error) => {
                    return reject(error);
                });
            } else return reject('no user');
        });
    }
    updateUserProfileImage(imageURL: any): Promise<any> {
        const user = this.auth.currentUser;

        // Create a Storage Ref w/ username

        const res = new ResourceUpload(imageURL);
        const workResource: string = 'maps/' + user?.uid + '/profile-image';
        return new Promise((resolve, reject) => {
            this.firebaseService.uploadFile(res, workResource).toPromise().then((ref: StorageReference) => {

                return getDownloadURL(ref);
            }).then((url: string) => {
                if (this.auth.currentUser && url !== null) {
                    return updateProfile(this.auth.currentUser, {
                        photoURL: url
                    });
                } else {
                    return Promise.resolve();
                }
            }).then(() => {
                this.changedProfile.emit(this.auth.currentUser);
                return resolve('');


            }).catch((error) => {
                return reject(error);
            })
        });

    }

    // sendSigninLink(email: string): Promise<void> {
    //     return new Promise((resolve, reject) => {

    //         // admin.auth().generateSignInWithEmailLink(email, actionCodeSettings)
    //         //     .then((link) => {
    //         sendSignInLinkToEmail(getAuth(), email, actionCodeSettings)

    //             .then(() => {
    //                 // The link was successfully sent. Inform the user.
    //                 // Save the email locally so you don't need to ask the user for it again
    //                 // if they open the link on the same device.

    //                 window.localStorage.setItem('emailForSignIn', email);
    //                 // ...
    //                 resolve();
    //             })
    //             .catch((error) => {

    //                 reject();
    //                 // ...
    //             });
    //     });



    // }

    public sendRequestAccess(email: string, requestDemo: boolean): any {
        const date = new Date().getHours() + ':' + new Date().getMinutes() + ' del giorno ' + new Date().getDate() + '/' + (new Date().getMonth() + 1) + '/' + new Date().getFullYear();
        const body = {
            mailTo: email,
            demoFromStart: requestDemo,
            date: date
        };
        return this.http.post<Response>(routes.apiRequestAccess, body, { headers: TokenInterceptor.buildNoTokenHeaders() })
            .pipe(map((res: Response) => res), catchError((error: any) => {
                console.error(error);
                return observableThrowError(() => error);
            }));
    }

    public sendRequestAccessPromise(email: string, requestDemo: boolean): Promise<any> {

        return this.sendRequestAccess(email, requestDemo).toPromise();
    }

    sendSigninLink(email: string, demoFromStart: boolean): Promise<void> {
        return new Promise((resolve, reject) => {


            this.sendRequestAccessPromise(email, demoFromStart).then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.

                window.localStorage.setItem('emailForSignIn', email);
                // ...
                return resolve();
            }).catch((error) => {

                return reject();
                // ...
            });
        });



    }
    completeSignInPasswordLess() {

        return new Promise((resolve, reject) => {
            this.isPasswordLessUser = true;

            // Confirm the link is a sign-in with email link.ret+
            const auth = getAuth();
            if (isSignInWithEmailLink(auth, window.location.href)) {
                // Additional state parameters can also be passed via URL.
                // This can be used to continue the user's intended action before triggering
                // the sign-in operation.
                // Get the email if available. This should be available if the user completes
                // the flow on the same device where they started it.
                let email = window.localStorage.getItem('emailForSignIn');
                if (!email) {
                    // User opened the link on a different device. To prevent session fixation
                    // attacks, ask the user to provide the associated email again. For example:
                    email = window.prompt(this.translateService.instant('REENTER_EMAIL'));
                }
                if (email) {
                    // The client SDK will parse the code from the link for you.
                    signInWithEmailLink(auth, email, window.location.href)
                        .then((result) => {
                            // Clear email from storage.
                            window.localStorage.removeItem('emailForSignIn');

                            // You can access the new user via result.user
                            // Additional user info profile not available via:
                            // result.additionalUserInfo.profile == null
                            // You can check if the user is new or existing:
                            // result.additionalUserInfo.isNewUser
                            result.providerId = 'passwordless';
                            console.log(result);
                            return resolve(result.user);
                        })
                        .catch((error) => {
                            // Some error occurred, you can inspect the code: error.code
                            // Common errors could be invalid email and invalid or expired OTPs.
                            console.log(error);
                            return resolve(null);
                        });
                }
            } else {
                return resolve(null);
            }
        });


    }

    testAndSendVerificationMail(): Promise<any> {

        return new Promise((resolve, reject) => {
            if (this.auth.currentUser && !this.auth.currentUser.emailVerified) {
                return sendEmailVerification(this.auth.currentUser, actionCodeSettings)

            } else return resolve('');
        });



    }

    resetPassword(email: string): Promise<void> {
        return new Promise((resolve, reject) => {
            sendPasswordResetEmail(this.auth, email,)
                .then(() => {
                    // Password reset email sent!
                    // return confirmPasswordReset(email, code);
                    resolve();

                })
                .catch((error) => {
                    return reject(error);
                });
        });
    }

    signIn(email: string, password: string): Promise<User> {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential: UserCredential) => {
                    // Signed in
                    const firebaseUser = userCredential.user;
                    this.currentUser = userCredential.user;
                    this.setFirebaseUser(firebaseUser);
                    this.isUserLoggedOut = false;

                    resolve(userCredential.user);
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;


                    const firebaseUser: FirebaseUser = {
                        email: email,
                        uid: '',
                        tokenId: ''
                    };
                    reject(errorCode);
                });
        });

    }



    signInWithGoogle(googleIdToken: string): Promise<FirebaseUser> {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            const credential = GoogleAuthProvider.credential(googleIdToken);
            // Sign in with credential from the Google user.
            signInWithCredential(auth, credential)
                .then((userCred: any) => {
                    const email = (userCred.user.email);
                    const firebaseUser: FirebaseUser = {
                        email: email,
                        uid: userCred.user.uid,

                        tokenId: userCred.user.tokenId // jhuba non me lo da in incognito
                    };
                    this.isUserLoggedOut = false;
                    this.currentUser = userCred;
                    this.setFirebaseUser(userCred);
                    //  this.updateUserProfile(c) TODO!!!

                    resolve(firebaseUser);
                }).catch((error) => {
                    // Handle Errors here.
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // The email of the user's account used.
                    const email = error.email;
                    // The credential that was used.
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    const firebaseUser: FirebaseUser = {
                        email: email,
                        uid: '',
                        tokenId: ''
                    };
                    resolve(firebaseUser);
                });
        });
    }


    reauthenticate(email: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                reauthenticateWithCredential(user, EmailAuthProvider.credential(email, password))
                    .then((userCred: any) => {
                        return resolve(userCred);
                    }).catch((error) => {
                        return reject(error);
                    })
            } else {
                return reject('no user');
            }
        });
    }

    logout(): Observable<void> {
        this.setFirebaseUser();
        this.currentUser = undefined;
        this.isUserLoggedOut = true;
        return from(getAuth().signOut());
    }

    _getUserIdToken() {
        return new Promise((resolve, reject) => {
            const auth = getAuth();
            const unsubscribe = auth.onIdTokenChanged(user => {
                unsubscribe();
                if (user) {
                    user.getIdToken(true).then(token => {
                        resolve(token);
                    });
                } else {
                    reject(null);
                }
            }, reject);
        });
    }

    getUserIdToken(): Observable<any> {
        return from(this._getUserIdToken());
    }




    getTokenId(): Promise<any> {
        return new Promise((resolve, reject) => {
            // if (!this.idToken) {
            //     const token = localStorage.getItem('token');
            //     // if token is not valid or not set
            //     this.idToken = token ? token : '';
            // }
            this.getStorageTokenId().then((token: string) => {
                // if (token ) {
                // const auth = getAuth();
                // setTimeout(() => {
                // if (this.auth == null) this.auth = getAuth();
                if (this.auth?.currentUser) {
                    // const token = await this.auth.currentUser.getIdToken(/* forceRefresh */ true);
                    this.auth.currentUser.getIdToken(/* forceRefresh */ true).then((token) => {
                        const storage = localStorage;
                        this.idToken = token;
                        storage.setItem('token', this.idToken);
                        this.checkAndEmitTokenReady();
                        return resolve(this.idToken);
                        // Send token to your backend via HTTPS
                    }).catch((error) => {
                        // Handle error
                        console.log(error);
                        return reject();
                    });
                } else {
                    if (token && this.currentUser) {
                        return resolve(token);
                    } else {
                        return reject(null);
                    }
                }
                // } else return reject(null);
                // }, 500);
            });
        });
    }

    getStorageTokenId(): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.idToken) {
                return resolve(this.idToken);
            } else {

                const token = localStorage.getItem('token');
                // if token is not valid or not set
                this.idToken = token ? token : '';
                return resolve(this.idToken);
            }
        });
    }

    public getCurrentUserUID() {
        if (this.currentUser) {
            return this.currentUser.uid;
        } else {
            return '';
        }
    }

    public getCurrentFirebaseUser(): Promise<User | undefined> {
        return new Promise((resolve) => {
            return resolve(this.currentUser);
        });
    }

    hasGoogleProvider(): boolean {
        let res = false;
        let i = 0
        const user = this.currentUser;
        if (user) {
            while (!res && i < user.providerData.length) {
                if (user.providerData[i].providerId === 'google.com') {
                    res = true;
                } else {
                    i++;
                }
            }
        }
        return res;
    }

    isNativeGoogleUser(): boolean {
        let res = false;
        const user = this.currentUser;
        if (user && user.providerData.length === 1 && user.providerData[0].providerId === 'google.com') {
            res = true;
        }
        return res;
    }


    checkAndEmitTokenReady() {
        if (!this.hasEmitted) {
            this.hasEmitted = true;
            this.onTokenReady.emit();
        }
    }

}


function observableThrowError(arg0: () => any): any {
    throw new Error('Function not implemented.');
}

