import { Component, OnInit, Input, OnDestroy, HostListener } from '@angular/core';
import { PrintMapService } from '../../../shared/commands/print-map.service';
import { SmService } from 'supermappe-core';
import { SmEventBroker } from '../../../shared/sm-event-broker';
import { ConvertToImageService } from '../../../shared/commands/convert-to-image.service';
import { CALLER, TtsService } from '../../../shared/commands/tts.service';
import { ZoomService } from '../../../shared/commands/zoom.service';
import { AuthenticationService } from '../../../core/authentication/authentication.service';
import { MapFoldingService } from '../../../shared/commands/map-folding.service';
import { Logger } from '../../../core/logger.service';
import { MapStateService } from '../../../shared/map-state.service';
import { CopyAndOpenMapService } from '../../../shared/commands/copy-and-open-map.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToggleFullscreenService } from '../../../shared/commands/toggle-fullscreen.service';
import { UserPreferenceService } from '../../../shared/user-preference.service';
import { UserPrefsDto } from '../../../shared/users/user-prefs-dto';
import { ModalService } from '../../../shared/links-menu/modal-service';
import { ShareLinkService } from '../../../shared/commands/share-link.service';
import { ShareMapService } from '../../../shared/share-map/share-map.service';
import { SmeService } from '../../../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { MessageBoxService } from 'src/app/shared/dialog/messagebox.service';

const logger: Logger = new Logger('MapShowComponent');

@Component({
    selector: 'app-show-toolbar',
    templateUrl: './show-toolbar.component.html',
    styleUrls: ['./show-toolbar.component.scss']
})
export class ShowToolbarComponent implements OnInit, OnDestroy {
    @Input() mapId = '';
    @Input() compressed: boolean = false;
    ttsEnabled = false;
    zoomOnSelectionEnabled = false;
    canCopy = false;
    canEdit = true;
    logged = false;
    userPrefs: UserPrefsDto;
    isLoaded = false;

    onLoadedSubscription: Subscription | undefined;
    showStepsSubscription: Subscription | undefined;
    insertMapSharedSubscription: Subscription | undefined;
    onZoomOnSelectionChangedSubscription: Subscription | undefined;
    getShareDataSubscription: Subscription | undefined;
    getUserPreferencesSubscription: Subscription | undefined;

    // isHandMode: boolean;
    selectiondata: any;
    mapStateOpen = false;
    isPrevEnabled = false;
    isNextEnabled = false;
    isFullscreen = false;
    isFullscreenChanging = false;

    @HostListener('document:fullscreenchange', ['$event'])
    onfullscreenchange(event: any) {
        this.toggleFullscreen(!this.isFullscreen, true);
    }
    @HostListener('document:webkitfullscreenchange', ['$event'])
    onwebkitfullscreenchange(event: any) {
        this.toggleFullscreen(!this.isFullscreen, true);
    }
    @HostListener('document:mozfullscreenchange', ['$event'])
    onmozfullscreenchange(event: any) {
        this.toggleFullscreen(!this.isFullscreen, true);
    }
    @HostListener('document:msfullscreenchange', ['$event'])
    onmsfullscreenchange(event: any) {
        this.toggleFullscreen(!this.isFullscreen, true);
    }

    constructor(private router: Router,
        private smService: SmService,
        private convertToImageService: ConvertToImageService,
        private ttsService: TtsService,
        private printService: PrintMapService,
        private zoomService: ZoomService,
        private mapFoldingService: MapFoldingService,
        private authenticationService: AuthenticationService,
        private mapStateService: MapStateService,
        private toggleFullScreenService: ToggleFullscreenService,
        private userPreferenceService: UserPreferenceService,
        private copyAndOpenMapService: CopyAndOpenMapService,
        private shareMapService: ShareMapService,
        private smEventBroker: SmEventBroker,
        private smeService: SmeService,
        private modalService: ModalService,
        private shareLinkService: ShareLinkService,
        private translateService: TranslateService,
        private messageBoxService: MessageBoxService) {
        //
        // User preferences inizializzati all'avvio
        //
        this.userPrefs = new UserPrefsDto();
    }

    ngOnInit() {
        this.mapStateService.isShowExiting = false;
        this.isLoaded = false;
        this.ttsEnabled = false;
        this.zoomOnSelectionEnabled = false;
        this.mapStateOpen = true;
        this.logged = this.authenticationService.isAuthenticated();
        this.canEdit = this.logged && !this.mapStateService.readOnly;
        this.canCopy = this.logged && this.mapStateService.readOnly;

        this.getUserPreferencesSubscription = this.smeService.getUserPreferences().subscribe((resp: any) => {
            let res = '';
            if (resp.result) {
                res = resp.result;
                this.userPrefs = new UserPrefsDto(res);
            }
        });

        this.onLoadedSubscription = this.mapStateService.onLoaded.subscribe((value: any) => {
            if (value) {
                //   if (!document.fullscreenElement) { this.toggleFullScreenService.goFullScreen(); }
                this.canEdit = this.logged && !this.mapStateService.readOnly;
                this.canCopy = this.logged && this.mapStateService.readOnly;
                this.initTTS();
            }
            this.isLoaded = value;
        });
        if (this.smEventBroker && this.smEventBroker.showStepsData) {
            this.showStepsSubscription = this.smEventBroker.showStepsData.subscribe((data: any) => {
                this.isPrevEnabled = (data && data.isPrevEnabled ? data.isPrevEnabled : false);
                this.isNextEnabled = (data && data.isNextEnabled ? data.isNextEnabled : false);
            });
        }
        if (this.smEventBroker && this.smEventBroker.onZoomOnSelectionChangedData) {
            this.onZoomOnSelectionChangedSubscription = this.smEventBroker.onZoomOnSelectionChangedData.subscribe((zoomOnSelection: boolean) => {
                this.zoomOnSelectionEnabled = zoomOnSelection;
            });
        }
        this.isFullscreen = false;
    }

    ngOnDestroy(): void {
        if (this.getUserPreferencesSubscription) { this.getUserPreferencesSubscription.unsubscribe(); }
        if (this.showStepsSubscription) { this.showStepsSubscription.unsubscribe(); }
        if (this.onLoadedSubscription) { this.onLoadedSubscription.unsubscribe(); }
        if (this.insertMapSharedSubscription) { this.insertMapSharedSubscription.unsubscribe(); }
        if (this.ttsService) { this.ttsService.stop(); }
        if (this.onZoomOnSelectionChangedSubscription) { this.onZoomOnSelectionChangedSubscription.unsubscribe(); }
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
    }

    initTTS() {
        //if (this.userPrefs.show_ttsEnabled) {
        this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.YESNO,
            this.translateService.instant('WARNING'),
            this.translateService.instant('TTS_SPEECHSYNTHESIS_ENABLE_REQUEST'))
            .subscribe((value) => {
                if (value === 'yes') {
                    this.ttsEnabled = true;
                    this.TTSRead();
                } else {
                    this.ttsEnabled = false;
                }
            });
        //}
    }

    centerMap() {
        this.smService.resizeMap();
    }

    zoom(direction: number) {
        this.zoomService.zoom(direction);
    }

    TTSRead() {
        if (this.smService) {
            this.userPrefs.show_ttsEnabled = !this.ttsEnabled;
            this.userPreferenceService.setUserPreference(this.userPrefs);
            this.smService.toggleTTS();
            this.ttsEnabled = this.smService.isTTSEnabled();
            if (!this.ttsEnabled) {
                this.ttsService.stop();
            } else {
                this.ttsService.speak(CALLER.map, this.translateService.instant("TTS_ENABLED_SPEECHSYNTHESIS_MESSAGE"), this.translateService.currentLang);
            }
        }
    }

    printMap(qrcodes: boolean) {
        this.printService.printMap(this.mapStateService.limitPrintSize, false);
    }

    convertToImage(qrcodes: boolean) {
        this.convertToImageService.convert(true, qrcodes, this.mapStateService.id, this.mapStateService.googleDocFileId);
    }

    openAllNodes() {
        this.mapFoldingService.setFoldingState(true);
        this.mapFoldingService.goToFinalShowStep();
    }

    nextShowStep() {
        this.mapFoldingService.nextShowStep();
    }

    prevShowStep() {
        this.mapFoldingService.prevShowStep();
    }

    closeAllNodes() {
        this.mapFoldingService.setFoldingState(false);
        this.mapFoldingService.goToStartingShowStep();
    }

    copyMap() {
        this.copyAndOpenMapService.copyAndOpenMap(this.mapStateService.id, this.mapStateService.name);
    }

    navigateToLogin() {
        this.router.navigate(['login', 'map-view/' + this.mapStateService.id]);
    }

    toggleFullscreen(value: boolean, fromHost: boolean) {
        if (!this.isFullscreenChanging) {
            if (value !== this.isFullscreen) {
                if (value) {
                    this.toggleFullScreenService.goFullscreen();
                } else {
                    this.toggleFullScreenService.goOutFullscreen();
                }
                this.isFullscreen = value;
                if (!fromHost) {
                    this.isFullscreenChanging = true;
                }
            }
        } else {
            this.isFullscreenChanging = false;
        }
    }

    showQRCode() {
        if (this.getShareDataSubscription) { this.getShareDataSubscription.unsubscribe(); }
        const email = (this.authenticationService && this.authenticationService.credentials && this.authenticationService.credentials.username ? this.authenticationService.credentials.username : '') + '';
        const firebaseUserId = this.authenticationService.credentials?.firebaseUserId + '';
        this.getShareDataSubscription = this.smeService.getShareData(email, firebaseUserId, this.mapStateService.id).subscribe((resp: any) => {
            this.mapStateService.isMine = (resp && resp.shareData ? resp.shareData.isMine : false);
            this.mapStateService.isShared = (resp && resp.SharedData ? resp.sharedData.isShared : false);
            if (!this.mapStateService.isShared) {
                this.shareMapService.showShareMapDialog(this.mapStateService.id, this.mapStateService.name, false, false, this.mapStateService.isShared, true);
            } else {
                const link = this.shareLinkService.getLinkToShare(this.mapStateService.id);
                this.modalService.showQRCode('MAP', link, 500);
            }
        });
    }

    zoomOnSelection() {
        this.smService.toggleZoomOnSelection();
    }

}
