<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''" [versioned]="true"></app-loader>

<div class="access-config" *ngIf="!isLoading && dataFound && !submitted">
  <div>
    <div [ngClass]="compressed ? 'access-title-compressed' : 'access-title'">
      <div>
        <!-- <a class="brand" routerLink="/home" target="_blank" translate> -->
        <div class="smx-logo-extended brand" routerLink="/home" translate>
          <img class="smx-logo-image-extended brand-logo" src="{{ logoUrlExtended }}?v=1" />
        </div>
        <!-- </a> -->

        <!-- <span [hidden]="!role" class="access-subtitle">{{getRoleType()}}</span> -->
      </div>
      <div [ngClass]="compressed ? 'access-info-compressed' : 'access-info'" class="spacer">
        <h1 class="access-subtitle">{{ "ACCESSCONFIG_TITLE" | translate }} -</h1>
        <span class="info" translate>ACCESSCONFIG_ORDERID</span><span class="info-order">{{ orderId }}</span>
      </div>
      <div class="access-buttons">
        <button color="warn" type="submit" mat-flat-button class="ok-button" (click)="onSubmit()">
          {{ "ACCESSCONFIG_SAVE" | translate }}
        </button>
        <!-- <button color="primary" type="button" mat-button (click)="goBack()">{{'PAGE_BACK' | translate}}</button> -->
      </div>
    </div>

    <div class="access-info">
      <span class="info" translate>ACCESSCONFIG_EXPIREDATE</span><span class="info-order">{{ expireDate }}</span>
      <span class="info" translate>ACCESSCONFIG_LICENSETYPE</span><span class="info-order">{{ getLicenseType() }}</span>
    </div>
  </div>
  <!-- <form class="access-form" [formGroup]="accessConfigForm" novalidate (ngSubmit)="onSubmit()"> -->
  <form class="access-form" [formGroup]="accessConfigForm" novalidate>
    <h1 class="access-subtitle" translate>ACCESSCONFIG_MSG_SINGLE</h1>
    <app-admin-config
      #adminconfig
      [parentForm]="accessConfigForm"
      [canEditDomain]="canEditDomain()"
      [accessList]="accessList"
      [showDomainLics]="false"
      [isSingleUser]="true"
    >
    </app-admin-config>
  </form>
</div>

<div class="access-config" *ngIf="!dataFound">
  <div>
    <div [ngClass]="compressed ? 'access-title-compressed' : 'access-title'">
      <!-- <a class="brand" routerLink="/home" target="_blank" translate> -->
      <div class="smx-logo-extended brand" routerLink="/home" translate>
        <img class="smx-logo-image-extended brand-logo" src="{{ logoUrlExtended }}?v=1" />
      </div>
      <!-- </a> -->
      <div [ngClass]="compressed ? 'access-info-compressed' : 'access-info'" class="spacer">
        <h1 class="access-subtitle">{{ "ACCESSCONFIG_TITLE" | translate }} -</h1>
        <span class="info" translate>ACCESSCONFIG_ORDERID</span><span class="info-order">{{ orderId }}</span>
      </div>
      <!-- <span [hidden]="!role" class="access-subtitle">{{getRoleType()}}</span> -->
    </div>
  </div>
  <br />
  <div class="order-not-found">
    <div class="onf-title">
      <p translate>ORDER-NOTFOUND-TITLE</p>
    </div>
    <div class="onf-subtitle">
      <p translate>ORDER-NOTFOUND-SUB</p>
    </div>
    <div class="onf-text">
      <p translate>ORDER-NOTFOUND-LINE1</p>
      <p translate>ORDER-NOTFOUND-LINE2</p>
      <p translate>ORDER-NOTFOUND-LINE3</p>
      <p translate>ORDER-NOTFOUND-LINE4</p>
    </div>
  </div>
</div>

<div [ngClass]="compressed ? 'access-title-compressed' : 'access-title'" *ngIf="submitted">
  <div>
    <!-- <a class="brand" routerLink="/home" target="_blank" translate> -->
    <div class="smx-logo-extended brand" routerLink="/home" translate>
      <img class="smx-logo-image-extended brand-logo" src="{{ logoUrlExtended }}?v=1" />
    </div>
    <!-- </a> -->

    <!-- <span [hidden]="!role" class="access-subtitle">{{getRoleType()}}</span> -->
  </div>
  <div [ngClass]="compressed ? 'access-info-compressed' : 'access-info'" class="spacer">
    <h1 class="access-subtitle">{{ "ACCESSCONFIG_TITLE" | translate }} -</h1>
    <span class="info" translate>ACCESSCONFIG_ORDERID</span><span class="info-order">{{ orderId }}</span>
  </div>
  <br />
</div>
<div class="order-not-found" *ngIf="submitted">
  <div class="onf-title">
    <p translate>ORDER-SUBMITTED-TITLE</p>
  </div>
  <div class="onf-subtitle">
    <p translate>ORDER-SUBMITTED-SUB</p>
  </div>
</div>
