import { Component, OnInit, OnDestroy, ViewChild, HostListener, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription, Observable, Subscriber } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AccessDto } from '../common/access-dto';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Location } from '@angular/common';
import { AccessConfigService } from '../access-config.service';
import { AdminConfigComponent } from '../common/admin-config/admin-config.component';
import { ConfirmationService } from '../../shared/dialog/confirmation.service';
import { Logger } from '../../core/logger.service';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';

const logger: Logger = new Logger('aid-desktop');

@Component({
    selector: 'app-single-user-config',
    templateUrl: './single-user-config.component.html',
    styleUrls: ['./single-user-config.component.scss']
})
export class SingleUserConfigComponent implements OnInit, OnDestroy {

    private paramMapSubscription: Subscription | undefined;
    private getAccesslistSubscription: Subscription | undefined;
    private saveAccesslistSubscription: Subscription | undefined;
    public orderId: string | undefined;
    private randomId: string | undefined;
    public singleUser = false;
    expireDate: any;


    accessConfigForm: FormGroup = this.formBuilder.group({
    });
    submitted = false;
    @ViewChild(AdminConfigComponent) adminconfig: AdminConfigComponent | undefined;


    isLoading = false;
    public dataFound = false;
    public changed: boolean;

    public accessList: AccessDto = new AccessDto();
    public origin: string | undefined;
    public accessName: string | undefined;

    public licenseType: string | undefined;
    public role: string | undefined;

    logoUrlExtended: string = '';
    compressed = false;
    private user = '';


    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.firebaseAuthService.auth.currentUser !== null && this.user !== this.firebaseAuthService.auth.currentUser?.email) {
            {

                this.router.navigate(['/home']);
            }
        }

    }

    @HostListener('window:beforeunload', ['$event'])
    onbeforeunload(event: any) {
        if (!this.accessConfigForm?.pristine) {
            const confirmationMessage = '\o/';
            event.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
            return confirmationMessage;              // Gecko, WebKit, Chrome <34
        } else return '';
    }


    constructor(private formBuilder: FormBuilder, private route: ActivatedRoute,
        private router: Router, private location: Location, private accessConfigService: AccessConfigService,
        private confirmationService: ConfirmationService, private translateService: TranslateService,
        private firebaseAuthService: FirebaseAuthService,
        private breakpointObserver: BreakpointObserver, private authenticationService: AuthenticationService) {
        this.changed = false;
        this.logoUrlExtended = ('../../assets/login/supermappex.png');

        this.breakpointObserver
            .observe([Breakpoints.XSmall])
            .subscribe((state: BreakpointState) => {
                this.compressed = state.matches;
            });

    }

    ngOnInit() {

        if (this.authenticationService.isAuthenticated()) {
            this.role = this.authenticationService.credentials?.role;
            this.user = this.authenticationService.getUserEmail();
        }

        this.accessConfigForm = this.formBuilder.group({
        });

        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            this.isLoading = true;
            this.dataFound = true;
            this.orderId = params['orderId'];
            this.randomId = params['randomId'];
            // console.log('orderId:' + this.orderId);
            // console.log('randomId:' + this.randomId);
            // Get access list
            this.getAccesslistSubscription = this.getAccessList().subscribe({
                next: () => {
                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                }
            });
        });
    }

    ngOnDestroy() {
        if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
        if (this.getAccesslistSubscription) { this.getAccesslistSubscription.unsubscribe(); }
        if (this.saveAccesslistSubscription) { this.saveAccesslistSubscription.unsubscribe(); }
    }

    public canEditDomain(): boolean {
        return false;
    }

    public canEditUsers(): boolean {
        return false;
    }

    public getLicenseType(): string {
        return this.translateService.instant('ACCESSCONFIG_LICENSE_SINGLE');
    }

    // public getRoleType() {
    //   return this.translateService.instant('ROLE_' + (this.role.toLocaleUpperCase() || 'USER'));
    // }

    public getOriginType() {
        return this.translateService.instant('ORIGIN_' + this.origin?.toLocaleUpperCase());
    }

    public getAccessList(): Observable<any> {
        // Get access list
        return new Observable<any>((subscriber: Subscriber<Response>) => {
            if (this.getAccesslistSubscription) { this.getAccesslistSubscription.unsubscribe(); }
            if (this.orderId && this.randomId) {
                this.getAccesslistSubscription = this.accessConfigService.findSubscription(this.orderId, this.randomId).subscribe((resp: any) => {
                    console.log(resp);
                    if (resp && resp.ok && resp.valid && resp.subscription) {
                        this.origin = resp.subscription.order.origin || '';
                        let accname = '';
                        if (resp.subscription.order && resp.subscription.order.licenseType === 's') {
                            this.singleUser = true;
                            accname = new Date().toISOString().replace(/[^a-zA-Z0-9@|]/g, '-') + resp.subscription.order.fullName;
                        }
                        this.accessName = (resp.subscription.access && resp.subscription.access.name !== '') ? resp.subscription.access.name : accname;
                        this.licenseType = resp.subscription.order.licenseType;

                        const date = new Date(resp.subscription.order.expireDate);
                        // const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
                        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'numeric', year: 'numeric' };
                        this.expireDate = date.toLocaleDateString('it-IT', options);
                        this.accessList = new AccessDto(resp.subscription.access);
                        subscriber.next();
                        subscriber.complete();
                    } else {
                        this.dataFound = false;
                    }
                    this.isLoading = false;
                }, (error: any) => {
                    subscriber.next(error);
                    subscriber.complete();
                    this.isLoading = false;
                    alert('SERVER ERROR: ' + error.message);
                });
            } else {
                this.isLoading = false;
                alert('NO DATA');
            }
        });
    }

    // private _goBack() {
    //   if (window.history.length > 1) {
    //     this.location.back();
    //   } else {
    //     this.router.navigate(['/home']);
    //   }
    // }

    // public goBack() {
    //   if ((this.accessConfigForm.dirty || (this.adminconfig && this.adminconfig.parentForm.dirty)) && !this.submitted) {
    //     this.confirmationService.confirm(this.translateService.instant('WARNING'),
    //       this.translateService.instant('EXIT_MESSAGE'), this.translateService.instant(extract('BUTTON_OK')), this.translateService.instant('WAIT'))
    //       .subscribe(result => {
    //         if (result) {
    //           // this._goBack();
    //         }
    //       }, (error: any) => {
    //         logger.error(error);
    //       });
    //   } else {
    //     // this._goBack();
    //   }
    // }

    private saveData() {
        // this.accessList.admin.eMail = this.accessConfigForm.get('adminEmail').value;
        // this.accessList.admin.name = this.accessConfigForm.get('adminName').value;
        // this.accessList.admin.notes = this.accessConfigForm.get('adminNotes').value;
        // this.accessList.domain = '@' + this.accessConfigForm.get('domain').value;
        // const adminConfig = this.accessConfigForm.

        if (this.accessList) {
            this.accessList.name = (this.accessName ? this.accessName : '');
            if (this.adminconfig) {
                this.accessList.admin.eMail = this.adminconfig.parentForm.get('adminEmail')?.value;
                this.accessList.admin.name = this.adminconfig.parentForm.get('adminName')?.value;
                this.accessList.admin.notes = this.adminconfig.parentForm.get('adminNotes')?.value;
                this.accessList.domain = '@' + this.adminconfig.parentForm.get('domain')?.value;
            }
        }
    }

    public saveAccessList() {
        // Save access list
        this.isLoading = true;

        this.saveData();
        if (this.orderId && this.randomId) {
            this.saveAccesslistSubscription = this.accessConfigService.createAccess(this.orderId, this.randomId, this.accessList)
                .subscribe((resp: any) => {
                    console.log(resp);
                    if (resp.ok === true) {
                        this.changed = false;
                        this.submitted = true;
                        if (this.accessConfigForm) {
                            this.accessConfigForm.reset();
                        }
                        if (this.adminconfig) {
                            this.adminconfig.parentForm.reset();
                        }
                        this.isLoading = false;
                        // alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_SUCCESS'));
                    } else {
                        this.isLoading = false;
                        this.submitted = false;
                        alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL'));
                    }

                    // this.goBack();
                }, (error: any) => {
                    this.submitted = false;
                    this.isLoading = false;
                    alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_FAIL') + '\n' + error.message);

                });
        } else { this.isLoading = false; }
    }

    public enabledSave() {
        let en = false;
        if (this.accessConfigForm && this.adminconfig) {
            en = this.accessConfigForm.valid && this.adminconfig.parentForm.valid;
            if (en) {
                en = en && (this.accessConfigForm.dirty || this.adminconfig.parentForm.dirty);
            }
        }
        return en;
    }


    get f() {
        if (this.accessConfigForm) {
            return this.accessConfigForm.controls;
        } else {
            return null;
        }
    }

    onSubmit() {
        // stop here if form is invalid
        if (!this.accessConfigForm || this.accessConfigForm.invalid || !this.adminconfig || this.adminconfig.parentForm.invalid) {
            return;
        }
        if (!this.accessConfigForm.dirty && !this.adminconfig.parentForm.dirty) {
            this.submitted = true;
            // this._goBack();
        } else {
            // this.submitted = true;
            this.saveAccessList();
        }
        // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.accessConfigForm.value));
    }
}
