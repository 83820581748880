import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from "@angular/core";
import { PrintMapService } from "../../../shared/commands/print-map.service";
import { SmService } from "supermappe-core";
import { DirtyDataService, DirtyDataStatus } from "../../../shared/commands/dirty-data.service";
import { ConvertToImageService } from "../../../shared/commands/convert-to-image.service";
import { CALLER, TtsService } from "../../../shared/commands/tts.service";
import { ZoomService } from "../../../shared/commands/zoom.service";
import { MapFoldingService } from "../../../shared/commands/map-folding.service";
import { MapStateService } from "../../../shared/map-state.service";
import { Subscription } from "rxjs";
import { Logger } from "../../../core/logger.service";
import { QuickEditService } from "../../../shared/commands/quick-edit.service";
import { ExtraItem, ExtraService } from "../../commands/extra.service";
import { WebSearchService } from "../../../shared/commands/web-search.service";
import { TranslateService } from "@ngx-translate/core";
import { extract } from "../../../core/i18n.service";
import { MapShowService } from "../../../map-show/map-show.service";
import { MapFindService } from "../../../shared/map-find/map-find.service";
import { ShareMapService } from "../../../shared/share-map/share-map.service";
import { DeviceService } from "src/app/core/device.service";

const logger: Logger = new Logger("EditToolbarComponent");

@Component({
  selector: "app-edit-toolbar",
  templateUrl: "./edit-toolbar.component.html",
  styleUrls: ["./edit-toolbar.component.scss"],
})
export class EditToolbarComponent implements OnInit, OnDestroy {
  @Input() mapId = "";
  @Input() editToolbarCompressed = false;

  quickEditEnabled = false;
  speechEditEnabled = false;

  canUndo = false;
  canRedo = false;
  canShowing = true;
  ttsEnable = false;
  showTooltip = "";
  mapSearchVisible = false;
  webSearchPanelVisible = false;
  error: any;
  selectiondata: any;
  mapStateOpen = false;

  openFindInMapSubscription: Subscription | undefined;
  dirtyDataChangeSubscription: Subscription | undefined;
  onErrorSubscription: Subscription | undefined;
  onToggleWebSearchSubscription: Subscription | undefined;
  onMapDirtyStateChangeSubscription: Subscription | undefined;
  onTTSEnabledChangedSubscription: any;
  onQuickEditEnableSubscription: Subscription | undefined;
  onSpeechEditEnableSubscription: Subscription | undefined;
  onSwitchToEditShowSubscription: Subscription | undefined;

  constructor(
    private smService: SmService,
    private dirtyDataService: DirtyDataService,
    private convertToImageService: ConvertToImageService,
    private ttsService: TtsService,
    private printService: PrintMapService,
    private zoomService: ZoomService,
    private mapShowService: MapShowService,
    private mapStateService: MapStateService,
    private mapFoldingService: MapFoldingService,
    private quickEditService: QuickEditService,
    private translateService: TranslateService,
    private extraService: ExtraService,
    private mapFindService: MapFindService,
    private webSearchService: WebSearchService,
    private shareMapService: ShareMapService,
    public deviceService: DeviceService
  ) {
    this.showTooltip = this.translateService.instant(extract("SHOWING"));
  }

  ngOnInit() {
    this.canUndo = false;
    this.canRedo = false;
    this.ttsEnable = false;
    this.webSearchPanelVisible = false;
    this.error = false;
    this.mapSearchVisible = false;
    this.quickEditEnabled = false;
    this.speechEditEnabled = false;
    if (this.dirtyDataService && this.dirtyDataService.dirtyDataChange) {
      this.dirtyDataChangeSubscription = this.dirtyDataService.dirtyDataChange.subscribe(
        (dirtyDataStatus: DirtyDataStatus) => {
          this.canUndo = dirtyDataStatus.canUndo;
          this.canRedo = dirtyDataStatus.canRedo;
        }
      );
    }
    this.openFindInMapSubscription = this.mapFindService.openFindInMap.subscribe((show: boolean) => {
      this.mapSearchVisible = show;
    });

    this.onMapDirtyStateChangeSubscription = this.mapStateService.onDirtyStateChange.subscribe((value: boolean) => {
      this.canShowing = !value;
      if (this.canShowing) {
        this.showTooltip = this.translateService.instant(extract("SHOWING"));
      } else {
        this.showTooltip = this.translateService.instant(extract("SHOW_DISABLED"));
      }
    });

    this.onQuickEditEnableSubscription = this.quickEditService.onQuickEditEnable.subscribe((enabled: boolean) => {
      this.quickEditEnabled = enabled && !this.error;
    });

    this.onErrorSubscription = this.mapStateService.onError.subscribe((error: any) => {
      this.error = error !== "";
      this.quickEditEnabled = false;
      this.speechEditEnabled = false;
      this.ttsEnable = false;
    });

    this.onTTSEnabledChangedSubscription = this.smService.onttsEnabledChanged.subscribe((value: boolean) => {
      this.ttsEnable = value;
    });

    this.onSpeechEditEnableSubscription = this.quickEditService.onSpeechEditEnable.subscribe((enabled: boolean) => {
      if (this.quickEditService.origin === this.quickEditService.ORIGIN.MAP) {
        this.speechEditEnabled = enabled && !this.error;
      }
    });

    this.onToggleWebSearchSubscription = this.webSearchService.onToggleWebSearchPanel.subscribe((opened: boolean) => {
      this.webSearchPanelVisible = opened;
      //this.smService.resizeCanvas();
    });

    this.onSwitchToEditShowSubscription = this.mapShowService.onSwitchToEditShow.subscribe((isEditShow: boolean) => {
      if (this.mapSearchVisible) {
        this.toggleFindInMap();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dirtyDataChangeSubscription) {
      this.dirtyDataChangeSubscription.unsubscribe();
    }
    if (this.onErrorSubscription) {
      this.onErrorSubscription.unsubscribe();
    }
    if (this.onToggleWebSearchSubscription) {
      this.onToggleWebSearchSubscription.unsubscribe();
    }
    if (this.onTTSEnabledChangedSubscription) {
      this.onTTSEnabledChangedSubscription.unsubscribe();
    }
    if (this.onSpeechEditEnableSubscription) {
      this.onSpeechEditEnableSubscription.unsubscribe();
    }
    if (this.onToggleWebSearchSubscription) {
      this.onToggleWebSearchSubscription.unsubscribe();
    }
    if (this.openFindInMapSubscription) {
      this.openFindInMapSubscription.unsubscribe();
    }
    if (this.onSwitchToEditShowSubscription) {
      this.onSwitchToEditShowSubscription.unsubscribe();
    }
  }

  openMenu() {}

  undoMap() {
    this.quickEditService.disableAll();
    this.dirtyDataService.undo();
  }

  redoMap() {
    this.quickEditService.disableAll();
    this.dirtyDataService.redo();
  }

  mapShowing() {
    if (this.canShowing) {
      this.mapShowService.showing(this.mapStateService.id);
    }
  }

  editShowing() {
    this.mapShowService.startEditShow();
  }

  centerMap() {
    this.smService.resizeMap();
  }

  zoom(direction: number) {
    this.zoomService.zoom(direction);
  }

  TTSRead() {
    if (this.smService) {
      this.smService.toggleTTS();
      this.ttsEnable = this.smService.isTTSEnabled();
      if (this.ttsEnable) {
        this.ttsService.speak(
          CALLER.map,
          this.translateService.instant("TTS_ENABLED_SPEECHSYNTHESIS_MESSAGE"),
          this.translateService.currentLang
        );
      } else {
        this.ttsService.stop();
      }
    }
  }

  quickEdit() {
    this.quickEditService.quickEditFromButtonInteraction = true;
    this.quickEditService.toggleQuickEdit();
  }

  speechEdit() {
    this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP);
  }

  printMap(qrcodes: boolean) {
    this.printService.printMap(this.mapStateService.limitPrintSize, false);
  }

  convertToImage(qrcodes: boolean) {
    this.convertToImageService.convert(true, qrcodes, this.mapStateService.id, this.mapStateService.googleDocFileId);
  }

  setFoldingState(open: boolean) {
    this.mapFoldingService.setFoldingState(open);
  }

  openExtraPanel(openItem: ExtraItem) {
    this.extraService.toggleExtraPanel(openItem);
  }

  openWebSearchPanel() {
    this.webSearchService.toggleWebSearchPanel();
  }

  toggleFindInMap() {
    this.mapFindService.toggleFindText();
  }

  shareMap() {
    this.shareMapService.showShareMapDialog(
      this.mapStateService.id,
      this.mapStateService.name,
      true,
      true,
      this.mapStateService.isShared,
      false
    );
  }

  goToEditMode() {
    this.mapShowService.finishEditShow();
  }

  clearReadOrder() {
    this.mapShowService.clearReadOrder();
  }

  setDefaultReadOrder() {
    this.mapShowService.setDefaultOrder();
  }

  toggleSearchWeb() {
    this.webSearchService.toggleWebSearchPanel();
  }
}
