import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { Logger } from '../../core/logger.service';
import { Subscription, Observable, Observer } from 'rxjs';
import { SmEventBroker } from '../../shared/sm-event-broker';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatButton } from '@angular/material/button';
import { Point } from '../../shared/point';
import { SmService } from 'supermappe-core';
import { ModalService } from '../../shared/links-menu/modal-service';
import { MapStateService } from '../../shared/map-state.service';
import { CALLER, TtsService } from '../../shared/commands/tts.service';
import { MapClipboardService } from '../commands/map-clipboard.service';
import { NewTableService } from '../new-table/new-table.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../../core/i18n.service';
import { QuickEditService } from '../../shared/commands/quick-edit.service';
import { WebSearchService } from '../../shared/commands/web-search.service';
import { UiConstants } from '../../shared/ui-constants';
import { UserPreferenceService } from '../../shared/user-preference.service';
import { UserPrefsDto } from '../../shared/users/user-prefs-dto';
import { LinksService } from '../../shared/links.service';
import { MathService } from 'src/app/shared/commands/math.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DeviceService } from 'src/app/core/device.service';

const logger: Logger = new Logger('SelectionMenuComponent');

@Component({
    selector: 'app-selection-menu',
    templateUrl: './selection-menu.component.html',
    styleUrls: ['./selection-menu.component.scss']
})

export class SelectionMenuComponent implements OnInit, AfterViewInit, OnDestroy {

    @ViewChild(MatMenuTrigger)
    private menuTrigger: MatMenuTrigger | undefined;
    @ViewChild('menuButton')
    private menuButton: MatButton | undefined;

    private dataSubscription: Subscription | undefined;
    private pasteSubscription: Subscription | undefined;
    private userPreferenceChangeSubscription: Subscription | undefined;
    private openNewTableDialogSubscription: Subscription | undefined;
    private openLinkDialogSubscription: Subscription | undefined;
    private askColRowSubscription: Subscription | undefined;
    private askUrlSubscription: Subscription | undefined;
    private getLinkPreviewSubscription: Subscription | undefined;
    private onFormatCopiedSubscription: Subscription | undefined;

    private menuPosition: Point = new Point(0, 0);
    private selectionData: any;
    public isCopyExtended = false;
    public isTableSelected = false;
    public isOnlyEdgeSelected = false;
    public isSingleNodeSelected = false;
    public isSingleEdgeSelected = false;
    public isEditMenuEnabled = false;
    public isTitleTextPresent = false;
    public isDeepTextPresent = false;
    public isSelectionPresent = false;
    public isSingleSelectionPresent = false;
    public isLinkNoMathPresent = false;
    public isMathLinkPresent = false;
    public isImagePresent = false;
    public isImagesPresent = false;
    public isTitlePresent = false;
    public canPaste = false;
    public canDelete = false;
    public canAlign = false;
    public canResize = false;
    public mainLinkIsVideo = false;
    isMultiSelectionPresent = false;
    isEdgeCurveSelected = false;
    // public itemToDelete: string;
    public editTitle = '';

    public scrCenterX = 0;
    public scrCenterY = 0;


    public alignments = UiConstants.ALIGNMENTS;
    public sizes = UiConstants.SIZES;
    public isFormatCopying = false;

    public isMathEnabled: boolean = false;

    constructor(
        private authenticationService: AuthenticationService,
        private smService: SmService,
        private smEventBroker: SmEventBroker,
        private modalService: ModalService,
        private mapStateService: MapStateService,
        private ttsService: TtsService,
        private mapClipboardService: MapClipboardService,
        private newTableService: NewTableService,
        private translateService: TranslateService,
        private quickEditService: QuickEditService,
        private webSearchService: WebSearchService,
        private userPreferenceService: UserPreferenceService,
        private linksService: LinksService,
        private mathService: MathService,
        public platformService: DeviceService
    ) { }

    ngOnInit() {
        this.isMathEnabled = this.authenticationService.isMathEnabled();
        if (this.smEventBroker.formatCopiedData) {
            this.onFormatCopiedSubscription = this.smEventBroker.formatCopiedData.subscribe(() => {
                this.isFormatCopying = false;
                this.mapStateService.isFormatCopying = this.isFormatCopying;
            });
        }
    }

    ngAfterViewInit(): void {
        if (this.smEventBroker.selectionMenuData) {
            this.dataSubscription = this.smEventBroker.selectionMenuData.subscribe((data: any) => {
                if (data && !this.mapStateService.getBusyState()) {
                    this.quickEditService.disableAll();
                    this.menuPosition = new Point(data.screenX, data.screenY);
                    this.selectionData = data.selectionData;
                    if (!this.selectionData.locked && this.menuButton) {
                        this.setupMenu();
                        this.menuButton._elementRef.nativeElement.style.position = 'absolute';
                        this.menuButton._elementRef.nativeElement.style.left = this.menuPosition.x + 'px';
                        this.menuButton._elementRef.nativeElement.style.top = this.menuPosition.y + 'px';
                        this.menuButton._elementRef.nativeElement.style.display = 'block';
                        this.menuTrigger?.openMenu();
                    } else {
                        this.menuTrigger?.closeMenu();
                    }
                }
            });
        }
        this.pasteSubscription = this.mapClipboardService.onCanPaste.subscribe((_canPaste: boolean) => {
            this.canPaste = _canPaste;
        });

        if (this.userPreferenceService.onUserPrefsChange) {
            this.userPreferenceChangeSubscription = this.userPreferenceService.onUserPrefsChange.subscribe((_userPref: UserPrefsDto) => {
                this.userPreferenceService.userPrefs = _userPref;
            });
        }

    }

    ngOnDestroy(): void {
        if (this.dataSubscription) { this.dataSubscription.unsubscribe(); }
        if (this.pasteSubscription) { this.pasteSubscription.unsubscribe(); }
        if (this.openNewTableDialogSubscription) { this.openNewTableDialogSubscription.unsubscribe(); }
        if (this.openLinkDialogSubscription) { this.openLinkDialogSubscription.unsubscribe(); }
        if (this.askColRowSubscription) { this.askColRowSubscription.unsubscribe(); }
        if (this.askUrlSubscription) { this.askUrlSubscription.unsubscribe(); }
        if (this.getLinkPreviewSubscription) { this.getLinkPreviewSubscription.unsubscribe(); }
        if (this.onFormatCopiedSubscription) { this.onFormatCopiedSubscription.unsubscribe(); }
    }

    isGoogleUser() {
        return this.authenticationService.isGoogleUser() || this.authenticationService.linkedWithGoogle();
    }

    public onMenuClosed(): void {
        if (this.menuButton) {
            this.menuButton._elementRef.nativeElement.style.display = 'none';
        } else {
            logger.error('selectionMenu close: menu not defined!');
        }
    }

    private setupMenu() {
        this.isSingleNodeSelected = (this.selectionData.nNodes + this.selectionData.nNodesImage +
            this.selectionData.nNodesImageFrame + this.selectionData.nNodesText +
            this.selectionData.nCells + this.selectionData.nCellsImage === 1);
        if (this.isSingleNodeSelected) {
            this.mainLinkIsVideo = (this.selectionData.element && this.selectionData.element.mainLinkType === 'video');
        }
        this.isSingleEdgeSelected = (this.selectionData.nEdges === 1);
        this.isEditMenuEnabled = (this.selectionData.class && this.selectionData.class !== '' ? true : false); // Single node or single edge
        this.isTitleTextPresent = (this.selectionData.titleText && this.selectionData.titleText !== '' ? true : false);
        this.isDeepTextPresent = (this.selectionData.deepHtml && this.selectionData.deepHtml !== '' ? true : false);
        this.isSelectionPresent = (this.selectionData.ids && this.selectionData.ids.length > 0);
        this.isSingleSelectionPresent = (this.selectionData.ids && this.selectionData.ids.length === 1);
        this.isMultiSelectionPresent = (this.selectionData.ids && this.selectionData.ids.length > 1);
        this.isLinkNoMathPresent = (this.selectionData.class === 'node' && this.selectionData.element && this.selectionData.element.links &&
            (this.selectionData.element.links.length > 1 ||
                this.selectionData.element.links.length === 1 && this.selectionData.element.links[0].type !== 'ltx'));
        this.isMathLinkPresent = (this.selectionData.class === 'node' && this.selectionData.element && this.selectionData.element.links &&
            (this.selectionData.element.links.length > 0 && this.getIsMathLinkPresent(this.selectionData.element.links)));
        this.isImagePresent = (this.selectionData.class === 'node' && this.selectionData.nodeType === 'image');
        this.isImagesPresent = (this.selectionData.nNodesImage > 0 || this.selectionData.nodeType === 'image' || this.selectionData.nCellsImage > 0);
        this.isOnlyEdgeSelected = (this.isSelectionPresent && this.selectionData.nEdges > 0 && this.selectionData.nEdges === this.selectionData.ids.length);
        this.isEdgeCurveSelected = (this.isOnlyEdgeSelected && this.selectionData.edgeCurve);
        this.isTableSelected = (this.selectionData.nCells + this.selectionData.nCellsImage) > 0;
        const onlyTableSelected = (this.selectionData.nCells + this.selectionData.nCellsImage) === 1 && !this.isSingleNodeSelected && !this.isOnlyEdgeSelected;
        this.canDelete = !(this.isTableSelected && onlyTableSelected) && (this.isDeepTextPresent || this.isLinkNoMathPresent || this.isMathLinkPresent || this.isImagesPresent || this.isTitleTextPresent) && (this.isSingleNodeSelected || (this.isOnlyEdgeSelected && this.isTitleTextPresent));
        this.isCopyExtended = ((this.isSingleNodeSelected || this.isSingleEdgeSelected) && (this.isTitleTextPresent || this.isDeepTextPresent || this.isImagePresent));


        if (this.isOnlyEdgeSelected) {
            // this.itemToDelete = this.translateService.instant(extract('DELETE_EDGE'));
            this.editTitle = this.translateService.instant(extract('DELETE_TEXT'));
        } else {
            // this.itemToDelete = this.translateService.instant(extract('DELETE_NODE'));
            this.editTitle = this.translateService.instant(extract('EDIT_NODE_TITLE'));
        }

        const nodeSelected = (this.selectionData.nNodes + this.selectionData.nNodesImage + this.selectionData.nNodesImageFrame + this.selectionData.nNodesText > 0);

        const canArrange = ((this.selectionData.nNodes + this.selectionData.nNodesImage + this.selectionData.nNodesImageFrame + this.selectionData.nNodesText) > 1) || (this.selectionData.nCells > 0 && nodeSelected);


        this.canAlign = canArrange && nodeSelected;
        this.canResize = canArrange && nodeSelected;
        this.scrCenterX = this.selectionData.scrCenterX;
        this.scrCenterY = this.selectionData.scrCenterY;
    }

    public deleteText() {
        this.smService.deleteTitleSelection();
    }

    public deleteImage() {
        this.smService.setSelectionNoImage();
    }

    deleteTable() {
        this.smService.deleteTable();
    }


    breakEdge() {
        this.smService.breakEdge();
    }

    // curvedEdge(curved: boolean) {
    //     this.smService.curveEdge(curved);
    // }

    public editText() {
        if (!this.isSelectionPresent) {
            this.smService.addNodeAndSelect(this.scrCenterX, this.scrCenterY);
        }
        this.smService.requestEditForCurrentElement();
    }

    public openMath() {
        if (!this.isSelectionPresent) {
            this.mathService.setLatexInEditor("");
        }
    }

    public editDeep() {
        this.modalService.showDeepContent(this.selectionData.id, this.selectionData.deepHtml, this.selectionData.deepText, this.selectionData.language);
    }

    public editMath() {
        this.mathService.editMath();
    }

    public deleteDeepText() {
        const data = { titleHtml: this.selectionData.titleHtml, deepHtml: '', deleteDeep: true };
        this.smService.editSelectedElement(data);
    }

    public readTitleText() {
        this.ttsService.stop();
        this.ttsService.speak(CALLER.map, this.selectionData.titleText, this.selectionData.language, true);
    }

    public cutSelection() {
        document.execCommand('cut');
    }

    public copySelection(type: string) {
        this.mapStateService.setCopyType(type);
        document.execCommand('copy');

        // modifica chrome iOS
        this.mapClipboardService.onCopy.emit();
    }

    public pasteSelection() {
        if (this.userPreferenceService.userPrefs?.usePasteWithouKeyboard) {
            this.mapClipboardService.showPaste(true);
        } else {
            // this.modalService.showCopyPastePopup();
            navigator.clipboard.read().then((data) => {
                this.mapClipboardService.pasteData(data);
            });
        }
    }

    public searchOnWeb(type: string) {
        if (this.isSingleNodeSelected) {
            this.webSearchService.textSearch = this.selectionData.titleText;

        }
        if (this.webSearchService.webSearchPanelOpen) {
            this.webSearchService.toggleWebSearchPanel();
        }
        this.webSearchService.searchOnWeb(type);
        this.webSearchService.disableMagicSearch();
    }

    public deleteSelection() {
        this.smService.deleteSelection();
    }

    public addNewNode() {
        this.smService.addNodeAndSelectIfNoSelection('');
    }

    public addNewTable() {
        this.mapStateService.lockUI(true);
        if (this.openNewTableDialogSubscription) { this.openNewTableDialogSubscription.unsubscribe(); }
        this.openNewTableDialogSubscription = this.openNewTableDialog().subscribe((res: any) => {
            if (res) {
                this.smService.addNewTable(res.col, res.row);
            }
            this.mapStateService.lockUI(false);
        });
    }

    public quickEdit() {
        this.quickEditService.toggleQuickEdit();
    }

    public quickSpeech() {
        this.quickEditService.toggleSpeechEdit(this.quickEditService.ORIGIN.MAP);
    }

    public editLink() {
        this.linksService.editLink();
    }

    editLinkGoogleDrive(type: string) { // type: 'drive' or 'map' or 'video'
        this.linksService.editLinkGoogleDrive(type);
    }

    deleteMathLink() {
        this.linksService.deleteMathLink();
    }

    public deleteLink() {
        this.linksService.deleteLink();
    }

    private openNewTableDialog() {
        return new Observable((observer: Observer<any>) => {
            // UnsavedChangesDialog defined somewhere else and imported above
            if (this.askColRowSubscription) { this.askColRowSubscription.unsubscribe(); }
            this.askColRowSubscription = this.newTableService.askColRow(this.translateService.instant(extract('GENERIC_TOOLBAR_INSERT_NEW_TABLE')))
                .subscribe({
                    next: result => {
                        observer.next(result);
                        observer.complete();
                    },
                    error: (error) => {
                        observer.next(false);
                        observer.complete();
                    }
                });
        });
    }

    // private lockUi(locked: boolean) {
    //   this.mapStateService.setEditingState(locked);
    //   // this.mapStateService.setBusyState(locked);
    //   this.mapStateService.setCanEnableQuickEdit(!locked);
    //   this.smService.setEnableKeyPresses(!locked);
    // }

    bringToFront() {
        this.smService.bringSelectionToFront();
    }

    sendToBack() {
        this.smService.sendSelectionToBack();
    }

    changeAlign(value: any) {
        this.smService.alignSelection(value);
    }

    changeSize(value: any) {
        this.smService.resizeSelection(value);
    }

    cropImage() {
        this.modalService.showSelectedNodeImageDialogCropper();
    }

    copyFormat() {
        this.smService.startCopyFormat(!this.isFormatCopying);
        this.isFormatCopying = !this.isFormatCopying;
        this.mapStateService.isFormatCopying = this.isFormatCopying;
    }

    getIsMathLinkPresent(links: Array<any>): boolean {
        let res = false;
        if (links) {
            for (let i = 0; i < links.length; i++) {
                if (links[i].type === 'ltx') {
                    res = true;
                    break;
                }
            }
        }
        return res;
    }

}
