import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MapShowRoutingModule } from './map-show-routing.module';
import { MapShowComponent } from './map-show.component';
import { SmModule } from 'supermappe-core';
import { ShowToolbarComponent } from './toolbar/show-toolbar/show-toolbar.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../material.module';
import { SmEventBroker } from '../shared/sm-event-broker';
import { TtsService } from '../shared/commands/tts.service';
import { TranslateModule } from '@ngx-translate/core';
import { InteractionmodeService } from '../shared/commands/interactionmode.service';
import { ConvertToImageService } from '../shared/commands/convert-to-image.service';
import { FbService } from '../shared/commands/fb.service';
import { PrintMapService } from '../shared/commands/print-map.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ZoomService } from '../shared/commands/zoom.service';
import { MapFoldingService } from '../shared/commands/map-folding.service';
import { ToggleFullscreenService } from '../shared/commands/toggle-fullscreen.service';
import { UserPreferenceService } from '../shared/user-preference.service';
import { MapShowService } from './map-show.service';

// import 'hammerjs';
// import 'hammer-timejs';

@NgModule({
    imports: [
        CommonModule,
        MatToolbarModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        MapShowRoutingModule,
        SmModule,
        SharedModule,
        MaterialModule,
        //FlexLayoutModule,
        TranslateModule.forRoot()
    ],
    providers: [SmEventBroker, InteractionmodeService, ZoomService, ToggleFullscreenService,
        ConvertToImageService, TtsService, FbService, PrintMapService, MapShowService,
        UserPreferenceService, MapFoldingService],
    declarations: [MapShowComponent, ShowToolbarComponent]
})
export class MapShowModule { }
