import { Component, OnInit, OnDestroy, Input, ViewChild, HostListener, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription, Observable, Subscriber } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { UserDto } from "./common/user-dto";
import { AccessDto } from "./common/access-dto";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { Location } from "@angular/common";
import { AccessConfigService } from "./access-config.service";
import { AdminConfigComponent } from "./common/admin-config/admin-config.component";
import { ConfirmationService } from "../shared/dialog/confirmation.service";
import { Logger } from "../core/logger.service";
import { AuthenticationService } from "../core/authentication/authentication.service";
import { UsersListComponent } from "./common/users-list/users-list.component";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { FirebaseAuthService } from "../core/firebase/firebase-auth.service";

const logger: Logger = new Logger("aid-desktop");

@Component({
  selector: "app-access-config",
  templateUrl: "./access-config.component.html",
  styleUrls: ["./access-config.component.scss"],
})
export class AccessConfigComponent implements OnInit, AfterViewInit, OnDestroy {
  private paramMapSubscription: Subscription | undefined;
  private getAccesslistSubscription: Subscription | undefined;
  private saveAccesslistSubscription: Subscription | undefined;
  private sendUserEmailSubscription: Subscription | undefined;
  public orderId = "";
  private randomId = "";
  public minSlot = 0;

  public safeDPAContent: SafeHtml = "";
  public safeContractContent: SafeHtml = "";
  public dpaGeneric: string = "";
  public contractGeneric: string = "";

  expireDate: any;
  accessConfigForm: FormGroup = this.formBuilder.group({
    accessName: ["", Validators.required],
  });
  submitted = false;
  @ViewChild(AdminConfigComponent) adminconfig: AdminConfigComponent | undefined;
  @ViewChild(UsersListComponent) userslist: UsersListComponent | undefined;
  // @ViewChild('usersconfig') private usersconfig: UsersListComponent;

  public showContract = false;
  public isOperator = false;
  public userType = "";
  public planId = "";
  public isLoading = false;
  public dataFound = false;
  public changed = false;
  public accessList: AccessDto | undefined;
  public origin: string | undefined;
  public accessName: string | undefined;
  public slotCount = 0;
  public users: UserDto[] | undefined;
  public licenseType = "";
  public domainLics = 0;
  public role = "";
  private isDPAChecked = false;
  private isContractChecked = false;
  showError = false;
  errorMessage = "";
  private user = "";

  @HostListener("window:beforeunload", ["$event"])
  onbeforeunload(event: any) {
    if (this.ctrlIfDirty()) {
      const confirmationMessage = "o/";
      event.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
      return confirmationMessage; // Gecko, WebKit, Chrome <34
    }
    return "";
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private firebaseAuthService: FirebaseAuthService,
    private accessConfigService: AccessConfigService,
    private confirmationService: ConfirmationService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    private sanitizer: DomSanitizer
  ) {
    this.changed = false;
    this.contractGeneric = this.translateService.instant("PRIVACY_DOC_CONTRACT_GENERIC");
    this.dpaGeneric = this.translateService.instant("PRIVACY_DOC_DPA_GENERIC");
    this.isOperator = this.route.snapshot.queryParams["operator"] === "true";
  }

  ngOnInit() {
    this.authenticationService.reloadCredentials();
    this.user = this.authenticationService.getUserEmail();
    if (this.authenticationService.isAuthenticated()) {
      this.role = this.authenticationService.credentials?.role ? this.authenticationService.credentials.role : "";
    }

    // this.accessConfigForm = this.formBuilder.group({
    //   accessName: ['', Validators.required]
    //   // users: this.formBuilder.array([this.createUsers()], CustomValidators.uniqueBy('email'))
    // });

    this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
      this.isLoading = true;
      this.dataFound = true;
      this.orderId = params["orderId"];
      this.randomId = params["randomId"];
      console.log("orderId:" + this.orderId);
      console.log("randomId:" + this.randomId);
    });
  }

  ngAfterViewInit() {
    this.getAccesslistSubscription = this.getAccessList().subscribe({
      next: () => {
        setTimeout(() => {
          this.initializeUsers();
          this.isLoading = false;
        });
      },
      error: (error) => {
        this.isLoading = false;
        this.showError = true;
        this.errorMessage = error.message ? error.message : error;
      },
    });
  }

  // createUsers(): FormGroup {
  //   return this.formBuilder.group({
  //     email: ['', Validators.email],
  //     name: '',
  //     notes: ''
  //   });
  // }

  initializeUsers() {
    const userslist = this.accessConfigForm?.get("users");
    this.accessConfigForm?.get("accessName")?.setValue(this.accessName);
    if (this.userslist) {
      this.userslist.initializeUsers(this.users);
    }

    // const userControl = <FormArray>this.accessConfigForm.controls.users;
    // userControl.controls.splice(0, userControl.controls.length);

    // this.users.forEach(user => {
    //   userControl.push(this.formBuilder.group({
    //     email: [user.eMail, Validators.email],
    //     name: user.name,
    //     notes: user.notes

    //   }));
    // });
  }

  ngOnDestroy() {
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
    if (this.getAccesslistSubscription) {
      this.getAccesslistSubscription.unsubscribe();
    }
    if (this.saveAccesslistSubscription) {
      this.saveAccesslistSubscription.unsubscribe();
    }
    if (this.sendUserEmailSubscription) {
      this.sendUserEmailSubscription.unsubscribe();
    }
  }

  public canEditDomain(): boolean {
    return this.licenseType === "d";
  }

  public canEditUsers(): boolean {
    return (this.licenseType === "d" || this.licenseType === "m") && this.slotCount > 0;
  }

  public getLicenseType(): string {
    switch (this.licenseType) {
      case "s":
        return this.translateService.instant("ACCESSCONFIG_LICENSE_SINGLE");
      case "m":
        return (
          this.translateService.instant("ACCESSCONFIG_LICENSE_MULTI") +
          " (" +
          this.slotCount +
          " " +
          this.translateService.instant("ACCESSCONFIG_USERS") +
          ")"
        );
      case "d":
        const descNumLicenses =
          this.domainLics <= 0
            ? this.translateService.instant("ACCESSCONFIG_UNLIMITED_LICENSES")
            : this.translateService.instant("ACCESSCONFIG_LIMITED_LICENSES").replace("%s", this.domainLics);
        return this.translateService.instant("ACCESSCONFIG_LICENSE_DOMAIN") + " " + descNumLicenses;
      default:
        return "undefined";
    }
  }

  public getRoleType() {
    return this.translateService.instant("ROLE_" + (this.role.toLocaleUpperCase() || "USER"));
  }

  public getOriginType() {
    return this.translateService.instant("ORIGIN_" + this.origin?.toLocaleUpperCase());
  }

  updateContractContent() {
    let keyDPA = "";
    let keyContract = "";
    if (this.planId === "edu") {
      // EDU
      keyDPA = "PRIVACY_DOC_DPA_EDU";
      if (this.userType !== "SCUOLA" && this.userType !== "UNIVERSITA") {
        keyContract = "PRIVACY_DOC_CONTRACT_PRIVATE_EDU";
      } else if (this.userType === "SCUOLA") {
        keyContract = "PRIVACY_DOC_CONTRACT_SCHOOL_EDU";
      } else if (this.userType === "UNIVERSITA") {
        keyContract = "PRIVACY_DOC_CONTRACT_UNIVERSITY_EDU";
      }
    } else {
      // LAB
      keyDPA = "PRIVACY_DOC_DPA_LAB";
      if (this.userType !== "SCUOLA" && this.userType !== "UNIVERSITA") {
        keyContract = "PRIVACY_DOC_CONTRACT_PRIVATE_LAB";
      } else if (this.userType === "SCUOLA") {
        keyContract = "PRIVACY_DOC_CONTRACT_SCHOOL_LAB";
      } else if (this.userType === "UNIVERSITA") {
        keyContract = "PRIVACY_DOC_CONTRACT_UNIVERSITY_LAB";
      }
    }
    const dpa = this.translateService.instant(keyDPA);
    this.safeDPAContent = this.sanitizer.bypassSecurityTrustHtml(this.dpaGeneric.replace("%dpa%", dpa));
    const contract = this.translateService.instant(keyContract);
    this.safeContractContent = this.sanitizer.bypassSecurityTrustHtml(
      this.contractGeneric.replace("%contract%", contract)
    );
  }

  public getAccessList(): Observable<any> {
    // Get access list
    return new Observable<any>((subscriber: Subscriber<Response>) => {
      if (this.getAccesslistSubscription) {
        this.getAccesslistSubscription.unsubscribe();
      }
      this.getAccesslistSubscription = this.accessConfigService.findSubscription(this.orderId, this.randomId).subscribe(
        (resp: any) => {
          console.log(resp);
          if (resp && resp.ok && resp.valid && resp.subscription) {
            this.showContract = (resp.subscription.order.locked || false) && !this.isOperator;
            this.userType = resp.subscription.order.userType || "PRIVATO";
            this.planId = resp.subscription.order.planId || "edu";
            this.origin = resp.subscription.order.origin || "";
            this.accessName = resp.subscription.access ? resp.subscription.access.name : "";
            this.licenseType = resp.subscription.order.licenseType;
            this.domainLics = resp.subscription.order.domainLics || 0;
            const date = new Date(resp.subscription.order.expireDate);
            // const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
            this.expireDate = date.toLocaleDateString("it-IT"); // , options);
            this.slotCount = resp.subscription.order.slotCount;
            this.accessList = new AccessDto(resp.subscription.access);
            // if (resp.subscription.access) {
            //   this.domain = resp.subscription.access.domain.replace('@', '');
            //   this.admin = new UserDto(resp.subscription.access.admin);
            // } else {
            //   this.domain = '';
            //   this.admin = new UserDto('');
            // }
            this.users = [];
            for (let i = 0; i < this.slotCount; i++) {
              let user;
              if (
                resp.subscription.access &&
                resp.subscription.access.users &&
                resp.subscription.access.users.length > i
              ) {
                user = new UserDto(resp.subscription.access.users[i]);
                // this.minSlot++;
              } else {
                user = new UserDto("");
              }
              this.users.push(user);
            }
            this.updateContractContent();
            subscriber.next();
            subscriber.complete();
          } else {
            this.dataFound = false;
          }
          this.isLoading = false;
        },
        (error: any) => {
          subscriber.next(error);
          subscriber.complete();
          this.isLoading = false;
          this.showError = true;
          this.errorMessage = error.message ? error.message : error;
          //alert("SERVER ERROR: " + error.message);
        }
      );
    });
  }

  // private _goBack() {
  //   if (window.history.length > 1) {
  //     this.location.back();
  //   } else {
  //     this.router.navigate(['/home']);
  //   }
  // }

  public ctrlIfDirty(): boolean {
    let dirty =
      (this.accessConfigForm?.dirty ||
        (this.adminconfig && this.adminconfig.parentForm.dirty) ||
        (this.userslist && this.userslist.usersForm.dirty)) &&
      !this.submitted;
    if (!dirty) dirty = false;
    return dirty;
  }

  private saveData() {
    if (this.accessList) {
      if (this.licenseType === "s") {
        this.accessList.name = this.accessName ? this.accessName : "";
      } else {
        this.accessList.name = this.accessConfigForm?.get("accessName")?.value;
      }
      this.accessList.admin.eMail = this.adminconfig?.parentForm.get("adminEmail")?.value;
      this.accessList.admin.name = this.adminconfig?.parentForm.get("adminName")?.value;
      this.accessList.admin.notes = this.adminconfig?.parentForm.get("adminNotes")?.value;
      // Normalize user with only one @ at start
      this.accessList.domain = "@" + this.adminconfig?.parentForm.get("domain")?.value.replace(/@/g, "");
      if (this.userslist) {
        this.accessList.users = this.userslist.getUsers();
      }
    }
  }

  public saveAccessList() {
    // Save access list

    this.saveData();

    this.saveAccesslistSubscription = this.accessConfigService
      .createAccess(this.orderId, this.randomId, this.accessList, "", false)
      .subscribe(
        (resp: any) => {
          console.log(resp);
          if (resp.ok === true) {
            this.changed = false;
            this.submitted = true;
            this.accessConfigForm?.reset();
            this.adminconfig?.parentForm.reset();
            if (this.userslist) {
              this.userslist.usersForm.reset();
            }
            // alert(this.translateService.instant('ACCESSCONFIG_MSG_SAVE_SUCCESS'));
          } else {
            this.submitted = false;
            alert(this.translateService.instant("ACCESSCONFIG_MSG_SAVE_FAIL"));
          }
          // this.goBack();
        },
        (error: any) => {
          this.submitted = false;
          alert(this.translateService.instant("ACCESSCONFIG_MSG_SAVE_FAIL") + "\n" + error.message);
        }
      );
  }

  public enabledSave() {
    let en = false;
    if (this.accessConfigForm && this.adminconfig) {
      en =
        this.accessConfigForm.valid &&
        this.adminconfig.parentForm.valid &&
        (this.accessConfigForm.dirty || this.adminconfig.parentForm.dirty);
      if (!en && this.userslist) {
        en = this.userslist.usersForm.valid && this.userslist.usersForm.dirty;
      }
    }
    return en;
  }

  sendUserEmail(user: any) {
    if (this.sendUserEmailSubscription) {
      this.sendUserEmailSubscription.unsubscribe();
    }
    const email = user.controls.email.value;
    const name = user.controls.name.value;
    this.sendUserEmailSubscription = this.accessConfigService
      .sendMail(this.orderId, this.randomId, email, name, true)
      .subscribe((resp: any) => {
        if (resp.ok) {
          alert(this.translateService.instant("MAIL_SENT"));
        }
      });
  }

  showUserStats(user: any) {
    const email = user.controls.email.value;
    const url = window.location.origin + "/user-stats/" + email;
    window.open(url, "_blank");
  }

  get f() {
    return this.accessConfigForm?.controls;
  }

  dpaClicked() {
    this.isDPAChecked = !this.isDPAChecked;
  }

  contractClicked() {
    this.isContractChecked = !this.isContractChecked;
  }

  isUnlockEnabled() {
    return this.isDPAChecked && this.isContractChecked;
  }

  unlockOrder() {
    this.isLoading = true;
    this.saveAccesslistSubscription = this.accessConfigService.acceptContract(this.orderId, this.randomId).subscribe(
      (resp: any) => {
        console.log(resp);
        if (resp.ok === true) {
          this.isLoading = false;
          // this.isOrderLocked = false;
          (window as any).location.reload();
        } else {
          this.isLoading = false;
          alert(this.translateService.instant("ACCESSCONFIG_MSG_SAVE_FAIL_ACCEPT"));
        }
      },
      (error: any) => {
        this.isLoading = false;
        const msg = error ? error.message : "<unknown>";
        alert(this.translateService.instant("ACCESSCONFIG_MSG_SAVE_FAIL_ACCEPT") + "\n" + msg);
      }
    );
  }

  onSubmit() {
    // stop here if form is invalid
    if (
      this.accessConfigForm?.invalid ||
      this.adminconfig?.parentForm.invalid ||
      (this.userslist && this.userslist.usersForm.invalid)
    ) {
      return;
    }

    if (!this.accessConfigForm?.dirty && !this.adminconfig?.parentForm.dirty && !this.userslist?.usersForm.dirty) {
      this.submitted = true;
      // this._goBack();
    } else {
      // this.submitted = true;
      this.saveAccessList();
    }
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.accessConfigForm.value));
  }
}
