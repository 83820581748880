<app-loader *ngIf="isLoading" [isLoading]="isLoading" [message]="''"></app-loader>

<form class="search" [formGroup]="searchForm" novalidate (ngSubmit)="onSubmit()">
    <span class="section-title">{{'USERS_LIST' | translate}}</span>
    <button mat-icon class="button-search" type="submit" matTooltip="{{'SEARCH' | translate}}">
        <mat-icon>search</mat-icon>
    </button>
    <input #searchtext type="text" placeholder="{{'SEARCH_PLACEHOLDER' | translate}}" ngDefaultControl autocomplete="off" spellcheck="false" class="input-search" formControlName="searchText" />
    <button mat-icon type="button" class="button-clear" matTooltip="{{'SEARCH_CLEAR' | translate}}" (click)="resetSearch()">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
    <!-- <button class="user-delete" mat-button matTooltip="{{'USERS_DELETE' | translate}}" (click)="deleteSearchUser()">{{'USERS_DELETE' | translate}}</button> -->
    <div *ngIf="!complete" class="list-incomplete" translate>USERS_LIST_INCOMPLETE</div>
</form>

<div class="users-list" mat-list>
    <div *ngFor="let user of usersList; let i = index" class="user-item">
        <span class="user-index">{{i + 1}}</span>
        <!-- <button class="user-delete" [disabled]="user.deleted" mat-button matTooltip="Revoke token" (click)="revoketoken(user.email,i)">revoke token</button> -->
        <button class="user-delete" [disabled]="user.deleted" mat-button matTooltip="{{'USERS_DELETE' | translate}}" (click)="deleteUser(user.email,i)">{{'USERS_DELETE' | translate}}</button>
        <span class="user-email">{{user.email}}</span>
        <span class="user-name">[{{user.displayName}}]</span>
        <mat-checkbox  [disabled]="user.deleted" class="user-data"  (click)="deleteDataClick(i)">{{'DELETE_ALL_DATA' | translate}}</mat-checkbox>
        <span *ngIf="user.uid" class="user-name">[{{user.uid}}]</span>
        <span *ngIf="user.deleteData===false && user.uid"> {{'DESC_DELETE_DATA' | translate}} </span>
    </div>
</div>
