import { Component, OnInit, OnDestroy } from '@angular/core';
import { CustomSelectElement } from '../custom-select-element-dto';
import { TranslateService } from '@ngx-translate/core';
import { SmService } from 'supermappe-core';
import { CustomizeToolbarService } from '../customize-toolbar.service';
import { Subscription } from 'rxjs';
import { CustomToolbarDto } from '../custom-toolbar-dto';
import { UiConstants } from '../../../../shared/ui-constants';
import { TtsService } from '../../../../shared/commands/tts.service';
import { MapStateService } from 'src/app/shared/map-state.service';
import { DeviceService } from 'src/app/core/device.service';

@Component({
    selector: 'app-language-toolbar',
    templateUrl: './language-toolbar.component.html',
    styleUrls: ['./language-toolbar.component.scss']
})
export class LanguageToolbarComponent implements OnInit, OnDestroy {

    public hideLangList = true;
    public languages: CustomSelectElement[];
    public languagesWithFlag: CustomSelectElement[] = new Array<CustomSelectElement>();
    public languagesNoFlag: CustomSelectElement[] = new Array<CustomSelectElement>();

    onGetVoicesSubscription: Subscription | undefined;
    onChangeToolbarVisibilitySubscription: Subscription | undefined;
    selectedLanguage = UiConstants.LANGUAGES[0].value;
    selectedLangIcon = UiConstants.LANGUAGES[0].icon;

    constructor(
        private smService: SmService,
        private mapStateService: MapStateService,
        private ttsService: TtsService,
        private translateService: TranslateService,
        private customizeToolbarService: CustomizeToolbarService,
        private deviceService: DeviceService) {

        this.languages = [];

    }

    ngOnInit() {
        this.onChangeToolbarVisibilitySubscription =
            this.customizeToolbarService.onChangeVisibilityToolbar.subscribe((_customData: CustomToolbarDto) => {
                this.changeSelection(_customData);
            });
        this.customizeToolbarService.onChangeLanguage.subscribe((language: CustomToolbarDto) => {
            this.changeSelection({ language: language });
        });
        this.onGetVoicesSubscription =
            this.ttsService.onGetVoices.subscribe((voices: SpeechSynthesisVoice[]) => {
                if (this.deviceService.isMobileOrTabletDevice()) {
                    this.languages = UiConstants.initLanguagesMobile(voices, this.translateService);
                } else {
                    this.languages = UiConstants.initLanguagesDesktop(voices, this.translateService);
                }
                this.sortLanguages();
            });

        if (this.ttsService.voices) {
            if (this.deviceService.isMobileOrTabletDevice()) {
                this.languages = UiConstants.initLanguagesMobile(this.ttsService.voices, this.translateService);
            } else {
                this.languages = UiConstants.initLanguagesDesktop(this.ttsService.voices, this.translateService);
            }
            this.sortLanguages();
        }

    }

    ngOnDestroy(): void {
        if (this.onChangeToolbarVisibilitySubscription) { this.onChangeToolbarVisibilitySubscription.unsubscribe(); }
        if (this.onGetVoicesSubscription) { this.onGetVoicesSubscription.unsubscribe(); }
    }

    changeSelection(_selectionData: any): any {
        this.selectedLanguage = _selectionData.language;
        const language = UiConstants.findLanguage(this.selectedLanguage, this.languages);
        if (language && language.icon) {
            this.selectedLangIcon = language.icon;
        }
    }

    changeLanguage(event: any) {
        this.selectedLanguage = event.currentTarget.id;
        this.mapStateService.setSpellCheckerLanguages(this.selectedLanguage);
        this.smService.setSelectionLanguage(this.selectedLanguage);
    }

    private sortLanguages() {
        if (!this.languages || this.languages.length == 0) return;

        this.languagesNoFlag = this.languages.filter(x => !x.icon || x.icon == UiConstants.FLAGS[0].icon);
        this.languagesWithFlag = this.languages.filter(x => x.icon && x.icon != UiConstants.FLAGS[0].icon);
    }
}


