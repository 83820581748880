import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { GoogleService } from 'src/app/core/google/google.service';
import { SmeService } from 'src/app/core/sme/sme.service';
import { MessageBoxService } from '../dialog/messagebox.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-share-classroom',
    templateUrl: './share-classroom.component.html',
    styleUrl: './share-classroom.component.scss'
})
export class ShareClassroomComponent implements OnDestroy {

    paramMapSubscription: Subscription | undefined;

    public isLoading = false;
    urlToShare = '';
    safeUrlToShare: SafeResourceUrl = '';


    constructor(
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private router: Router,
        private translateService: TranslateService,
        private messageBoxService: MessageBoxService,
        private googleService: GoogleService,
        private smeService: SmeService,
        private sanitizer: DomSanitizer
    ) {
        this.paramMapSubscription = this.route.params.subscribe((params: Params) => {
            const mapId = params['mapId'];
            this.share(mapId);
        });
    }

    share(mapId: string): void {
        console.log('Preparing mapId: ' + mapId);
        this.isLoading = true;
       // this.googleService.getClassroomFolderId().then((folderId: any) => {
            this.smeService.uploadMapToDrive(mapId + '', '').subscribe({
                next: (data: any) => {
                    const googleUri = `https://drive.google.com/file/d/${data.googleId}?usp=drive_link`;
                    this.urlToShare = `https://classroom.google.com/share?url=${encodeURIComponent(googleUri)}`;
                    this.isLoading = false;
                    this.safeUrlToShare = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlToShare);
                    (window as any).location = this.urlToShare;
                },
                error: (error) => {
                    this.isLoading = false;
                    if (error.status === '401') {
                        if (this.authenticationService.isNativegoogleUser()) {
                            this.router.navigate(['loggedin'], { fragment: 'expired=1' });
                        } else {
                           if (this.authenticationService.credentials) {
                             
                            const id = this.authenticationService.credentials.firebaseUserId;
                            this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK,
                                this.translateService.instant('WARNING'),
                                this.translateService.instant('RICONNNECT_GOOGLE_ACCOUNT'),
                            ).subscribe(() => {
                            this.router.navigate(['/user-profile', id], { fragment: 'highlight=1' });
                            });
                           } else {
                            this.router.navigate(['loggedin'], { fragment: 'expired=1' });
                           }
                        }

                    } else {
                        this.router.navigate(['loggedin'], { fragment: 'expired=1' });
                        // logger.error('AUTH ERROR: ' + JSON.stringify(err));
                        // this.error = extract('GDRIVE_PICKER_ERROR_LOADING_PICKER');
                        // this.showError(this.error);
                    }
                    console.error(JSON.stringify(error));
                    this.isLoading = false;
                }
            });
       // });
    }

    ngOnDestroy(): void {
        if (this.paramMapSubscription) { this.paramMapSubscription.unsubscribe(); }
    }

}
