import { Component } from '@angular/core';
import { FirebaseAuthService } from '../core/firebase/firebase-auth.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../core/authentication/authentication.service';
@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent {

    isLoading: boolean = false;
    public demoFromStart = false;
    isEmailRequest: boolean = true;
    isPasswordRequest: boolean = false;
    isForgotPassword: boolean = false;

    showingError: boolean = false;

    username: string = '';
    error: string = '';
    public client_id = '';

    public user = {
        id: '',
        uid: '',
        email: '',
        displayName: '',
        picture: '',
        refreshToken: '',
        token: ''
    };


    constructor(private firebaseAuthService: FirebaseAuthService, private translateService: TranslateService,
        private dialog: MatDialog, private router: Router, private authenticationService: AuthenticationService) { }

    requestPassword(username: string) {
        this.isEmailRequest = false;
        this.isPasswordRequest = true;
        this.username = username;

    }

    submitPassword(password: string) {
        if (password !== '') {
            this.loginNoGoogle({ username: this.username, password: password });
        }
    }

    loginNoGoogle(event: any): void {
        this.isLoading = true;
        this.showingError = false;
        console.log(`Login...`);
        if (navigator.onLine) {
            console.log(`ONLINE`);
            //this.firebaseAuthService.createUser(event.username, event.password).then((userCred: any) => {
            this.authenticationService.isUserSignin = true;
            this.firebaseAuthService.signIn(event.username, event.password).then((userCred: any) => {
                this.client_id = environment.firebaseClientId;
                this.user = {
                    id: userCred.uid,
                    uid: userCred.uid,
                    email: userCred.email,
                    displayName: userCred.displayName ? userCred.displayName : '',
                    picture: userCred.photoURL,
                    refreshToken: userCred.stsTokenManager.refreshToken,
                    token: userCred.stsTokenManager.accessToken
                };
                const UserInfo = {

                    state: 'password',
                    access_token: userCred.stsTokenManager.accessToken,
                    email: userCred.email,
                    fullName: userCred.displayName ? userCred.displayName : '',
                    id: userCred.uid,
                    id_token: userCred.stsTokenManager.idToken,
                    picture: userCred.photoURL,
                    refresh_token: userCred.stsTokenManager.refreshToken
                };
                // close();

                this.dialog.closeAll();


                this.router.navigate(['/loggedin'], { queryParams: { loginData: JSON.stringify(UserInfo) } });

            }).catch(error => {

                this.error = this.translateService.instant(error);
                if (this.error === error) {
                    this.error = this.translateService.instant('LOGIN_ERROR_GENERIC');
                }
                this.showingError = true;
                this.isLoading = false;
            })
        } else {
            console.error(`ERROR: OFFLINE`);
            this.isLoading = false;
            this.showingError = true;
            this.error = this.translateService.instant('OFFLINE_LOGIN');
        }

    }

    forgotPassword() {
        this.isPasswordRequest = false;
        this.isForgotPassword = true;
    }

    gotoPasswordRequest() {
        this.isEmailRequest = false;
        this.isPasswordRequest = true;
        this.isForgotPassword = false;
    }
}
