import { Title } from '@angular/platform-browser';
import { Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { MatMenuTrigger } from '@angular/material/menu';
import { AuthenticationService } from '../../core/authentication/authentication.service';
import { I18nService } from '../../core/i18n.service';
import { AboutService } from '../../about/about.service';
import { Subscription } from 'rxjs';
import { UserPreferenceService } from '../user-preference.service';
import { NotificationsDto } from '../notifications-data-dto';
import { SmeService } from '../../core/sme/sme.service';
import { TranslateService } from '@ngx-translate/core';
import { Logger } from '../../core/logger.service';
import { MapStateService } from '../map-state.service';
import { FirebaseService } from '../../core/firebase/firebase.service';
import { CookieService } from 'ngx-cookie-service';
import { FirebaseAuthService } from 'src/app/core/firebase/firebase-auth.service';
import { ImportMapService } from '../commands/import-map.service';
import { MessageBoxService } from '../dialog/messagebox.service';
import { MapOperationType } from '../map-operations';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { QuerySnapshot, onSnapshot } from '@angular/fire/firestore';
import { MapImportComponent } from 'src/app/map-import/map-import.component';
import { MatDialog } from '@angular/material/dialog';
import { subscriptionLogsToBeFn } from 'rxjs/internal/testing/TestScheduler';

const logger: Logger = new Logger('header-component');

// declare var genLang: string;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {


    @Input() sidenav: MatSidenav | undefined;
    @Input() showMapComponents = true;
    @Input() loadingMap = false;
    @Output() startLoading = new EventEmitter<void>();
    @Output() endLoading = new EventEmitter<boolean>();
    @Output() errorRecent = new EventEmitter<any>();

    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined;

    //LAYOUT variables
    public compressHeader = false;
    public compressLogo = false;
    ////////////

    public logoUrlExtended = '';
    public logoUrlCompressed = '';
    public genLang = '';
    public checkVisible: any = null;
    public currLanguage = '';
    private currUser = '';


    logoutSubscription: Subscription | undefined;
    userNotificationsSubs: Subscription | undefined;
    onUserIsDemoSubscription: Subscription | undefined;
    public userImageUrl: string;
    public adminUser: boolean;
    private credentials: any;
    public numNewNotifications = 0;
    public notifsEmpty = true;
    public noMap = false;
    public textSearch = '';

    private isDemo: any = null;
    private userNotifs: any;

    public popupBlocked = false;

    public notifications: NotificationsDto[] = new Array<NotificationsDto>();
    public isNotificationsExpanded: boolean = false;

    createNewMapSubscription: Subscription | undefined;
    statNewMapSubscription: Subscription | undefined;
    statOpenMapSubscription: Subscription | undefined;
    public driveUsageSpace: number = 0;
    public isGoogleUser = false;

    public hasUserValidSubscription: boolean = false;

    dbListenerNotificationChange: any;
    dbListenerNotificationChangeSnap: any;

    isFirstFirestoreEvent = true;
    authStateChangSubscription: Subscription | undefined;
    authChangedProfileSubscription: Subscription | undefined;
    popupBlockedSubscription: Subscription | undefined;
    onMapCreatedSubscription: Subscription | undefined;
    onMapImportedSubscription: Subscription | undefined;
    @HostListener('window:storage')
    onStorageChange() {
        console.log('change...');
        if (this.currUser !== this.firebaseAuthService.auth.currentUser?.email && this.firebaseAuthService.auth.currentUser !== null) {
            {
                this.credentials = this.authenticationService.reloadCredentials();
                this.currUser = this.authenticationService.getUserEmail();
                this.adminUser = (this.credentials && (this.credentials.admin || (this.credentials.role !== '')));
                this.userImageUrl = this.authenticationService.getUserImageUrl();
                this.isGoogleUser = this.authenticationService.reloadCredentials()?.providerId === 'google.com';
                this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
                this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');
            }
        }

    }

    constructor(
        private router: Router,
        private titleService: Title,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private i18nService: I18nService,
        private aboutService: AboutService,
        private smeService: SmeService,
        private userPreferenceService: UserPreferenceService,
        private messageBoxService: MessageBoxService,
        public mapStateService: MapStateService,
        private firebaseService: FirebaseService,
        private firebaseAuthService: FirebaseAuthService,
        private importMapService: ImportMapService,
        private cookieService: CookieService,
        private dialog: MatDialog,
        private breakpointObserver: BreakpointObserver) {

        this.credentials = this.authenticationService.credentials;
        this.adminUser = (this.credentials && (this.credentials.admin || (this.credentials.role !== '')));
        this.userImageUrl = this.authenticationService.getUserImageUrl();
        this.isGoogleUser = this.authenticationService.reloadCredentials()?.providerId === 'google.com';
        this.currUser = this.authenticationService.getUserEmail();

        if (this.authChangedProfileSubscription) this.authChangedProfileSubscription.unsubscribe();

        this.authChangedProfileSubscription = this.firebaseAuthService.changedProfile.subscribe((_user: any) => {
            if (_user) {
                if (_user.photoURL) this.userImageUrl = _user.photoURL;
                this.isGoogleUser = this.firebaseAuthService.hasGoogleProvider();
            }

        });

        this.firebaseAuthService.auth.onAuthStateChanged((user: any) => {
            if (user) {
                if (user.photoURL) this.userImageUrl = user.photoURL;
                this.isGoogleUser = this.firebaseAuthService.hasGoogleProvider();
                //  this.authenticationService.reloadCredentials();



            }
        });

        this.breakpointObserver
            .observe(['(max-width:725px)'])
            .subscribe((state: BreakpointState) => {
                this.compressHeader = state.matches;
            });
        this.breakpointObserver
            .observe(['(max-width:900px)'])
            .subscribe((state: BreakpointState) => {
                this.compressLogo = state.matches;
            });


        const lang = localStorage.getItem('language');
        if (lang && lang !== 'null') this.setLanguage(lang); else this.setLanguage('it-IT');
        // this.initialize();
        this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');
    }


    ngOnInit() {
        let lang = localStorage.getItem('language');
        if (lang === 'null') lang = this.translateService.currentLang;
        this.setLanguage(lang + '');
        // this.initialize();
        this.logoUrlExtended = (this.authenticationService.isLab() ? '../../assets/home/SMX_completo_lab.png' : '../../assets/home/SMX_completo_edu.png');
        this.logoUrlCompressed = (this.authenticationService.isLab() ? '../../assets/home/SMX_logo_lab.png' : '../../assets/home/SMX_logo_edu.png');
        this.hasUserValidSubscription = !this.authenticationService.isUserExpired();
        if (this.authenticationService.credentials && this.authenticationService.credentials.valid) {
            this.checkVisible = {};
            this.checkVisible['it-IT'] = true;
            this.checkVisible['en-GB'] = true;
            this.checkVisible['ca-ES'] = false;
            this.checkVisible['es-ES'] = false;

            try {
                const email = this.authenticationService.credentials?.username + '';
                this.dbListenerNotificationChange = this.firebaseService.getFirestoreNotificationChangeListener(email);
                this.dbListenerNotificationChangeSnap = onSnapshot(this.dbListenerNotificationChange, (querySnapshot: QuerySnapshot) => {
                    this.receiveMessages(querySnapshot);
                });
            } catch (err) {
                console.log(`[Recent] FirestoreDB onSnapshot maps ERROR: ${err}`);
            }


            this.onUserIsDemoSubscription = this.mapStateService.onUserIsDemo.subscribe((_isDemo: boolean) => {
                this.isDemo = _isDemo;
                this.updateUserNotifs();
            });

            this.loadNotifications();

            // this.smeService.getUserDriveQuota().subscribe({
            //     next: (quota: any) => {
            //         const limit = quota.storageQuota.limit != undefined ? Number(quota.storageQuota.limit) : -1;
            //         const usage = quota.storageQuota.usage != undefined ? Number(quota.storageQuota.usage) : -1;

            //         //unlimited account
            //         if (limit != -1) {
            //             this.driveUsageSpace = (usage / limit) * 100;
            //         } else {
            //         }
            //     },
            //     error: (error: any) => {
            //         console.log(error);
            //     }
            // });
        }
    }

    openDrive() {
        //  this.router.navigate(['gdrive-picker']);
        const redirectUri = window.location.origin + '/gdrive-picker';
        window.open(redirectUri, '_blank');

    }

    mapOpen: any = '';


    importMap() {

        const dialogRef = this.dialog.open(MapImportComponent, {

        });
    }

    openMap(file: any) {
        // const wh = window.open(window.location.origin + '/map-open', '_blank');
        ////////////////////////////////////////////////
        // Uncomment to try error on local open
        // this.showError('HOME_ERROR_GENERIC_MAP_LOAD');const storageRef = firebase.storage().ref();
        // return;
        ////////////////////////////////////////////////
        if (!this.loadingMap) {
            if (file.target.files[0].name.toLowerCase().endsWith('.sme')) {
                // SME: Load
                this.mapStateService.isSomeOperationStarted = true;
                this.loadingMap = true;
                //  this.isLoading.emit(true);
                // this.isLoading = true;
                // this.error = '';
                this.startLoading.emit();

                const title = this.translateService.instant('WARNING');
                let url = '';
                const message = this.translateService.instant('MSG_BLOCKED_POPUP');
                if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
                if (this.onMapCreatedSubscription) this.onMapCreatedSubscription.unsubscribe();
                this.onMapCreatedSubscription = this.importMapService.onMapCreated.subscribe((_url) => {
                    url = _url;
                });
                if (this.onMapImportedSubscription) this.onMapImportedSubscription.unsubscribe();
                this.onMapImportedSubscription = this.importMapService.onMapImported.subscribe((imported: boolean) => {
                    this.endLoading.emit(false);
                    if (!imported) {
                        this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, this.translateService.instant('WARNING'), this.translateService.instant('HOME_ERROR_GENERIC_MAP_UPLOAD')).subscribe(() => { });



                    }

                });
                this.popupBlockedSubscription = this.importMapService.onPopupBlocked.subscribe((blocked: boolean) => {

                    const title = this.translateService.instant('WARNING');
                    const message = this.translateService.instant('MSG_BLOCKED_POPUP');

                    this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message).subscribe(() => {



                        const redirectUri = url;//window.location.origin + '/map-open/' + url;
                        window.open(redirectUri, '_blank');


                    });
                });


                this.importMapService.openMap(file, true);
            } else {
                // SMA/SMAM: Error
                // this.error = this.translateService.instant('ERROR_MAP_FORMAT_NOT_SUPPORTED');
                this.showError('ERROR_MAP_FORMAT_NOT_SUPPORTED');
            }
        }

    }



    // public newMap() {
    //     if (!this.loadingMap) {
    //         logger.debug('new map');
    //         this.mapStateService.isSomeOperationStarted = true;
    //         this.loadingMap = true;
    //         this.startLoading.emit();
    //         // let accessToken = '';
    //         //let smxFolderId = '';
    //         // smxFolderId = '';

    //         //TEST ENOUGH DRIVE SPACE 1MB
    //         /*   this.smeService.hasUserEnoughDriveQuota(1000000)
    //                .then((ok: boolean) => {
    //                    if (ok) {
    //                        console.log("OK!!!!!!!!!!!!!!!!!!!!!!!!!");
    //                    } else {
    //                        console.log("NOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOOO");
    //                    }
    //                })
    //                .then((err: any) => {

    //                });
    //            return;*/

    //         // JHUBA: FOLDER SUPERMAPPEX - uncomment
    //         // this.googleService.getSMXFolderIds()
    //         //     .then((_smxFolderIds: any) => {
    //         //         smxFolderId = (_smxFolderIds && _smxFolderIds.smx ? _smxFolderIds.smx : '');
    //         this.mapStateService.isNew = true;
    //         if (this.statNewMapSubscription) { this.statNewMapSubscription.unsubscribe(); }
    //         this.statNewMapSubscription = this.smeService.addCustomMapStat('newmap', MapOperationType.STAT_NEWMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
    //         if (this.createNewMapSubscription) { this.createNewMapSubscription.unsubscribe(); }
    //         this.createNewMapSubscription = this.smeService.createNewMap('')
    //             .subscribe({
    //                 next: (mapId: string) => {
    //                     if (this.statOpenMapSubscription) { this.statOpenMapSubscription.unsubscribe(); }
    //                     this.statOpenMapSubscription = this.smeService.addCustomMapStat(mapId, MapOperationType.STAT_OPENMAP, this.authenticationService.getUserEmail()).subscribe((data: any) => { });
    //                     this.endLoading.emit(this.noMap);
    //                     this.loadingMap = false;
    //                     logger.info(`new Map ${mapId} created`);
    //                     this.router.navigate(['map-edit/', mapId]);
    //                     // window.location.href = 'map-edit/' + mapId;
    //                 },
    //                 error: (error: any) => {
    //                     logger.error(error);
    //                     this.loadingMap = false;
    //                     this.endLoading.emit(this.noMap);
    //                     let errorCode = '';
    //                     if (error.status === 504) {
    //                         errorCode = 'GATEWAY_TIMEOUT';
    //                     } else {
    //                         errorCode = (error.code) ? error.code : error.statusText;
    //                         errorCode = (error.error.reason) ? error.error.reason : error.statusText;
    //                         if (error.error.reason) {
    //                             this.translateService.instant(errorCode);
    //                         }
    //                     }
    //                     this.showError(errorCode);
    //                 }
    //             });

    //     }
    // }

    public newMap() {
        const redirectUri = window.location.origin + '/map-new';
        //DEBUG: RIPRISTINARE window.open!!!
        // alert('DEBUG: open map in same tab');
        // this.router.navigate(['map-new']);
        //----------------------------------
        window.open(redirectUri, '_blank');
    }

    private showError(errorCode: string) {
        try {
            // JHUBA ERROR DEBUG---------------------------------------------------
            // throw new Error('cippo');
            // JHUBA ERROR DEBUG---------------------------------------------------
            // this.error = this.translateService.instant(extract(errorCode));
            const title = this.translateService.instant('WARNING');
            const message = this.translateService.instant(errorCode);
            this.messageBoxService.showTextMessage(this.messageBoxService.MODE_TYPE.OK, title, message);
        } catch (err) {
            // this.error = 'ERROR: ' + errorCode;
            this.messageBoxService.showGenericError(errorCode);
        }
    }


    public endLoadingRecents(noMap: boolean) {
        this.noMap = noMap;
    }

    searchClick(event: any) {
        const search = (event && event.target && event.target.value ? event.target.value : '');
        this.textSearch = search;
        this.userPreferenceService.onRecentSearch.emit(this.textSearch);
    }

    clearSearch() {
        this.textSearch = '';
        this.userPreferenceService.onRecentSearch.emit(this.textSearch);
    }

    updateUserNotifs(limitNotifications: number = 5) {
        if (this.isDemo !== null && this.userNotifs && this.userNotifs.notifications) {
            this.notifications = [];
            let subscription: NotificationsDto | undefined;
            this.numNewNotifications = 0;
            // tslint:disable-next-line:forin
            for (const notificationId in this.userNotifs.notifications) {
                let suppressNotif = false;
                const el = new NotificationsDto(this.userNotifs.notifications[notificationId], this.menuTrigger);
                el.id = notificationId;
                if (!el.shown) { this.numNewNotifications++; }
                if (el.source === 'peer') {
                    // Populate missing data
                    el.title = this.translateService.instant('SHAREMAP_TITLE');
                    if (el.data.operation === 'add') {
                        el.link = `${window.origin}/map-view/${el.data?.mapId}`;
                    }
                    el.notes = this.translateService.instant('SHAREMAP_USER_' + el.data.operation.toUpperCase());
                    el.notes = el.notes.replace('%username%', el.data.sharerName);
                    el.notes = el.notes.replace('%mapname%', el.data.mapName);
                }
                if (this.isDemo) {
                    // DEMO
                    if (el.id === 'subscription_expiring') {
                        // Suppress demo notification until 7 days to expiry
                        const dateExp = new Date(el.dateCreated);
                        const dateNow = new Date();
                        const diffTime = Math.abs(dateExp.getTime() - dateNow.getTime());
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        el.notes = this.translateService.instant('NOTIFICATION_EXPIRING_DEMO');
                        el.link = window.origin + '/user-desktop';
                        if (diffDays > 7) {
                            suppressNotif = true;
                            this.numNewNotifications--;
                        }
                    } else if (el.id === 'subscription_expired') {
                        el.notes = this.translateService.instant('NOTIFICATION_EXPIRED_DEMO');
                        el.link = window.origin + '/user-desktop';
                    }
                } else {
                    // ACCOUNT
                    if (el.id === 'subscription_expiring') {
                        el.notes = this.translateService.instant('NOTIFICATION_EXPIRING_ACCOUNT');
                        el.link = window.origin + '/user-desktop';
                    } else if (el.id === 'subscription_expired') {
                        el.notes = this.translateService.instant('NOTIFICATION_EXPIRED_ACCOUNT');
                        el.link = window.origin + '/user-desktop';
                    }
                }
                if (!suppressNotif) {
                    if (el.typeIcon === 'subscription') {
                        subscription = el;
                    } else {
                        this.notifications.push(el);
                    }
                }
            }
            if (this.notifications.length > 0) {
                // Ordinate dalla query del DB
                // this.notifications.sort((a: NotificationsDto, b: NotificationsDto) => new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime());
                if (limitNotifications > 0) {
                    this.isNotificationsExpanded = false;
                    this.notifications = this.notifications.splice(0, limitNotifications);
                } else {
                    this.isNotificationsExpanded = true;
                }
                //tiene solo le notifiche di meno di 30 giorni 
                const now = new Date();
                const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
                this.notifications = this.notifications.filter(notification => new Date(notification.dateCreated) >= thirtyDaysAgo);
                this.numNewNotifications = 0;
                for (const notif of this.notifications) {
                    if (!notif.shown) { this.numNewNotifications++; }
                }
            }
            if (subscription) {
                this.notifications.unshift(subscription);
            }
            this.notifsEmpty = this.notifications.length === 0;
            this.currLanguage = this.i18nService.language;
        }
    }

    menuNotifsClosed() {
        this.numNewNotifications = 0;
        this.smeService.setUserNotificationsShownState(true).subscribe(() => {
            this.notifications.forEach((el: NotificationsDto) => {
                el.shown = true;
            });
        });
        // this.notificationsMenu.openMenu();
    }

    ngOnDestroy() {
        if (this.logoutSubscription) { this.logoutSubscription.unsubscribe(); }
        if (this.userNotificationsSubs) { this.userNotificationsSubs.unsubscribe(); }
        if (this.dbListenerNotificationChangeSnap) { this.dbListenerNotificationChangeSnap(); }
        if (this.onUserIsDemoSubscription) { this.onUserIsDemoSubscription.unsubscribe(); }
        if (this.onMapImportedSubscription) this.onMapImportedSubscription.unsubscribe();
        if (this.statNewMapSubscription) this.statNewMapSubscription.unsubscribe();
        if (this.popupBlockedSubscription) this.popupBlockedSubscription.unsubscribe();
        if (this.onMapCreatedSubscription) this.onMapCreatedSubscription.unsubscribe();
    }


    accessConfig() {
        this.mapStateService.isSomeOperationStarted = true;
        this.credentials = this.authenticationService.reloadCredentials();
        let redirectUri;
        if (this.credentials.role === '') {
            redirectUri = '/user-desktop';
        } else
            if (this.credentials.role === 'spain') {
                redirectUri = /*window.location.origin + */ '/spain-desktop';
            } else if (this.credentials.role === 'aid') {
                redirectUri = /*window.location.origin + */ '/aid-desktop';
            } else if (this.credentials.role === 'anastasis') {
                redirectUri = /*window.location.origin + */ '/ana-desktop';
            } else if (this.credentials.role === 'admin') {
                redirectUri = /*window.location.origin + */ '/admin-page';
            }
        redirectUri = window.location.origin + redirectUri;
        window.open(redirectUri, '_blank');
        //this.router.navigate([redirectUri]);
    }

    accessStats() {
        // let redirectUri = '/user-stats';

        // if (this.credentials.role === '') {
        //   redirectUri = '/user-stats';
        // } else
        //   if (this.credentials.role === 'anastasis') {
        //     redirectUri = '/user-stats';
        //   } else
        //     if (this.credentials.role === 'admin') {
        //       redirectUri = '/user-stats';
        //     }

        // this.router.navigate([redirectUri]);
        const email = this.authenticationService.getUserEmail();
        const redirectUri = window.location.origin + '/user-stats/' + email
        window.open(redirectUri, '_blank');
        //this.router.navigate(['/user-stats/' + email]);
    }

    setLanguage(language: string) {
        this.currLanguage = language;
        this.i18nService.language = language;
        this.genLang = language.substring(0, 2);
        this.cookieService.set('GUILanguage', this.genLang);
        localStorage.setItem('language', language);
        localStorage.setItem('locale', this.genLang);
        document.querySelector('html')?.setAttribute('lang', this.genLang);
        (window as any).WEBSPELLCHECKER_CONFIG.localization = this.genLang;
    }

    logout() {
        if (this.logoutSubscription) { this.logoutSubscription.unsubscribe(); }
        this.logoutSubscription = this.authenticationService.logout()
            .subscribe(() => this.router.navigate(['/login', '/home'], { replaceUrl: true }));
    }

    get currentLanguage(): string {
        return this.i18nService.language;
    }

    get languages(): string[] {
        return this.i18nService.supportedLanguages;
    }

    languageName(lang: string): string {
        return this.translateService.instant(lang);
    }

    get isNotifLangSupported(): boolean {
        const res = (this.translateService.currentLang === 'it-IT');
        return res;
    }

    get userEmail(): string {
        const credentials = this.authenticationService.credentials;
        return credentials ? credentials.username : '';
    }

    get username(): string {
        const credentials = this.authenticationService.credentials;
        return credentials ? credentials.fullName : '';
    }


    get title(): string {
        return this.titleService.getTitle();
    }

    about() {
        return this.aboutService.show();
    }

    aboutDevice() {
        return this.aboutService.showDeviceInfo();
    }

    //MOVED TO menu-notification.component.ts
    // goLink(notification: NotificationsDto) {
    //     let res = notification.link;
    //     if (res !== '' && !res.startsWith('data:image') && !res.startsWith('http://') && !res.startsWith('https://')) {
    //         res = 'http://' + res;
    //     }
    //     if (res !== '') {
    //         window.open(res, '_blank');
    //     }
    // }

    manual() {
        // let manualFile = '';
        // let language = '';
        // switch (this.i18nService.language) {
        //     case 'es-ES':
        //         language = 'es-ES';
        //         manualFile = 'Guía de inicio de SMX';
        //         break;
        //     case 'ca-ES':
        //         language = 'ca-ES';
        //         manualFile = 'Guia d\'introducció a SMX';
        //         break;
        //     default:
        //         // ITA / ENG
        //         language = 'it-IT';
        //         manualFile = 'Guida introduttiva SMX';
        // }
        // const manualPath = `../../assets/manuals/${language}_${manualFile}.pdf?v=5`;
        // window.open(manualPath, '_blank');
        const manualUrl = 'https://download.anastasis.it/manuali/smx-guida-introduttiva.pdf ';
        window.open(manualUrl, '_blank');
    }

    goProfile() {
        const user = this.firebaseAuthService.auth?.currentUser;
        if (user !== null) {
            this.router.navigate(['user-profile', user.uid]);
        }
    }

    downloadChromeLink(so: string) {
        this.firebaseService.downloadChromeLink(so);
    }

    openFaq() {
        window.open('/faq', '_blank');
    }

    privacy() {
        window.open('/privacy', '_blank');
    }

    openIntroMap() {
        this.userPreferenceService.openIntroMap();
    }

    version() {
        this.mapStateService.isSomeOperationStarted = true;
        this.smeService.version().subscribe((res: any) => {
            alert(JSON.stringify(res));
        });
    }

    getDriveProgressBarColor(progress: number) {
        if (progress < 60) {
            return 'primary';
        } else if (progress > 90) {
            return 'warn';
        } else {
            return 'accent';
        }
    }

    loadNotifications() {
        if (this.userNotificationsSubs) { this.userNotificationsSubs.unsubscribe(); }
        this.userNotificationsSubs = this.smeService.userListNotifications(this.credentials.expireDate).subscribe({
            next: (_userNotifs: any) => {
                this.userNotifs = _userNotifs;
                this.updateUserNotifs();
            }, error: (error: any) => {
                logger.error(error.message);
            }
        });
    }

    receiveMessages(querySnapshot: QuerySnapshot) {
        try {
            console.log('Listening to the projectTransactions events collection');
            const promises: Array<any> = [];
            const events: Array<any> = [];
            querySnapshot.docChanges().forEach((change: any) => {
                if (!this.isFirstFirestoreEvent) {
                    this.loadNotifications();
                }
                this.isFirstFirestoreEvent = false;
            });
        } catch (err) {
            console.log(`[Recent] FirestoreDB receiveMessages ERROR: ${err}`);
        }
    }

    public onFooterNotificationClick() {
        console.log('Footer notification click');
        this.updateUserNotifs(-1);
    }

}
