import { throwError as observableThrowError, Observable, Subscription } from 'rxjs';
import { Injectable, OnDestroy, numberAttribute } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FirebaseStorageUrlResolver } from '../firebase/firebase-storage-url-resolver';
import { Guid } from 'guid-typescript';
import { AuthenticationService } from '../authentication/authentication.service';
import { ResourceUpload } from '../firebase/resource-upload';
import { FirebaseService } from '../firebase/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { extract } from '../i18n.service';
import { Logger } from '../logger.service';
import { CookieService } from 'ngx-cookie-service';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { TokenInterceptor } from '../http/token.interceptor';
import { NotificationsDto } from '../../shared/notifications-data-dto';
import { timeout } from 'rxjs/operators';
import { StorageReference, getDownloadURL } from 'firebase/storage';
import { DesmosConfigDto } from 'src/app/map-edit/desmos-config/desmosConfig-dto';

const routes = {
    apiVersion: '/api/version2gen',
    apiGetDesmosConfig: '/api/getdesmosconfig2gen',
    apiSetDesmosConfig: '/api/setdesmosconfig2gen',
    apiUpdatesidebar2gen: '/api/updatesidebar2gen',
    apiPrepareMap: '/api/preparemap2gen',
    apiMoveMap: '/api/movemap2gen',
    apiCreateWorkMapFromStorage: '/api/createworkmapfromstorage2gen',
    apiCreateWorkMapFromGoogleDrive: '/api/createworkmapfromgoogledrive2gen',
    apiSearchMapInStorage: '/api/searchmapuserbymapid2gen',
    apiInsertMapShared: '/api/insertmapshared2gen',
    apiViewMap: '/api/viewmap2gen',
    apiEditMap: '/api/editmap2gen',
    apiExportSme: '/api/exportsme2gen',
    apiInsertImageFromDrive: '/api/insertimagefromdrive2gen',
    apiInsertImageFromSvg: 'api/insertimagefromsvg2gen',
    apiDownloadPdfFromDrive: '/api/downloadpdffromdrive2gen',
    apiAddPdfToRecents: '/api/addpdftorecents2gen',
    apiUpdatePdfMap: '/api/updatepdfmap2gen',
    apiSavePdf: '/api/savepdftodrive2gen',
    apiSavePdfasBlob: '/api/savepdfasblobtodrive2gen',
    apiGetPdfRecents: '/api/getpdfrecents2gen',
    apiDeleteFromPdf: '/api/deletefrompdf2gen',
    apiDeletePdfFromMaps: 'api/deletepdffrommaps2gen',
    apiGetPdfStorageById: '/api/getpdfstoragebyid2gen',
    apiGetPdfFromMap: '/api/getpdffrommap2gen',
    apiRecentMaps: '/api/recentmaps2gen',
    apiRemoveRecent: '/api/removerecent2gen',
    apiTrashRecent: '/api/trashrecent2gen',
    apiStopSharing: '/api/stopsharing2gen',
    apiGetRecentData: '/api/recentdata2gen',
    apiSaveSme: '/api/savesme2gen',
    apiCreateCopyMapInGoogleDrive: '/api/createcopymapingoogledrive2gen',
    apiCreateCopyMap: '/api/createcopymap2gen',
    apiRenameMap: '/api/renamemap2gen',
    apiCreateNewMap: '/api/createnewmap2gen',
    apiCreateNewDoc: '/api/createnewdocument2gen',
    apiUserPreferences: '/api/userpreferences2gen',
    apiUserMapsRevisions: '/api/usermaprevisions2gen',
    apiGetMapJpegFromRevision: '/api/getmapjpegfromrevision2gen',
    apiRestoreRevision: '/api/restorerevision2gen',
    apiCopyRevision: '/api/copyrevision2gen',
    apiExportSmeToGDrive: '/api/exportmapingdrive2gen',
    apiUploadImageFromUri: '/api/uploadimagefromuri2gen',
    apiUntrashFile: '/api/untrashfile2gen',
    apiRestoreMapFromStorage: '/api/restoremapfromstorage2gen',
    apiSetSessionId: '/api/setsessionid2gen',
    apiCheckSessionId: '/api/checksessionid2gen',
    apiWriteGoogleUserData: '/api/writegoogleuserdata2gen',
    apiUnlinkGoogleEmailForUser: '/api/unlinkgoogleemailforuser2gen',
    apiSetUserInfo: '/api/setuserinfo2gen',
    apiHasUserAcceptedPrivacy: '/api/hasuseracceptedprivacy2gen',
    apiGetIntroMaps: '/api/getintromaps2gen',
    apiGetShareData: '/api/getsharedata2gen',
    apiDeleteChat: '/api/deletechat2gen',
    apiDeleteMapEvents: '/api/deletemapevents2gen',
    adminInsertNotification: '/api/admininsertnotification2gen',
    adminListNotifications: '/api/adminlistnotifications2gen',
    userListNotifications: '/api/userlistnotifications2gen',
    setUserNotificationsShownState: '/api/setusernotificationsshownstate2gen',
    addStatRecords: '/api/addstatrecords2gen',
    addCustomMapStat: '/api/addcustommapstat2gen',
    readSingleUserStats: '/api/readsingleuserstats2gen',
    readOrderStatsStep1: '/api/readorderstatsstep12gen',
    readOrderStatsStep2: '/api/readorderstatsstep22gen',
    readOrderStatsStep3: '/api/readorderstatsstep32gen',
    readOrderStatsStep4: '/api/readorderstatsstep42gen',
    freezeSubscription: '/api/freezesubscription2gen',
    getStatsByOriginAndYearStep1: '/api/getstatsbyoriginandyearstep12gen',
    getStatsByOriginAndYearStep2: '/api/getstatsbyoriginandyearstep22gen',
    getStatsByOriginAndYearStep3: '/api/getstatsbyoriginandyearstep32gen',
    getStatsByOriginAndYearStep4: '/api/getstatsbyoriginandyearstep42gen',
    getStatsByOriginAndYearStep5: '/api/getstatsbyoriginandyearstep52gen',
    getDomainsStatsByYear: '/api/getdomainsstatsbyyear2gen',
    findMapSharedInfo: '/api/findmapsharedinfo2gen',
    findOwnerIdByMapId: '/api/findowneridbymapid2gen',
    deleteSubscription: '/api/deletesubscription2gen',
    setDriveDocumentAsPublic: '/api/setdrivedocumentaspublic2gen',
    findMapLinkInfo: '/api/findmaplinkinfo2gen',
    addUserToNewsletter: '/api/addusertonewsletter2gen',
    activateDemo: '/api/activatedemo2gen',
    unlockOrders: '/api/unlockorders2gen',
    apiLoggedin: '/api/loggedin2gen',
    apiMustRelogged: '/api/mustrelogged2gen',
    apiRevokeToken: '/api/revoketoken2gen',
    apiGetAccessToken: '/api/getaccesstoken2gen',
    apiGetAuthUser: '/api/getauthuser2gen',
    apiLatex2Image: '/api/latex2image2gen',
    getUserDriveQuota: '/api/getuserdrivequota2gen',
    apiGetOcrMathPix: '/api/getocrmathpix2gen',
    apiGetOcrMathPixScanCount: '/api/getocrmathpixscancount2gen',
    apiGetOcrMathPixScansLeft: '/api/getocrmathpixscansleft2gen',
    apiDeleteUserByEmail: '/api/deleteuserbyemail2gen',
    apiListUsers: '/api/listusers2gen',
    apiWriteShares: '/api/writeshares2gen',
    apiUploadMapToDrive: '/api/uploadmaptodrive2gen',
    apiEmptyTrash: '/api/emptytrash2gen',
    apiFindUserByEmail: '/api/finduserbyemail2gen',
    apiMoveMaps: '/api/movemaps2gen',
    apiMoveMapsinEmail: '/api/movemapsbyemail2gen',

    resourcePath: (userId: string) => `/maps/${userId}`,
    //resourcePdfPath: (userId: string, filename: string) => `/maps/${userId}/pdfs/${filename}`,
    resourcePdfPath: (userId: string, pdfId: string) => `${routes.resourcePath(userId)}/pdfs/${pdfId}`,
    resourceSme: (userId: string, filename: string) => `${routes.resourcePath(userId)}/${filename}`,
    resourceSmePath: (userId: string, mapId: string) => `${routes.resourcePath(userId)}/mymaps/${mapId}.sme`,
    workPath: (userId: string, mapId: string) => `${routes.resourcePath(userId)}/work/${mapId}`,
    workJson: (userId: string, mapId: string) => `${routes.workPath(userId, mapId)}/map.json`,
    workMapImage: (userId: string, mapId: string) => `${routes.workPath(userId, mapId)}/map.jpg`,

};

const logger: Logger = new Logger('SmeService');
export interface Config {
    readOnly: boolean;
    mapId: string;
    pdfId: string;
    googleId: string;
    data: any;
    html: string;
    userId: string;
    filedata: any;
    result: any;
    recents: any;
    mapJson: any;
    forceLogin: boolean;
    access_token: boolean;
    storageRef: string;
}

@Injectable({
    providedIn: 'root'
})
export class SmeService implements OnDestroy {

    private uploadFileSubscription: Subscription | undefined;
    private uploadWorkResourceSubscription: Subscription | undefined;
    private addPDfToRecentsSubscription: Subscription | undefined;
    private getRecentDataSubscription: Subscription | undefined;
    private sessionId = '';

    constructor(
        private http: HttpClient,
        private firebaseService: FirebaseService,
        private authenticationService: AuthenticationService,
        private translateService: TranslateService,
        private cookieService: CookieService
    ) {
        console.log('SmeService constructor');
    }

    ngOnDestroy() {
        if (this.uploadFileSubscription) { this.uploadFileSubscription.unsubscribe(); }
        if (this.uploadWorkResourceSubscription) { this.uploadWorkResourceSubscription.unsubscribe(); }
    }

    // private getIdTokenForFunctions(funcName: string): string {

    //     const { GoogleAuth } = require('google-auth-library');
    //     const auth = new GoogleAuth();


    // }



    public version(): any {
        return this.http.post<Response>(routes.apiVersion, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public hasUserEnoughDriveQuota(bytesToSave: number = 5000000): any {
        return new Promise((onResolve, onError) => {
            this.getUserDriveQuota().subscribe({
                next: (quota: any) => {
                    console.log(JSON.stringify(quota));

                    const limit = quota.storageQuota.limit != undefined ? Number(quota.storageQuota.limit) : -1;
                    const usage = quota.storageQuota.usage != undefined ? Number(quota.storageQuota.usage) : -1;

                    //unlimited account
                    if (limit != -1) {
                        const availableSpace = limit - usage;
                        onResolve(availableSpace >= bytesToSave);
                    } else {
                        onResolve(true);
                    }
                },
                error: (error: any) => {
                    console.log(error);
                    onError(error);
                }
            });
        });
    }

    //Get Google Drive Quota
    public getUserDriveQuota(): any {
        return this.http.get(routes.getUserDriveQuota, {})
            .pipe(
                map(
                    (res: any) => {
                        return res;
                        //esempio {"storageQuota":{"limit":"109951162777600","usage":"1585734447005","usageInDrive":"1632926463","usageInDriveTrash":"0"}}
                    }
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getAccessToken(): any {
        return this.http.post<Config>(routes.apiGetAccessToken, {}, {})
            .pipe(
                map(
                    (res: Config) => res.access_token
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getAccessTokenPromise(): any {
        // return this.getAccessToken().toPromise();
        return new Promise((resolve, reject) => {
            this.getAccessToken().subscribe((_accessToken: any) => {
                return resolve(_accessToken);
            }, (err: any) => {
                return reject(err);

            });
        });
    }

    public getAuthUser(): any {
        return this.http.post(routes.apiGetAuthUser, {}, {})
            .pipe(
                map(
                    (res: any) => res.authUser
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getAuthUserPromise(): any {
        return new Promise((resolve, reject) => {
            this.getAuthUser().subscribe((authUser: any) => {
                return resolve(authUser);
            }, (err: any) => {
                return reject(err);

            });
        });
    }

    public mustRelogged(): any {
        return this.http.post<Config>(routes.apiMustRelogged, {}, {})
            .pipe(
                map(
                    (res: Config) => res.forceLogin
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public mustReloggedPromise(): any {
        return new Promise((resolve, reject) => {
            this.mustRelogged().subscribe((forceLogin: any) => {
                return resolve(forceLogin);
            }, (err: any) => {
                return reject(err);

            });
        });
    }

    public revokeToken(accessToken: string): any {
        const body = { accessToken: accessToken };

        return this.http.post<Response>(routes.apiRevokeToken, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public revokeTokenPromise(accessToken: string): any {
        return new Promise((resolve, reject) => {
            this.revokeToken(accessToken).subscribe((res: any) => {
                return resolve(res);
            }, (err: any) => {
                return reject(err);

            });
        });
    }

    private getImageName(mapId: string, nodeId: string, name: string, storagePath?: string, fromServer: boolean = false): string {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const guid = Guid.create();
        // const extension: string = name.split('.').pop(); --> JHUBA molte non ce l'hanno, ma funzionano lo stesso
        if (storagePath) {
            return storagePath;
        } else {
            if (userId) {
                let p = routes.workPath(userId, mapId) + `/${nodeId}_${guid}.png`;
                if (fromServer) {
                    p = p.substring(1);
                }
                console.log('storagepath ' + p);
                return p; // /${extension}`;
            } else {
                return '';
            }
        }
    }

    public uploadImageFromUri(uri: string, mapId: string, nodeId: string, storagePath?: string): Observable<any> {
        const destPath = this.getImageName(mapId, nodeId, uri, storagePath, true);
        const bucketName = this.firebaseService.getBucketName();

        const body = {
            bucket: bucketName,
            uri: uri,
            destPath: destPath
        };
        return this.http.post<Response>(routes.apiUploadImageFromUri, body, {})
            .pipe(
                map((res: Response) => res), catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }


    public insertImageFromDrive(googleId: string, mapId: string, nodeId: string, storagePath?: string): Observable<any> {
        const destPath = this.getImageName(mapId, nodeId, '', storagePath, true);
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            googleId: googleId,
            bucket: bucketName,
            destPath: destPath,
            mapId: mapId
        };
        return this.http.post<Response>(routes.apiInsertImageFromDrive, body, {})
            .pipe(
                map((res: Response) => res), catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public insertImageFromSvg(svg: string, mapId: string, nodeId: string, storagePath?: string): Observable<any> {
        return new Observable<any>((observer: any) => {
            const destPath = this.getImageName(mapId, nodeId, '', storagePath);
            this.firebaseService.uploadStringPromise(svg, destPath, 'image/svg+xml').then((ref: any) => {
                getDownloadURL(ref)
                    .then((url: string) => {
                        observer.next({ url: url, destPath: destPath });
                        observer.complete();
                    }, (error: any) => {
                        logger.error('FireBase Storage NOT FOUND: ' + error.message);
                        observer.next({ url: '', destPath: '' });
                        observer.complete();
                    });
            });
        });
    }

    public addPDfToRecents(pdfId: string, name: string, googleId: string, color: string, lastUpdated: string, lastOpened: string, imported: string, storageRef: string, link: string): Observable<any> {
        // const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        //  const resourcePdfPath: string = (userId) ? routes.resourcePdfPath(userId, pdfId) : '';

        const body = {
            googleId: googleId,
            pdfId: pdfId,
            name: name,
            lastOpened: lastOpened,
            lastUpdated: lastUpdated,
            imported: imported,
            color: color,
            storageRef: storageRef,
            link: link
        };
        return this.http.post<Config>(routes.apiAddPdfToRecents, body, {})
            .pipe(
                map((res: Config) => res),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public deleteFromPdf(pdfId: string): Observable<any> {
        const body = {
            pdfId: pdfId
        }
        return this.http.post<Response>(routes.apiDeleteFromPdf, body, {})
            .pipe(
                map((res: Response) => res), catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }


    public deletePdfFromMaps(mapId: string): Observable<any> {
        const body = {
            mapId: mapId
        };
        return this.http.post<Response>(routes.apiDeletePdfFromMaps, body, {})
            .pipe(
                map((res: Response) => res), catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public updatePdfMap(pdfId: string, mapId: string): Observable<any> {
        // const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        //  const resourcePdfPath: string = (userId) ? routes.resourcePdfPath(userId, pdfId) : '';

        const body = {
            mapId: mapId,
            pdfId: pdfId
        };
        return this.http.post<Response>(routes.apiUpdatePdfMap, body, {})
            .pipe(
                map((res: Response) => res), catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public uploadPDF(pdfUpload: ResourceUpload, pdfId: string): Observable<any> {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const resourcePdfPath: string = (userId) ? routes.resourcePdfPath(userId, pdfId) : '';
        return new Observable<any>((observer: any) => {
            const name = pdfUpload.file.name.replace(/\.[^/.]+$/, '') + '.png'; // filename no ext + png

            if (this.uploadFileSubscription) { this.uploadFileSubscription.unsubscribe(); }
            this.uploadFileSubscription = this.firebaseService.uploadFile(pdfUpload, resourcePdfPath)
                .subscribe({
                    next: (ref: any) => {
                        // progress
                        if (ref >= 0 && ref <= 100) {
                            observer.next(ref);
                        } else {
                            getDownloadURL(ref)
                                .then((url: string) => {
                                    observer.next({ url: url, destPath: ref });
                                    observer.complete();
                                }, (error: any) => {
                                    logger.error('FireBase Storage NOT FOUND: ' + error.message);
                                    observer.next({ url: '', destPath: '' });
                                    observer.complete();
                                });
                        }
                    },
                    error: (err) => {
                        logger.error('upload PDF ERROR:' + err);
                        observer.next({ url: '', destPath: '' });
                        observer.complete();
                    },
                    complete: () => {

                    }

                });
        });
    }



    // public uploadPDFBlob(blob: Blob, name: string, mapId: string): Observable<any> {
    //     const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
    //     let resourcePdfPath: string = (userId) ? routes.resourcePdfPath(userId) : '';
    //     const path = require('node:path')

    //     resourcePdfPath = path.join(resourcePdfPath, name);

    //     return new Observable<any>((observer: any) => {


    //         if (this.uploadFileSubscription) { this.uploadFileSubscription.unsubscribe(); }
    //         this.uploadFileSubscription = this.firebaseService.uploadBlob(blob, resourcePdfPath)
    //             .subscribe({
    //                 next: (ref: any) => {
    //                     // progress
    //                     if (ref >= 0 && ref <= 100) {
    //                         // observer.next(ref); NON GESTITO PROGRESS
    //                     } else {
    //                         getDownloadURL(ref)
    //                             .then((url: string) => {
    //                                 observer.next({ url: url, destPath: resourcePdfPath });
    //                                 observer.complete();
    //                             }, (error: any) => {
    //                                 logger.error('FireBase Storage NOT FOUND: ' + error.message);
    //                                 observer.next({ url: '', destPath: '' });
    //                                 observer.complete();
    //                             });
    //                     }
    //                 },
    //                 error: (err) => {
    //                     logger.error('upload PDF ERROR:' + err);
    //                     observer.next({ url: '', destPath: '' });
    //                     observer.complete();
    //                 },
    //                 complete: () => {

    //                 }

    //             });
    //     });
    // }

    public downloadPdfFromDrive(googleId: string, filename: string, mapId: string): Observable<any> {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const destPath = (userId) ? routes.resourcePdfPath(userId, filename) : '';
        const body = {
            googleId: googleId,
            destPath: destPath
        };
        return this.http.post(routes.apiDownloadPdfFromDrive, body, { responseType: 'blob' })
            .pipe(
                map(
                    (res: Blob) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public savePDF(googleId: string, filePath: string, pdfName?: string) {


        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const body = {

            userId: userId,
            googleId: googleId,
            pdfName: pdfName,
            filePath: filePath
        };

        return this.http.post<Config>(routes.apiSavePdf, body, {})
            .pipe(
                map(
                    (res: Config) => {
                        return res.googleId;
                    }),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public savePDFasBlob(googleId: string, googleFolderId: string, blob: Blob, pdfName?: string) {

        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const body = {

            userId: userId,
            googleId: googleId,
            pdfName: pdfName,
            pdfBlob: blob,
            googleFolderId: googleFolderId

        };
        return this.http.post<Config>(routes.apiSavePdfasBlob, body, {})
            .pipe(
                map(
                    (res: Config) => {
                        return res.googleId;
                    }),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public uploadPDFasBlob(blob: Blob, pdfId: string) {
        let pdfPath: StorageReference;
        return new Observable<any>((observer: any) => {
            const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
            const resourcePdfPath: string = (userId) ? routes.resourcePdfPath(userId, pdfId) : '';
            this.firebaseService.uploadBlob(blob, resourcePdfPath).subscribe({
                next: (_ref) => {
                    if (_ref >= 0 && _ref <= 100) {
                        // observer.next(ref); NON GESTITO PROGRESS
                    } else {
                        pdfPath = _ref;

                    }

                },
                complete: () => {
                    getDownloadURL(pdfPath)
                        .then((url: string) => {
                            observer.next({ url: url, destPath: pdfPath });
                            observer.complete();
                        }, (error: any) => {
                            logger.error('FireBase Storage NOT FOUND: ' + error.message);
                            observer.next({ url: '', destPath: '' });
                            observer.complete();
                        });
                },
                error: (error) => {
                    logger.error('FireBase Storage NOT FOUND: ' + error.message);
                    observer.next('');
                    observer.complete();


                }
            });
        });


    }

    public getPdfRecents(): any {
        return this.http.post<Config>(routes.apiGetPdfRecents, {}, {})
            .pipe(
                map(
                    (res: Config) => res.recents
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public getPdfFromStorage(storageRef: string): Observable<any> {
        return new Observable<any>((observer: any) => {
            const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
            //const destPath = (userId) ? routes.resourcePdfPath(userId) : '';
            this.firebaseService.getDownloadUri(storageRef).then((url) => {
                if (url !== '') {
                    this.firebaseService.downloadResourceAsBlob(storageRef, 'application/pdf').subscribe({
                        next: (pdfBlob) => {
                            observer.next(pdfBlob);
                            observer.complete();

                        },
                        error: (err) => {
                            logger.error('insertImage ERROR:' + err);
                            observer.error({ url: '', destPath: '' });
                            observer.complete();
                        }
                    });
                } else {
                    observer.error('');
                    observer.complete();

                }
            }).catch(() => {
                observer.error('');
                observer.complete();
            });
        });

    }


    private checkPdfMaps(mapId: string): Observable<any> {


        //  const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const body = {
            mapId: mapId
        };
        return this.http.post<Config>(routes.apiGetPdfFromMap, body, {})
            .pipe(
                map(
                    (res: Config) => {
                        return res.pdfId;
                    }),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }


    public getPdfStorageById(pdfId: string): Observable<any> {
        const body = {
            pdfId: pdfId
        };
        return this.http.post<Config>(routes.apiGetPdfStorageById, body, {})
            .pipe(
                map(
                    (res: Config) => {
                        return { storageRef: res.data.storageRef, name: res.data.name, color: res.data.color };
                    }),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));

    }

    public checkPdfToLoad(mapId: string): Observable<any> {
        return new Observable<any>((observer: any) => {
            const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
            this.checkPdfMaps(mapId).subscribe({
                next: (_pdfId: string) => {
                    const pdfId = _pdfId;
                    const destPath = (userId) ? routes.resourcePdfPath(userId, pdfId) : '';
                    if (pdfId !== null && pdfId != '') {
                        this.getPdfStorageById(pdfId).subscribe({
                            next: (data) => {
                                if (data !== '') {
                                    // this.firebaseService.downloadResourceFromStorageAsBlob(storageRef, 'application/pdf').subscribe({
                                    //   next: (pdfBlob) => {
                                    observer.next({ storageRef: data.storageRef, pdfId: pdfId, name: data.name, color: data.color });
                                    observer.complete();

                                    //   //  },
                                    //     error: (err) => {
                                    //         logger.error('insertImage ERROR:' + err);
                                    //         observer.error({ url: '', destPath: '' });
                                    //         observer.complete();
                                    //     }
                                    // });
                                } else {
                                    observer.next('');
                                    observer.complete();

                                }
                            }, error: () => {
                                observer.error('');
                                observer.complete();
                            }
                        });
                    } else {
                        observer.next('');
                        observer.complete();

                    }

                },
                error: (error) => {
                    observer.error(error);
                    observer.complete();
                }
            });
        });
    }

    public downloadPDf(resourceFullPath: string): Observable<Blob> {

        return this.firebaseService.downloadResourceAsBlob(resourceFullPath, 'application/pdf');

    }

    public insertImage(imageUpload: ResourceUpload, mapId: string, nodeId: string): Observable<any> {
        return new Observable<any>((observer: any) => {
            const name = imageUpload.file.name.replace(/\.[^/.]+$/, '') + '.png'; // filename no ext + png
            const destPath = this.getImageName(mapId, nodeId, name);
            if (this.uploadFileSubscription) { this.uploadFileSubscription.unsubscribe(); }
            this.uploadFileSubscription = this.firebaseService.uploadFile(imageUpload, destPath)
                .subscribe({
                    next: (ref: any) => {
                        // progress
                        if (ref >= 0 && ref <= 100) {
                            // observer.next(ref); NON GESTITO PROGRESS
                        } else {
                            getDownloadURL(ref)
                                .then((url: string) => {
                                    observer.next({ url: url, destPath: destPath });
                                    observer.complete();
                                }, (error: any) => {
                                    logger.error('FireBase Storage NOT FOUND: ' + error.message);
                                    observer.next({ url: '', destPath: '' });
                                    observer.complete();
                                });
                        }
                    },
                    error: (err) => {
                        logger.error('insertImage ERROR:' + err);
                        observer.next({ url: '', destPath: '' });
                        observer.complete();
                    },
                    complete: () => {

                    }

                });
        });
    }

    public downloadMap(resourceFullPath: string): Observable<any> {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;

        return this.firebaseService.downloadResource(resourceFullPath);
    }

    public uploadMap(upload: ResourceUpload, mapId?: string): Observable<any> {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        let resourceSmePath = '';
        if (mapId) {
            resourceSmePath = (userId) ? routes.resourceSmePath(userId, mapId) : '';
        } else {
            resourceSmePath = (userId) ? routes.resourceSme(userId, upload.file.name) : '';
        }

        return this.firebaseService.uploadFile(upload, resourceSmePath);
    }

    /**
     *
     * param mapId L'id della mappa corrente
     * param filename Il nome del file di cui si vuole l'URL (oppure il suo path relativo partendo da work)
     * returns Una promise che contiene l'URL che ha nello Storage di Firebase la risorsa filename
     * dentro la cartella work dell'utente corrente.
     */
    public resolveWorkUrl(mapId: string, filename: string): Promise<any> {
        let userId;
        if (this.authenticationService.isAuthenticated()) {
            userId = this.authenticationService.credentials?.firebaseUserId;
        }
        const storageUrl = (userId) ? `${routes.workPath(userId, mapId)}/${filename}` : '';
        return this.firebaseService.resolveStorageUrl(storageUrl);
    }

    public createNewMap(googleFolderId?: string): Observable<string> {
        const mapName = this.translateService.instant(extract('NEW_MAP_NAME'));
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            bucket: bucketName,
            mapName: mapName,
            // googleFolderId: googleFolderId
        };
        return this.http.post<Config>(routes.apiCreateNewMap, body, {})
            .pipe(
                map(
                    (res: Config) => res.mapId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public createNewGoogleDocument(name: string, json: any, locale: any, notes: any, mapId: string, googleFolderId: string, googleDocFileId: string): Observable<string> {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            format: 'google',
            name: name,
            googleFolderId: googleFolderId,
            bucket: bucketName,
            mapid: mapId,
            json: json,
            locale: locale,
            notes: notes,
            googleDocFileId: googleDocFileId
        };
        return this.http.post<Config>(routes.apiCreateNewDoc, body, {})
            .pipe(
                map(
                    (res: Config) => res.googleId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public createNewDocument(format: string, name: string, json: any, locale: any, notes: any, mapId: string): Observable<any> {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            format: format,
            name: name,
            bucket: bucketName,
            mapid: mapId,
            json: json,
            locale: locale,
            notes: notes
        };
        if (format === 'docx-ms' || format === 'docx-lo') {
            // Docx
            return this.http.post(routes.apiCreateNewDoc, body, { responseType: 'blob' })
                .pipe(
                    map(
                        (res: Blob) => res
                    ),
                    catchError((error: any) => {
                        logger.error(error);
                        return observableThrowError(() => error);
                    }));
        } else {
            // PDF
            return this.http.post<Config>(routes.apiCreateNewDoc, body, {})
                .pipe(
                    map(
                        (res: Config) => res.html
                    ),
                    catchError((error: any) => {
                        logger.error(error);
                        return observableThrowError(() => error);
                    }));
        }
    }

    public exportMapToGDrive(googleFolderId: string, name: string, mapId: string): Observable<string> {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            mapName: name,
            googleFolderId: googleFolderId,
            bucket: bucketName,
            mapId: mapId
        };
        return this.http.post<Config>(routes.apiExportSmeToGDrive, body, {})
            .pipe(
                map(
                    (res: Config) => res.googleId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public updateSideBar(open: boolean) {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;


        const body = {
            userId: userId,
            sideBarOpen: open

        };
        return this.http.post<string>(routes.apiUpdatesidebar2gen, body, {})
            .pipe(
                map(
                    (res: string) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getDesmosConfig() {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;


        const body = {
            userId: userId,

        };
        return this.http.post<Config>(routes.apiGetDesmosConfig, body, {})
            .pipe(
                map(
                    (res: Config) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }


    public setDesmosConfig(desmosConfig: DesmosConfigDto) {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;


        const body = {
            userId: userId,
            desmosConfig: desmosConfig

        };
        return this.http.post<Config>(routes.apiSetDesmosConfig, body, {})
            .pipe(
                map(
                    (res: Config) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }
    public prepareMap(mapId: string) {
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const bucketName = this.firebaseService.getBucketName();
        const resPath: string = (userId) ? routes.resourcePath(userId) : '';

        const body = {
            mapid: mapId,
            userId: userId,
            bucket: bucketName,
            resPath: resPath
        };
        return this.http.post<Config>(routes.apiPrepareMap, body, {})
            .pipe(
                map(
                    (res: Config) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }
    public exportSmeInGDrive(googleFolderId: string, blob: Blob): Observable<string> {

        const body = {
            blob: blob,
            googleFolderId: googleFolderId
        };
        return this.http.post<string>(routes.apiExportSmeToGDrive, body, {})
            .pipe(
                map(
                    (res: string) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }
    public moveMap(googleFolderId: string, mapId: string): Observable<string> {

        const body = {
            mapId: mapId,
            googleFolderId: googleFolderId
        };
        return this.http.post<string>(routes.apiMoveMap, body, {})
            .pipe(
                map(
                    (res: string) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public createWorkMapFromStorage(fileRef: any, mapName: string, mapId: string): Observable<string> {
        // const googleUserId = this.authenticationService.reloadCredentials()?.googleUserId;
        const body = {
            bucket: fileRef.bucket,
            // googleUserId: googleUserId,
            filePath: fileRef.fullPath,
            mapName: mapName,
            mapId: mapId
            // googleFolderId: googleFolderId
        };
        return this.http.post<Config>(routes.apiCreateWorkMapFromStorage, body, {})
            .pipe(
                map(
                    (res: Config) => res.mapId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }


    public createWorkMapFromGoogleDrive(fileId: string, googleUserId?: string): Observable<string> {

        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const bucketName = this.firebaseService.getBucketName();
        const resPath: string = (userId) ? routes.resourcePath(userId) : '';

        const body = {
            fileId: fileId,
            userId: userId,
            bucket: bucketName,
            resPath: resPath
        };
        return this.http.post<string>(routes.apiCreateWorkMapFromGoogleDrive, body, {})
            .pipe(
                map(
                    (res: string) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public createCopyMap(mapId: string, mapName: string): Observable<string> {
        //  const googleUserId = this.authenticationService.reloadCredentials()?.googleUserId;

        const bucketName = this.firebaseService.getBucketName();
        const body = {
            mapId: mapId,
            mapName: mapName,
            bucket: bucketName
        };
        return this.http.post<Config>(routes.apiCreateCopyMap, body, {})
            .pipe(
                map(
                    (res: Config) => res.mapId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }
    public createCopyMapInGoogleDrive(fileId: string, mapName: string, googleFolderId: string): Observable<string> {
        //  const googleUserId = this.authenticationService.reloadCredentials()?.googleUserId;
        const userId = this.authenticationService.reloadCredentials()?.firebaseUserId;
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            fileId: fileId,
            userId: userId,
            mapName: mapName,
            googleFolderId: googleFolderId,
            bucket: bucketName
        };
        return this.http.post<Config>(routes.apiCreateCopyMapInGoogleDrive, body, {})
            .pipe(
                map(
                    (res: Config) => res.mapId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public downloadJsonToView(mapId: string, withCredentials: boolean) {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            bucket: bucketName,
            mapId: mapId
        };
        const options = (withCredentials) ? {} : { headers: TokenInterceptor.buildNoTokenHeaders() };
        return this.http.post<Config>(routes.apiViewMap, body, options)
            .pipe(
                map(
                    (res: Config) => res.data
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public downloadJsonToEdit(mapId: string) {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            bucket: bucketName,
            mapId: mapId
        };
        return this.http.post<Config>(routes.apiEditMap, body, {})
            .pipe(
                map(
                    (res: Config) => res.data
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public restoreMap(mapId: string) {
        const body = {
            mapId: mapId
        };
        return this.http.post<Config>(routes.apiUntrashFile, body, {})
            .pipe(
                map(
                    (res: Config) => res.data
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public restoreMapFromStorage(mapId: string) {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            bucket: bucketName,
            mapId: mapId
        };
        return this.http.post<Config>(routes.apiRestoreMapFromStorage, body, {})
            .pipe(
                map(
                    (res: Config) => res.data
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public searchForMap(mapId: string): Observable<string> {
        const body = {
            mapId: mapId
        };
        return this.http.post<Config>(routes.apiSearchMapInStorage, body, {})
            .pipe(
                map(
                    (res: Config) => res.userId
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public insertMapShared(mapId: string, shareType: string): Observable<string> {
        const body = {
            mapId: mapId,
            shareType: shareType
        };
        return this.http.post<string>(routes.apiInsertMapShared, body, {})
            .pipe(
                map(
                    (res: string) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public uploadWorkJson(userId: string, mapId: string, resource: any) {
        // let userId = '';
        // if (this.authenticationService.reloadCredentials()) {
        //   userId = this.authenticationService.credentials.firebaseUserId;
        // }
        // const json = JSON.parse(resource);
        // if (json.nodes.length === 0) {
        //   console.log(`++++++++++++++++++SALVATAGGIO CON MAPPA VUOTA!!!`);
        // }
        const jsonResource: string = routes.workJson(userId, mapId);
        return this.firebaseService.fsUploadString(resource, jsonResource);
    }

    public uploadWorkResource(userId: string, mapId: string, resource: any, name: string) {
        if (resource) {
            // let userId = '';
            // if (this.authenticationService.reloadCredentials()) {
            //   userId = this.authenticationService.credentials.firebaseUserId;
            // }
            const workResource: string = routes.workPath(userId, mapId) + '/' + name;
            return this.firebaseService.uploadBase64(resource, workResource, 'image/jpg');
        } else {
            return new Observable((observer: any) => {
                observer.next('');
                observer.complete();
            });
        }
    }

    public getUrlResolver(): FirebaseStorageUrlResolver {
        return new FirebaseStorageUrlResolver(this.firebaseService);
    }

    public saveMapRequest(mapId: string, mapWorkJson: any, mapOperationsData: any): Observable<any> {
        this.authenticationService.reloadCredentials();
        // const googleUserId = this.authenticationService.credentials?.googleUserId;
        const userId = this.authenticationService.credentials?.firebaseUserId;
        const saveToDrive = this.authenticationService.credentials?.providerId === 'google.com';
        const bucketName = this.firebaseService.getBucketName();
        let mapName = '';
        try {
            const workjson = JSON.parse(mapWorkJson);
            mapName = workjson.mapProps.mapName;
        } catch (error) {
            logger.error(error);
            return observableThrowError(() => error);
        }
        const body = {
            mapId: mapId,
            userId: userId,
            saveAs: false,
            bucket: bucketName,
            mapName: mapName,
            saveToDrive: false, //saveToDrive,
            mapWorkJson: mapWorkJson,
            operations: mapOperationsData
        };

        return this.http.post(routes.apiSaveSme, body, { responseType: 'blob' })
            .pipe(
                map(
                    (res: Blob) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public saveMap(mapId: string, imgPreview: any, imgMap: any, mapWorkjson: any, mapOperationsData: any): Observable<any> {
        return new Observable((observer: any) => {
            if (this.uploadWorkResourceSubscription) { this.uploadWorkResourceSubscription.unsubscribe(); }
            this.findOwnerIdByMapId(mapId).subscribe((result: any) => {
                const ownerId = result.ownerId;
                this.uploadWorkResourceSubscription = this.uploadWorkResource(ownerId, mapId, imgPreview, 'preview.jpg')
                    .pipe(mergeMap(() => this.uploadWorkResource(ownerId, mapId, imgMap, 'map.jpg')))
                    .pipe(mergeMap(() => this.uploadWorkJson(ownerId, mapId, mapWorkjson)))
                    .pipe(mergeMap(() => this.saveMapRequest(mapId, mapWorkjson, mapOperationsData)))
                    .subscribe({
                        next: (blob: Blob) => {
                            observer.next(blob);
                            observer.complete();
                        },
                        error: (error: any) => {
                            observer.error(error);
                        }
                    });
            });
        });
    }

    public exportSmeRequest(mapId: string): Observable<any> {
        const body = {
            bucket: this.firebaseService.getBucketName(),
            mapid: mapId
        };
        // return this.http.post(routes.apiExportSme, body, { cache: false, responseType: ResponseContentType.Blob })

        return this.http.post(routes.apiExportSme, body, { responseType: 'blob' })
            .pipe(
                map(
                    (res: Blob) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public exportSme(mapId: string, imgPreview: any, imgMap: any, mapWorkjson: any): Observable<any> {
        return new Observable((observer: any) => {
            if (this.uploadWorkResourceSubscription) { this.uploadWorkResourceSubscription.unsubscribe(); }
            this.findOwnerIdByMapId(mapId).subscribe((result: any) => {
                const ownerId = result.ownerId;
                this.uploadWorkResourceSubscription = this.uploadWorkResource(ownerId, mapId, imgPreview, 'preview.jpg').pipe(
                    mergeMap(() => this.uploadWorkResource(ownerId, mapId, imgMap, 'map.jpg'))).pipe(
                        mergeMap(() => this.uploadWorkJson(ownerId, mapId, mapWorkjson))).pipe(
                            mergeMap(() => this.exportSmeRequest(mapId)))
                    .subscribe({
                        next: (sme: any) => {
                            observer.next(sme);
                            observer.complete();
                        },
                        error: (error: any) => {
                            observer.error(error);
                            observer.complete();
                        }
                    });
            });
        });
    }


    public renameMap(mapId: any, newName: string): Observable<any> {
        const body = { mapId: mapId, mapName: newName };
        return this.http.post<Response>(routes.apiRenameMap, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public awaitableGetRecentData(email: string, mapInfo: any) {
        return new Promise((resolve, reject) => {
            if (this.getRecentDataSubscription) { this.getRecentDataSubscription.unsubscribe(); }
            this.getRecentDataSubscription = this.getRecentData(email, mapInfo).subscribe((data: any) => {
                return resolve(data);
            });
        });
    }

    public getRecentData(email: string, mapInfo: any) {
        const body = { email: email, mapInfo: mapInfo };
        return this.http.post<Response>(routes.apiGetRecentData, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    // public getRecentsData(email: string, mapList: Array<string>) {
    //     const body = { email: email, mapList: mapList };
    //     return this.http.post<Response>(routes.apiGetRecentsData, body, {})
    //         .pipe(
    //             map(
    //                 (res: Response) => res
    //             ),
    //             catchError((error: any) => {
    //                 logger.error(error);
    //                 return observableThrowError(() => error);
    //             }));

    // }
    // public getFileData(mapId: any): Observable<any> {
    //     const body = { mapId: mapId };
    //     return this.http.post<Config>(routes.apiGoogleUserForMap, body, {})
    //         .pipe(
    //             map(
    //                 (res: Config) => res.filedata
    //             ),
    //             catchError((error: any) => {
    //                 logger.error(error);
    //                 return observableThrowError(() => error);
    //             }));
    // }

    public userMapsRevisions(mapId: string): Observable<any> {
        const body = { mapId: mapId };
        return this.http.post<Response>(routes.apiUserMapsRevisions, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getMapJpegFromRevision(mapId: string, revisionId: string, lastUdate: any): Observable<any> {
        const bucketName = this.firebaseService.getBucketName();
        const body = { mapId: mapId, revId: revisionId, bucket: bucketName, lastUpdate: lastUdate };
        return this.http.post<Response>(routes.apiGetMapJpegFromRevision, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public restoreRevision(mapId: string, revisionId: string): Observable<any> {
        const bucketName = this.firebaseService.getBucketName();
        const body = { mapId: mapId, revId: revisionId, bucket: bucketName };
        return this.http.post<Config>(routes.apiRestoreRevision, body, {})
            .pipe(
                map(
                    (res: Config) => res.result
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public copyRevision(mapId: string, revisionId: string, newName: string): Observable<any> {
        const bucketName = this.firebaseService.getBucketName();
        const body = { mapId: mapId, revId: revisionId, bucket: bucketName, newName: newName };
        return this.http.post<Config>(routes.apiCopyRevision, body, {})
            .pipe(
                map(
                    (res: Config) => res.result
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getRecentMaps(): any {
        const bucketName = this.firebaseService.getBucketName();
        const body = { bucket: bucketName };
        return this.http.post<Config>(routes.apiRecentMaps, body, {})
            .pipe(
                map(
                    (res: Config) => res.recents
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getUserPreferences(): any {
        const body = {};
        return this.http.post<Response>(routes.apiUserPreferences, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public updateUserPreference(userPref: any) {
        const body = { userPref: userPref };
        return this.http.post<Response>(routes.apiUserPreferences, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public removeRecent(mapId: string): any {
        const bucketName = this.firebaseService.getBucketName();
        const body = { mapId: mapId, bucket: bucketName };
        return this.http.post<Response>(routes.apiRemoveRecent, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public trashRecent(mapId: string, operation: 'trash' | 'restore'): any {
        const trash = (operation === 'trash' ? true : false);
        const body = { mapId: mapId, trash: trash };
        return this.http.post<Response>(routes.apiTrashRecent, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }


    public stopSharing(mapId: string): any {
        const body = {
            mapId: mapId
        };
        return this.http.post<Response>(routes.apiStopSharing, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    private getBaseName(str: string) {
        let base = str.substring(str.lastIndexOf('/') + 1);
        if (base.lastIndexOf('.') !== -1) {
            base = base.substring(0, base.lastIndexOf('.'));
        }
        return base;
    }

    // public getWhitelist(): any {
    //     const body = {};
    //     return this.http.post<Response>(routes.apiGetWhitelist, body, {})
    //         .pipe(
    //             map(
    //                 (res: Response) => res
    //             ),
    //             catchError((error: any) => {
    //                 logger.error(error);
    //                 return observableThrowError(() => error);
    //             }));
    // }

    // public setWhitelist(whitelist: string[]): any {
    //     const body = { emails: whitelist };
    //     return this.http.post<Response>(routes.apiSetWhitelist, body, {})
    //         .pipe(
    //             map(
    //                 (res: Response) => res
    //             ),
    //             catchError((error: any) => {
    //                 logger.error(error);
    //                 return observableThrowError(() => error);
    //             }));
    // }

    public writeGoogleUserData(googleUserData: any) {
        const body = {
            googleUserData: googleUserData
        };
        return this.http.post<Response>(routes.apiWriteGoogleUserData, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public unlinkGoogleEmailForUser() {

        return this.http.post<Response>(routes.apiUnlinkGoogleEmailForUser, {}, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public setUserInfo(firebaseUserId: string, fbUserEmail: string, googleUserEmail: string, googleUserId: string, googleUserName: string, lastLogin: string, privacyAccepted?: string, newsletter?: boolean, userType?: string, ageRange?: string) {
        const body = {
            fbUserEmail: fbUserEmail,
            firebaseUserId: firebaseUserId,
            googleUserEmail: googleUserEmail,
            googleUserId: googleUserId,
            googleUserName: googleUserName,
            lastLogin: lastLogin,
            privacyAccepted: privacyAccepted,
            newsletter: newsletter,
            userType: userType,
            ageRange: ageRange
        };
        return this.http.post<Response>(routes.apiSetUserInfo, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public hasUserAcceptedPrivacy(firebaseUserId: string) {
        const body = {
            firebaseUserId: firebaseUserId
        };
        return this.http.post<Response>(routes.apiHasUserAcceptedPrivacy, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public setSessionId(sessionId: string) {
        const body = { sessionId: sessionId };
        this.sessionId = sessionId;
        return this.http.post<Response>(routes.apiSetSessionId, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getSessionId(): string {
        if (this.sessionId === '') {
            const cookies = this.cookieService.getAll();
            const sessionCookie = this.cookieService.get('smxsession');
            if (sessionCookie) {
                this.sessionId = sessionCookie;
            } else {
                this.sessionId = Guid.create().toString();
                this.cookieService.set('smxsession', this.sessionId, 100, '/');
            }
        }
        return this.sessionId;
    }

    public checkSessionId() {

        if (!window.location.href.includes('web.supermappex.it')) {
            return new Observable<any>(observer => {
                const res = {
                    ok: true,
                    result: true
                };
                observer.next(res);
                observer.complete();
            })
        }

        const body = { sessionId: this.getSessionId() };

        return this.http.post<Response>(routes.apiCheckSessionId, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    return observableThrowError(() => error);
                }));
    }

    public getIntroMaps(): any {
        const body = {};
        return this.http.post<Response>(routes.apiGetIntroMaps, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getShareData(email: string, firebaseUserId: string, mapId: string): any {
        if (!email) email = '';
        if (!firebaseUserId) firebaseUserId = '';
        const body = {
            email: email,
            firebaseUserId: firebaseUserId,
            mapId: mapId
        };
        const options = { headers: TokenInterceptor.buildNoTokenHeaders() };
        return this.http.post<Response>(routes.apiGetShareData, body, options)
            .pipe(
                map((res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    // NOTIFICHE

    public userListNotifications(expireDateSubscription: any) {
        const body = {
            expireDate: expireDateSubscription
        };
        return this.http.post<Response>(routes.userListNotifications, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public setUserNotificationsShownState(shown: boolean) {
        const body = {
            shown: shown
        };
        return this.http.post<Response>(routes.setUserNotificationsShownState, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public adminListNotifications() {
        const body = {
        };
        return this.http.post<Response>(routes.adminListNotifications, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public insertOrUpdateNotification(notification: NotificationsDto) {
        const id = (notification.id) ? notification.id : '';
        const body = {
            notificationId: id,
            title: notification.title,
            typeIcon: notification.typeIcon,
            dateCreated: notification.dateCreated,
            active: notification.active,
            notes: notification.notes,
            link: notification.link
        };
        return this.http.post<Response>(routes.adminInsertNotification, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }


    // STATISTICHE

    /**
     * Aggiungi gruppo di statistiche a firestore db
     * param operations
     */
    public addStatRecords(operations: any): any {
        const body = {
            operations: operations
        };
        return this.http.post<Response>(routes.addStatRecords, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    /**
     * Ottieni numero statistiche per intervallo di tempo, utente e tipo di operzione
     * param email
     * param startDate
     * param endDate
     * param idOperation
     */
    public getStatRecords(email: string, startDate: any, endDate: any, idOperation: string): any {
        const body = {
            email: email,
            startDate: startDate,
            endDate: endDate,
            idOperation: idOperation
        };
        /*
        return this.http.post(routes.getStatRecords, body, {})
          .pipe(
            map(
              (res: Response) => res
            ),
            catchError((error: any) => {
              logger.error(error);
              return observableThrowError(() => error);
            }));
            */
    }

    /**
     * Ottieni lista delle statistiche per intervallo di tempo e utente
     * param email
     * param startDate
     * param endDate
     */
    public readSingleUserStats(email: string, startDate: string, endDate: string): any {
        const body = {
            email: email,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post<Response>(routes.readSingleUserStats, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    /**
     * Ottieni lista delle statistiche per intervallo di tempo e ordine
     * param orderId
     * param startDate
     * param endDate
     */
    public readOrderStatsStep1(orderId: string, startDate: string, endDate: string): any {
        const body = {
            orderId: orderId,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post<Response>(routes.readOrderStatsStep1, body, {}) // headers: new HttpHeaders({ timeout: `${5400000}` }) })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public readOrderStatsStep2(orderId: string, startDate: string, endDate: string): any {
        const body = {
            orderId: orderId,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post<Response>(routes.readOrderStatsStep2, body, {}) // headers: new HttpHeaders({ timeout: `${5400000}` }) })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public readOrderStatsStep3(orderId: string, startDate: string, endDate: string): any {
        const body = {
            orderId: orderId,
            startDate: startDate,
            endDate: endDate
        };
        return this.http.post<Response>(routes.readOrderStatsStep3, body, {}) // headers: new HttpHeaders({ timeout: `${5400000}` }) })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public readOrderStatsStep4(orderId: string): any {
        const body = {
            orderId: orderId,
        };
        return this.http.post<Response>(routes.readOrderStatsStep4, body, {}) // headers: new HttpHeaders({ timeout: `${5400000}` }) })
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    /**
     * Registra statistica custom (evento scatenato da altro utente)
     * param mapId
     * param idOperation
     * param userEmail
     */
    public addCustomMapStat(mapId: string, idOperation: string, userEmail: string): any {
        const bucketName = this.firebaseService.getBucketName();
        const body = {
            bucket: bucketName,
            mapId: mapId,
            idOperation: idOperation,
            userEmail: userEmail
        };

        const options = { headers: TokenInterceptor.buildNoTokenHeaders() };

        return this.http.post<Response>(routes.addCustomMapStat, body, options)
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public freezeSubscription(year: number, month: number): any {
        const body = {
            year: year,
            month: month
        };
        return this.http.post<Response>(routes.freezeSubscription, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    // ----------------------------------------------------------------------------------
    public getStatsByOriginAndYearStep1(origin: string, year: number) {
        const body = {
            origin: origin,
            year: year
        };
        return this.http.post<Response>(routes.getStatsByOriginAndYearStep1, body, {})
            .pipe(
                timeout(180000),
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getStatsByOriginAndYearStep2(year: number) {
        const body = {
            year: year
        };
        return this.http.post<Response>(routes.getStatsByOriginAndYearStep2, body, {})
            .pipe(
                timeout(180000),
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getStatsByOriginAndYearStep3(year: number) {
        const body = {
            year: year
        };
        return this.http.post<Response>(routes.getStatsByOriginAndYearStep3, body, {})
            .pipe(
                timeout(180000),
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getStatsByOriginAndYearStep4(year: number) {
        const body = {
            year: year
        };
        return this.http.post<Response>(routes.getStatsByOriginAndYearStep4, body, {})
            .pipe(
                timeout(180000),
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getStatsByOriginAndYearStep5(subs: any, totUsers: any, activeUsers: any, openedMaps: any) {
        const body = {
            subs: subs,
            totUsers: totUsers,
            activeUsers: activeUsers,
            openedMaps: openedMaps
        };
        return this.http.post<Response>(routes.getStatsByOriginAndYearStep5, body, {})
            .pipe(
                timeout(180000),
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    // ----------------------------------------------------------------------------------


    public getDomainsStatsByYear(year: number) {
        const body = {
            year: year
        };
        return this.http.post<Response>(routes.getDomainsStatsByYear, body, {})
            .pipe(
                timeout(540000),
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public findMapSharedInfo(mapId: string) {
        const body = {
            mapId: mapId,
        };
        return this.http.post<Response>(routes.findMapSharedInfo, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public writeShares(mapId: string, shares: any) {
        const body = {
            mapId: mapId,
            shares: shares
        };
        return this.http.post<Response>(routes.apiWriteShares, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                })
            );
    }

    public findOwnerIdByMapId(mapId: string) {
        const body = {
            mapId: mapId,
        };
        return this.http.post<Response>(routes.findOwnerIdByMapId, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public deleteSubscription(orderId: string) {
        const body = {
            orderId: orderId,
        };
        return this.http.post<Response>(routes.deleteSubscription, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public setDriveDocumentAsPublic(googleId: string, shareType: string) {
        const body = {
            googleId: googleId,
            shareType: shareType
        };
        return this.http.post<Response>(routes.setDriveDocumentAsPublic, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public findMapLinkInfo(origin: string, googleId: string) {
        const body = {
            origin: origin,
            googleId: googleId
        };
        return this.http.post<Response>(routes.findMapLinkInfo, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public addUserToNewsletter(fullName: string, email: string, interests: any) {
        const body = {
            fullName: fullName,
            email: email,
            interests: interests
        };
        return this.http.post<Response>(routes.addUserToNewsletter, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public activateDemo(fullName: string, email: string) {
        const body = {
            fullName: fullName,
            email: email,
        };
        return this.http.post<Response>(routes.activateDemo, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public unlockOrders(orderIds: Array<string>) {
        const body = {
            orderIds: orderIds
        };
        return this.http.post<Response>(routes.unlockOrders, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    /**
     * Call the 'recursiveDelete' callable function with a path to initiate
     * a server-side delete.
     */
    public deleteMapEvents(deletionType: string, mapId: string, toDateTime: string) {
        const body = {
            deletionType: deletionType,
            mapId: mapId,
            toDateTime: toDateTime
        };
        return this.http.post<Response>(routes.apiDeleteMapEvents, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
        // const q = query(citiesRef, orderBy("state"), orderBy("population", "desc"));
        // const q = query(collectionRef, orderBy('__name__'), limit(batchSize));
        // return new Promise((resolve, reject) => {
        //     this.deleteQueryBatch(db, q, resolve).catch(reject);
        // });
    }

    /**
     * Call the 'recursiveDelete' callable function with a path to initiate
     * a server-side delete.
     */
    public deleteChat(mapId: string, batchSize: number) {
        const body = {
            mapId: mapId,
            batchSize: batchSize
        };
        return this.http.post<Response>(routes.apiDeleteChat, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
        // const q = query(citiesRef, orderBy("state"), orderBy("population", "desc"));
        // const q = query(collectionRef, orderBy('__name__'), limit(batchSize));
        // return new Promise((resolve, reject) => {
        //     this.deleteQueryBatch(db, q, resolve).catch(reject);
        // });
    }

    public latex2image(latex1: string, latex2: string) {
        const body = {
            latex1: latex1,
            latex2: latex2
        };
        return this.http.post<Response>(routes.apiLatex2Image, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getOcrMathPix(email: string, imageBase64: string, latexFormat: boolean) {
        const body = {
            email: email,
            imageBase64: imageBase64,
            latexFormat: latexFormat
        };
        return this.http.post<Response>(routes.apiGetOcrMathPix, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getOcrMathPixScanCount(email: string) {
        const body = {
            email: email
        };
        return this.http.post<Response>(routes.apiGetOcrMathPixScanCount, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public getOcrMathPixScansLeft(subscriptionId: string) {
        const body = {
            subscriptionId: subscriptionId
        };
        return this.http.post<Response>(routes.apiGetOcrMathPixScansLeft, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public listUsers(searchText: string) {
        const body = {
            searchText: searchText
        };
        return this.http.post<Response>(routes.apiListUsers, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public revokeTokenbyEmail(email: string) {
         const body = {
            email: email
          
        };
        return this.http.post<Response>(routes.apiRevokeToken, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public deleteUserByEmail(email: string, deleteData: boolean) {
        const body = {
            email: email,
            deleteData: deleteData
        };
        return this.http.post<Response>(routes.apiDeleteUserByEmail, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public uploadMapToDrive(mapId: string, folderId: string) {
        const body = {
            mapId: mapId,
            folderId: folderId
        };
        return this.http.post<Response>(routes.apiUploadMapToDrive, body, {})
            .pipe(
                map(
                    (res: Response) => res
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }


    public parseErrorFromGoogleDrive(error: any): string {
        let errorString = '';
        if (error.allErrors != null) {
            error.allErrors.array.forEach((err: any) => {
                switch (err.reason) {
                    case 'authError':
                        // Invalid Credentials
                        errorString = 'AUTH_ERROR';
                        break;
                    case 'activeItemCreationLimitExceeded':
                        // This account has exceeded the creation limit of 500 million items. To create more items, permanently delete some items.
                        errorString = 'ACTIVE_ITEM_CREATION_LIMIT_EXCEEDED';
                        break;
                    case 'dailyLimitExceeded':
                        // Daily Limit Exceeded
                        errorString = 'DAILY_LIMIT_EXCEEDED';
                        break;
                    case 'fileOwnerNotMemberOfTeamDrive':
                        // Cannot move a file into a shared drive as a writer when the owner of the file is not a member of that shared drive.
                        errorString = 'FILE_OWNER_NOT_MEMEBER_OF_TEAM_DRIVE';
                        break;
                    case 'insufficientFilePermissions':
                        // The user does not have sufficient permissions for file
                        errorString = 'INSUFFICIENT_FILE_PERMISSIONS';
                        break;
                    case 'myDriveHierarchyDepthLimitExceeded':
                        // Your My Drive can't contain more than 100 levels of folders.
                        errorString = 'DRIVE_HIERARCHY_DEPTH_LIMIT_EXCEEDED';
                        break;
                    case 'storageQuotaExceeded':
                        // The user's Drive storage quota has been exceeded.
                        errorString = 'STORAGE_QUOTA_EXCEEDED';
                        break;
                    case 'teamDriveFileLimitExceeded':
                        // The file limit for this shared drive has been exceeded.
                        errorString = 'TEAM_DRIVE_FILE_LIMIT_EXCEEDED';
                        break;
                    case 'teamDriveMembershipRequired':
                        // The attempted action requires shared drive membership.
                        errorString = 'TEAM_DRIVE_MEMBERSHIP_REQUIRED';
                        break;
                    case 'userRateLimitExceeded':
                        // User Rate Limit Exceeded.
                        errorString = 'USER_RATE_LIMIT_EXCEEDED';
                        break;
                }
            });
        }

        return errorString;
    }

    public emptyTrash() {
        return this.http.post(routes.apiEmptyTrash, {})
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public moveMaps(oldUserID: string, newUserID: string) {
        const body = { oldUserID: oldUserID, newUserID: newUserID };
        return this.http.post(routes.apiMoveMaps, body, {})
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }

    public moveMapsinEmail(oldUserID: string, newUserEmail: string) {
        const body = { oldUserID: oldUserID, newUserEmail: newUserEmail };
        return this.http.post(routes.apiMoveMapsinEmail, body, {})
            .pipe(
                map(
                    (res: any) => {
                        return res;
                    }
                ),
                catchError((error: any) => {
                    logger.error(error);
                    return observableThrowError(() => error);
                }));
    }


}
