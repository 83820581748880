import { HttpClient } from "@angular/common/http";
import { Injectable, EventEmitter } from "@angular/core";
import { map, catchError } from "rxjs/operators";
import { Subscription } from "rxjs";
import { SmService } from "supermappe-core";
import { throwError as observableThrowError } from "rxjs";
import { Logger } from "../../core/logger.service";
import { UiConstants } from "../ui-constants";
import { TranslateService } from "@ngx-translate/core";
import { DeviceService } from "src/app/core/device.service";
import { MatomoTracker } from "ngx-matomo-client";
import { FirebaseAuthService } from "src/app/core/firebase/firebase-auth.service";

const logger: Logger = new Logger("TtsService");

export enum CALLER {
    app = "app",
    map = "map",
    math = "math",
    outline = "outline",
    pdf = "pdf",
    notepad = "notepad",
}

@Injectable({
    providedIn: "root",
})
export class TtsService {
    private routes = {
        apiCloudTTS: "/api/cloudtts2gen",
    };

    private enableDebug = false;
    private firstRead = true;

    public isTalking: boolean;

    private speechEngine: any;
    private cancel: boolean;

    private utteranceList: SpeechSynthesisUtterance[];

    public onGetVoices = new EventEmitter<any>();
    public onStart = new EventEmitter<SpeechSynthesisEvent>();
    public onBoundary = new EventEmitter<SpeechSynthesisEvent>();
    public onMark = new EventEmitter<SpeechSynthesisEvent>();
    public isTalkingStateChanged = new EventEmitter<boolean>();
    public TalkingStart = new EventEmitter();
    public TalkingEnd = new EventEmitter();
    public TalkingLoading = new EventEmitter<boolean>();
    public voiceRate: number;
    public voices: SpeechSynthesisVoice[] = [];
    public karaokeEnabled: boolean = false;
    private CloudTTSSubscription: Subscription | undefined;

    constructor(
        private smService: SmService,
        private http: HttpClient,
        private translateService: TranslateService,
        private platform: DeviceService,
        private tracker: MatomoTracker,
        private authService: FirebaseAuthService
    ) {
        // Initialize speech synth (google chrome)
        this.cancel = false;
        this.voiceRate = 1;
        this.isTalking = false;
        this.utteranceList = [];
        this.initializeService();
        console.log("inizializzato tts");
    }

    private initializeService() {
        this.speechEngine = window.speechSynthesis;
        this.initLanguages();
        window.speechSynthesis.onvoiceschanged = () => {
            this.initLanguages();
        };
        this.speechEngine.onerror = (event: any) => {
            console.log("An error has occurred with the speech synthesis: " + event.error);
        };
    }

    public needToTalkMore(): boolean {
        return this.utteranceList.length >= 1;
    }

    private showAllVoices() {
        console.log("TTSService: init languages for useragent " + navigator.userAgent);
        let s = "";
        s += `default;lang;localservice;name;voiceURI\n`;
        this.voices.forEach((v, i) => {
            s += `${v.default};${v.lang};${v.localService};${v.name}; ${v.voiceURI}\n`;
        });
        console.log("TTSService init languages end: total voices " + this.voices.length);
        console.log(s);
    }

    private initLanguages() {
        this.voices = window.speechSynthesis.getVoices();
        if (this.voices) {
            // this.showAllVoices();

            if (this.enableDebug) {
                console.log("TTSService: init languages for useragent " + navigator.userAgent);
            }

            if (this.platform.isMobileOrTabletDevice() || this.platform.isChromeOS()) {
                UiConstants.initLanguagesMobile(this.voices, this.translateService);
                this.filterVoices();
            } else {
                UiConstants.initLanguagesDesktop(this.voices, this.translateService);
                this.filterVoices();
            }

            if (this.enableDebug) {
                console.log("TTSService init languages end: total voices " + this.voices.length);
                this.voices.forEach((v, i) => {
                    console.log("TTSService: Voice " + i + " " + v.name + " " + v.lang);
                });
            }

            this.onGetVoices.emit(this.voices);
            setTimeout(() => {
                this._speak(" ", "it-IT", true, 1);
            });
        } else {
            console.log("TTSService: No voices");
        }
    }

    /**
     *
     * @param v language lang
     * @param l language locale to check
     * @returns true if the language lang is included in language to check
     */
    //OLD
    /*private checkLanguage(v: string, l: string): boolean {
          if (v) {
              if (v.indexOf('_') !== -1)
                  v = v.replaceAll('_', '-');
  
              return v.toLowerCase().includes(l.toLowerCase());
          }
  
          return false;
      }*/

    private checkLanguage(languageName: string, languageTag: string): boolean {
        if (!languageName || !languageTag) return false;

        // Normalizzo il nome della lingua e il tag lingua (case-insensitive)
        const normalizedLanguageName = languageName.toLowerCase();
        const normalizedLanguageTag = languageTag.toLowerCase();

        // Scompongo il tag lingua in due parti se c'è un trattino
        const [langCode, countryCode] = normalizedLanguageTag.split("-");

        // Definisco una regex per cercare il codice lingua come parola separata o delimitata
        const langCodeRegex = new RegExp(`\\b${langCode}\\b`, "i");
        const countryCodeRegex = countryCode ? new RegExp(`\\b${countryCode}\\b`, "i") : null;

        // Controllo se il languageTag è solo il codice lingua (es. "it" senza "-IT")
        if (!countryCode) {
            // Verifichiamo se il nome della lingua contiene solo il codice lingua come parola separata
            return langCodeRegex.test(normalizedLanguageName);
        }

        // Altrimenti verifichiamo codice lingua, codice paese o tag completo
        const fullTagRegex = new RegExp(`\\b${langCode}-${countryCode}\\b`, "i");

        return (
            langCodeRegex.test(normalizedLanguageName) ||
            (countryCodeRegex && countryCodeRegex.test(normalizedLanguageName)) ||
            fullTagRegex.test(normalizedLanguageName)
        );
    }

    private filterVoices() {
        if (this.platform.isMobileOrTabletDevice() || this.platform.isChromeOS()) {
            //PIATTAFORMA ANDROID////////////////////////////////
            if (
                this.platform.isAndroid() ||
                (this.platform.isAndroid() && this.platform.isTabletDevice()) ||
                (this.platform.isAndroid() && this.platform.isDesktopMode()) ||
                this.platform.isChromeOS()
            ) {
                //le voci su android sono varie e dipendono dal dispositivo e a volte non ci sono neanche tutte quelle che supportiamo
                if (this.enableDebug) console.log("TTSService: filter voices Android");
                if (this.voices) {
                    for (let i = this.voices.length - 1; i >= 0; i--) {
                        const v: SpeechSynthesisVoice = this.voices[i];

                        if (this.enableDebug) this.showVoice(v, i);

                        //Questo era un fix per chrome os per togliere le lingue robotiche
                        if (this.platform.isChromeOS() && v.name.toLowerCase().includes("espeak")) {
                            if (this.enableDebug)
                                console.log(
                                    "Deleted voice from list because eSpeak on Chrome OS" +
                                    this.voices[i].name +
                                    " " +
                                    this.voices[i].lang
                                );
                            this.voices.splice(i, 1);
                            continue;
                        }

                        if (this.checkLanguage(v.lang, "it-IT")) {
                        } else if (this.checkLanguage(v.lang, "en-GB")) {
                        } else if (this.checkLanguage(v.lang, "ca-ES")) {
                        } else if (this.checkLanguage(v.lang, "es-ES")) {
                        } else if (this.checkLanguage(v.lang, "fr-FR")) {
                        } else if (this.checkLanguage(v.lang, "de-DE")) {
                        } else {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        }
                    }
                }
                //PIATTAFORMA IOS////////////////////////////////
            } else if (
                this.platform.isIOS() ||
                (this.platform.isIOS() && this.platform.isTabletDevice()) ||
                (this.platform.isIOS() && this.platform.isDesktopMode())
            ) {
                if (this.voices) {
                    for (let i = this.voices.length - 1; i >= 0; i--) {
                        const v = this.voices[i];

                        if (this.enableDebug) this.showVoice(v, i);

                        if (this.checkLanguage(v.lang, "it-IT") && !v.name.includes("Alice")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "en-GB") && !v.name.includes("Daniel")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "ca-ES") && !v.name.includes("Montse")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "es-ES") && !v.name.includes("Mónica")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);

                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "fr-FR") && !v.name.includes("Thomas")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);

                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "de-DE") && !v.name.includes("Anna")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);

                            this.voices.splice(i, 1);
                        } else {
                        }
                    }
                }
            }
            // DESKTOP /////////////////////////////
        } else if (this.platform.isDesktopDevice()) {
            if (this.platform.isSafari() || this.platform.isOsX()) {
                if (this.voices) {
                    for (let i = this.voices.length - 1; i >= 0; i--) {
                        const v = this.voices[i];

                        if (this.enableDebug) this.showVoice(v, i);

                        if (this.checkLanguage(v.lang, "it-IT") && !v.name.includes("Alice")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "en-GB") && !v.name.includes("Daniel")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "ca-ES") && !v.name.includes("Montse")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "es-ES") && !v.name.includes("Mónica")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);

                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "fr-FR") && !v.name.includes("Thomas")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);

                            this.voices.splice(i, 1);
                        } else if (this.checkLanguage(v.lang, "de-DE") && !v.name.includes("Anna")) {
                            if (this.enableDebug)
                                console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);

                            this.voices.splice(i, 1);
                        } else {
                        }
                    }
                }
            } else {
                this.removeInstalled();
            }
        }
    }

    private removeInstalled() {
        if (this.enableDebug) console.log("TTSService: removing installed languages");
        if (this.voices) {
            for (let i = this.voices.length - 1; i >= 0; i--) {
                const v = this.voices[i];
                if (this.enableDebug) console.log("Deleted voice from list " + this.voices[i].name + " " + this.voices[i].lang);
                if (v.localService) {
                    this.voices.splice(i, 1);
                }
            }
        }
    }

    private showVoice(v: any, i: number = -1) {
        console.log("TTSService --------------------------------------------------------");
        if (i !== -1) console.log("Voice #" + i);
        console.log("default: " + v.default);
        console.log("lang: " + v.lang);
        console.log("localService: " + v.localService);
        console.log("name: " + v.name);
        console.log("voiceURI: " + v.voiceURI);
    }

    private clearText(txt: string) {
        txt = txt.replace(/\t/g, " ");
        txt = txt.replace(/\s\s+/g, " ");
        txt = txt.replace(/[\n\r]+/g, " ").trim();
        return txt;
    }

    private isNumeric(str: string): boolean {
        const pattern = /^\d+$/;
        return pattern.test(str.trim());
    }
    private clearTextBrowser(txt: string, language: string) {
        txt = txt.replace(/\t/g, " ");
        txt = txt.replace(/\s\s+/g, " ");
        txt = txt.replace(/[\n\r]+/g, " ").trim();
        const arr = txt.split(" ");
        txt = "";
        for (let i = 0; i < arr.length; i++) {
            const w = arr[i].trim();
            if (this.isNumeric(w)) {
                const sNum = num2Text(w, language);
                txt += sNum + " ";
            } else {
                txt += w + " ";
            }
        }
        return txt;
    }

    // public setTrackingInfo(nChars: number, readType: READ_TYPE, caller: string) {
    //     this.trackInfo = new TrackInfoDto(nChars, readType, caller);
    // }

    public speak(
        caller: CALLER,
        text: string,
        language: string,
        forceSpeaking?: boolean,
        speechRate: number = -1,
        voice?: SpeechSynthesisVoice
    ) {
        console.log(
            `SPEAK TRACKED: caller=${caller}, language=${language}, #chars=${text.length}, forceSpeaking=${forceSpeaking}, speechRate=${speechRate}, voice=${voice?.name}`
        );
        //  window.speechSynthesis.cancel();

        this._speak(text, language, forceSpeaking, speechRate, voice);
        try {
            this.tracker.trackEvent("TTS", caller, language, text.length);
        } catch (e: any) {
            console.error("speakTracked: Matomo error " + e.message);
        }
    }

    private _speak(
        text: string,
        language: string,
        forceSpeaking?: boolean,
        speechRate: number = -1,
        voice?: SpeechSynthesisVoice
    ) {
        if (this.voices.length <= 0) {
            console.log("TTSService: trying to speak but no voices available");
            return;
        }

        if (this.smService.isTTSEnabled() || forceSpeaking) {
            if (speechRate !== -1) {
                if (speechRate == 0) speechRate = 1;
                this.voiceRate = speechRate;
            }

            const lang = UiConstants.findLanguage(language, UiConstants.languages);
            if (this.enableDebug)
                console.log("TTSService: speaking " + text + " in " + language + " with rate " + this.voiceRate);
            if (lang) {
                if (lang.browserTTS) {
                    this.speakBrowserTTS(text, language, voice);
                } else {
                    this.speakCloudTTS(text, language);
                }
            } else {
                console.log("TTSService: trying to speak but no lang found for " + language);
            }
        }
    }

    private speakCloudTTS(text: string, language: string) {
        const context = new AudioContext();

        if (this.enableDebug) console.log("Cloud TTS lang=" + language + " - text=" + text);
        text = this.clearText(text);
        let buf: any;
        if (this.CloudTTSSubscription) {
            this.CloudTTSSubscription.unsubscribe();
        }
        this.TalkingLoading.emit(true);
        this.CloudTTSSubscription = this.requestCloudTTS(text, language).subscribe((resp: any) => {
            this.TalkingLoading.emit(false);
            if (resp && resp.ok && resp.result && resp.result.data) {
                const byteArray = resp.result.data;
                const arrayBuffer = new ArrayBuffer(byteArray.length);
                const bufferView = new Uint8Array(arrayBuffer);
                for (let i = 0; i < byteArray.length; i++) {
                    bufferView[i] = byteArray[i];
                }
                context.decodeAudioData(arrayBuffer, (buffer) => {
                    buf = buffer;
                    // Play the loaded file
                    // Create a source node from the buffer
                    const source = context.createBufferSource();
                    source.buffer = buf;
                    // Connect to the final output node (the speakers)
                    source.connect(context.destination);
                    // Play immediately
                    this.isTalking = true;
                    this.TalkingStart.emit();
                    source.onended = () => {
                        this.isTalking = false;
                        this.TalkingEnd.emit();
                    };
                    source.start(0);
                });
            } else {
                this.isTalking = false;
                this.TalkingEnd.emit();
            }
        });
    }

    private requestCloudTTS(text: string, language: string): any {
        const body = {
            text: text,
            language: language,
        };
        return this.http.post<Response>(this.routes.apiCloudTTS, body, {}).pipe(
            map((res: Response) => res),
            catchError((error: any) => {
                logger.error(error);
                return observableThrowError(() => error);
            })
        );
    }

    private speakBrowserTTS(text: string, language: string, voice?: SpeechSynthesisVoice) {
        // Eccezione per Catalano: siccome non c'è la sintesi Google, forza la voce spagnola
        if (this.checkLanguage(language, "ca-ES")) {
            if (this.enableDebug) console.log("Forced Spanish voice for Catalan speak");
            language = "es-ES";
        }
        //

        //Correzione pronuncia errata per Italiano della lettera y isolata
        if (this.checkLanguage(language, "it-IT")) {
            text = text.replace(/\by\b/gi, "ipsilon");
        }

        if (this.speechEngine) {
            if (this.enableDebug) console.log("Browser TTS lang=" + language + " - text=" + text);
            text = this.clearTextBrowser(text, language);
            console.log("After num2text: " + text);
            if (text !== "") {
                //this.isTalking = true;
                this.cancel = false;
                this.utteranceList = [];
                const utterance = this.createUtterance(text, language, voice);
                this.utteranceList.push(utterance);

                // console.log('Read chunk length = ' + cLength);
                if (this.utteranceList.length >= 1) {
                    this.speakInternal(this.utteranceList[0]);
                }
            } else {
                console.log("TTSService: empty text");
                this.stop();
            }
        }
    }

    private speakInternal(utterance: any) {
        const vRate = this.voiceRate === 0 ? 0.1 : this.voiceRate;

        const cLengthMin = 40;
        const cLengthMax = 220;
        const cLengthBase = 150;
        const cLength = Math.max(cLengthMin, Math.min(cLengthMax, cLengthBase * vRate));
        this.speechEngine.cancel();
        this.TalkingStart.emit();
        this.speechUtteranceChunker(
            utterance,
            {
                chunkLength: cLength,
            },
            () => {
                // Some code to execute when done
                if (this.enableDebug) console.log("speechUtteranceChunker done ");
                const l = this.utteranceList.length > 1 ? this.utteranceList.length - 1 : 1;
                this.utteranceList.splice(0, l);
                if (this.utteranceList.length > 0) {
                    this.speakInternal(this.utteranceList[0]);
                } else {
                    this.TalkingEnd.emit();
                }
            }
        );
    }

    public stop() {
        if (this.speechEngine) {
            this.cancel = true;
            this.isTalking = false;
            this.speechEngine.cancel();
            this.utteranceList = [];
            this.TalkingEnd.emit();
        }
    }

    private createUtterance(text: string, language: string, forceVoice?: SpeechSynthesisVoice): SpeechSynthesisUtterance {
        const utterance = new SpeechSynthesisUtterance();
        utterance.volume = 1; // 0 to 1
        utterance.rate = this.voiceRate; // 0 to 2
        utterance.pitch = 1; // 0 to 2
        utterance.text = text;
        utterance.lang = language;
        this.setUtteranceVoiceByLanguage(utterance, language, forceVoice);
        utterance.onstart = (e: any) => {
            this.isTalking = true;
            this.isTalkingStateChanged.emit(true);
        };
        utterance.onend = (e: any) => {
            //  this.speechEngine.cancel();
            if (this.enableDebug) logger.debug("TTS: Finished in " + e.elapsedTime + " seconds.");
            this.isTalking = false;
            this.isTalkingStateChanged.emit(false);
        };
        utterance.onboundary = (e: any) => {
            this.onBoundary.emit(e);
        };
        utterance.onmark = (e: any) => {
            this.onMark.emit(e);
        };
        utterance.onerror = (e: any) => {
            if (this.enableDebug) logger.debug("An error has occurred with the speech synthesis: " + e.error);
            this.utteranceList = [];
            this.isTalking = false;
            this.isTalkingStateChanged.emit(false);
        };
        return utterance;
    }

    // Select a voice for the specified language (need to be set to use native unchunked speak)
    private setUtteranceVoiceByLanguage(
        utterance: SpeechSynthesisUtterance,
        language: string,
        forceVoice?: SpeechSynthesisVoice
    ) {
        if (!this.voices || this.voices.length === 0) {
            this.voices = this.speechEngine.getVoices();
        }
        let i = 0;
        let found = false;
        if (forceVoice) {
            utterance.voice = forceVoice;
            return;
        }
        if (this.voices) {
            while (!found && i < this.voices.length) {
                const voice: SpeechSynthesisVoice = this.voices[i];
                if (this.checkLanguage(voice.lang, language)) {
                    //} && !voice.localService) { voce brutta ma serve per karaoke. aggiungere selettore voce se si vuole voce bella
                    utterance.voice = voice;
                    found = true;
                } else {
                    i++;
                }
            }
        }
    }

    private speechUtteranceChunker(utt: any, settings: any, callback: any) {
        let timer = 0;
        if (this.firstRead) {
            timer = 5;
            window.speechSynthesis.cancel();
        }
        settings = settings || {};
        let newUtt: any = null;
        const txt = settings && settings.offset !== undefined ? utt.text.substring(settings.offset) : utt.text;
        if (utt.voice && utt.voice.localService) {
            // utt.voice.voiceURI === 'native') { // Not part of the spec
            newUtt = utt;
            newUtt.text = txt;
            newUtt.addEventListener("end", () => {
                if (this.cancel) {
                    this.cancel = false;
                }
                if (callback !== undefined) {
                    callback();
                }
            });
        } else {
            const chunkLength = (settings && settings.chunkLength) || 160;
            const pattRegex = new RegExp(
                "^[\\s\\S]{" +
                Math.floor(chunkLength / 2) +
                "," +
                chunkLength +
                "}[.!?,]{1}|^[\\s\\S]{1," +
                chunkLength +
                "}$|^[\\s\\S]{1," +
                chunkLength +
                "} "
            );
            const chunkArr = txt.match(pattRegex);

            if (!chunkArr || chunkArr[0] === undefined) {
                // Call once all text has been spoken...
                if (callback !== undefined) {
                    callback();
                }
                return;
            }
            // this.speechEngine.cancel();
            const chunk = chunkArr[0];
            newUtt = new window.SpeechSynthesisUtterance();
            newUtt.volume = utt.volume;
            newUtt.rate = utt.rate;
            newUtt.pitch = utt.pitch;
            newUtt.text = chunk;
            newUtt.lang = utt.lang;
            newUtt.voice = utt.voice;
            newUtt.onstart = utt.onstart;
            newUtt.onend = utt.onend;
            newUtt.onerror = utt.onerror;
            newUtt.addEventListener("boundary", (event: SpeechSynthesisEvent) => {
                this.onBoundary.emit(event);
                if (this.enableDebug)
                    console.log(
                        `${event.name} boundary reached after ${event.elapsedTime} seconds.` +
                        `${event.name} boundary reached after ${event.charIndex} charindex.` +
                        `${event.name} boundary reached after ${event.charLength} length.`
                    );
            });
            newUtt.addEventListener("mark", (event: SpeechSynthesisEvent) => {
                this.onMark.emit(event);
            });
            // Better this, but it doesn't work!
            for (const x in utt) {
                if (utt[x] && x !== "text") {
                    // if (utt.hasOwnProperty(x) && x !== 'text') {
                    newUtt[x] = utt[x];
                }
            }
            newUtt.addEventListener("end", () => {
                if (this.cancel) {
                    this.cancel = false;
                    return;
                }
                settings.offset = settings.offset || 0;
                settings.offset += chunk.length;
                this.speechUtteranceChunker(utt, settings, callback);
            });
        }

        if (settings.modifier) {
            settings.modifier(newUtt);
        }
        // IMPORTANT!! Do not remove: Logging the object out fixes some onend firing issues.
        console.log(newUtt);
        //******************************************* */

        // placing the speak invocation inside a callback fixes ordering and onend issues.
        if (this.firstRead) {
            this.firstRead = false;

            this.speechEngine.speak(newUtt);
            // window.speechSynthesis.cancel();
        }
        setTimeout(() => {
            this.speechEngine.speak(newUtt);
        }, timer);
    }
}

function num2Text(strNum: string, lang: string): string {
    let num = 0;
    let resto;
    let vsc = -1;
    let vst2 = "";
    let vst3 = "";
    let vst4 = "";
    let vst5 = "";
    let n0 = 0;
    let n1 = 0;
    let n2 = 0;
    let fl;
    let lettere: Array<string> = [];
    let vst0 = "";
    //
    const numeri = new Array(28);
    for (let i = 0; i <= 20; i++) {
        numeri[i] = i;
    }
    numeri[21] = 30;
    numeri[22] = 40;
    numeri[23] = 50;
    numeri[24] = 60;
    numeri[25] = 70;
    numeri[26] = 80;
    numeri[27] = 90;
    numeri[28] = 1000;

    if (lang === "it-IT" || lang === "it") {
        lang = "it";
    } else {
        lang = "en";
    }

    if (lang == "it") {
        lettere = [
            "zero",
            "uno",
            "due",
            "tre",
            "quattro",
            "cinque",
            "sei",
            "sette",
            "otto",
            "nove",
            "dieci",
            "undici",
            "dodici",
            "tredici",
            "quattordici",
            "quindici",
            "sedici",
            "diciassette",
            "diciotto",
            "diciannove",
            "venti",
            "trenta",
            "quaranta",
            "cinquanta",
            "sessanta",
            "settanta",
            "ottanta",
            "novanta",
            "mille",
        ];
    } else {
        lettere = [
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "ten",
            "eleven",
            "twelve",
            "thirteen",
            "fourteen",
            "fifteen",
            "sixteen",
            "seventeen",
            "eighteen",
            "nineteen",
            "twenty",
            "thirty",
            "forty",
            "fifty",
            "sixty",
            "seventy",
            "eighty",
            "ninety",
            "thousand",
        ];
    }
    strNum = strNum.replace(",", ".");
    // resto = parseFloat(strNum) - parseInt(strNum);
    num = parseInt(strNum);
    vsc = cerca(num, numeri);
    if (vsc == -1) {
        if (num >= 1000000000) {
            n0 = Math.floor(num / 1000000000);
            dividi();
            num = num % 1000000000;
            if (n1 == 1) vst5 = lang === "it" ? "un miliardo" : "one billion";
            else vst5 = vst0 + (lang === "it" ? "miliardi" : "billions");
        }
        if (num >= 1000000) {
            n0 = Math.floor(num / 1000000);
            dividi();
            num = num % 1000000;
            if (n1 == 1) vst4 = lang === "it" ? "un milione" : "one million";
            else vst4 = vst0 + (lang === "it" ? "milioni" : "millions");
        }
        if (num >= 1000) {
            n0 = Math.floor(num / 1000);
            dividi();
            num = num % 1000;
            if (n1 == 1 && n2 == 0) vst3 = lang === "it" ? "mille" : "one thousand";
            else vst3 = vst0 + (lang === "it" ? "mila" : "thousands");
        }
        if (num > 0 && num < 1000) {
            n0 = num;
            dividi();
            vst2 = vst0;
        }
        fl = vst5 + vst4 + vst3 + vst2;
    } else {
        fl = lettere[vsc];
    }
    return fl;

    function cerca(num: number, arrNum: Array<number>) {
        for (let i = 0; i < arrNum.length; i++) {
            if (num === arrNum[i]) return i;
        }
        return -1;
    }

    function dividi() {
        let nd;
        n1 = n0 % 100;
        n0 = Math.floor(n0 / 100);
        n2 = n0 % 10;
        n0 = Math.floor(n0 / 10);
        if (n2 != 0) {
            vsc = cerca(n2, numeri);
            if (n2 == 1) vst0 = lang === "it" ? "cento" : "one hundred";
            else vst0 = lettere[vsc] + (lang === "it" ? "cento" : "hundred");
        }
        if (n1 != 0) {
            vsc = cerca(n1, numeri);
            if (vsc == -1) {
                nd = Math.floor(n1 / 10) * 10;
                vsc = cerca(nd, numeri);
                nd = n1 - nd;
                if (nd == 1 || nd == 8) vst0 = vst0 + lettere[vsc].substring(0, lettere[vsc].length - 1);
                else vst0 = vst0 + lettere[vsc];

                vsc = cerca(nd, numeri);

                vst0 = vst0 + lettere[vsc];
            } else {
                vst0 = vst0 + lettere[vsc];
            }
        }
    }
}
