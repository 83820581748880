<div [formGroup]="parentForm">
    <div class="admin-card" *ngIf="canEditDomain">
        <mat-card-content [ngClass]="(compressed ? 'access-admin-compressed'  : 'access-domain')">
            <div class="domain-line">
                <span class="left-col" translate>ACCESSCONFIG_DOMAIN</span>
                <span>&#64;</span>
                <!-- <mat-form-field appearance="outline"> -->
                <input formControlName="domain" mat-input type="text" placeholder="{{'ACCESSCONFIG_DOMAIN_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" class="map-input-name" />

                <!-- </mat-form-field> -->
            </div>
            <div class="domain-line ">
                <div class="left-col">
                    <span *ngIf="f.domain.errors" class="invalid-feedback">
                        <span translate>NOT_VALID_DOMAIN_MSG</span>
                    </span>
                    <span *ngIf="!f.domain.errors">
                        <span></span>
                    </span>
                </div>
                <span class="access-desc" translate>ACCESSCONFIG_DOMAIN_DESC</span>
                <!-- <br /><br /> -->
            </div>
            <div class="domain-line">
                <div class="left-col">
                    <span *ngIf="showDomainLics" translate>ACCESSCONFIG_DOMAINLICS</span>
                    <span *ngIf="showDomainLics"></span>
                </div>
                <!-- <mat-form-field appearance="outline" *ngIf="showDomainLics"> -->

                <input *ngIf="showDomainLics" formControlName="domainLics" mat-input type="number" autocomplete="off" spellcheck="false" class="map-input-name" />
                <!-- </mat-form-field> -->
            </div>
            <div class="domain-line">
                <div class="left-col">
                    <span *ngIf="f.domainLics.errors && showDomainLics" class="invalid-feedback2">
                        <span translate>NOT_VALID_DOMAINLICS_MSG</span>
                    </span>
                    <span *ngIf="!f.domainLics.errors && showDomainLics">
                        <span></span>
                    </span>
                </div>

                <span *ngIf="showDomainLics" class="access-desc2" translate>ACCESSCONFIG_DOMAINLICS_DESC</span>
            </div>
        </mat-card-content>

    </div>
    <div class="admin-card">
        <mat-card-content [ngClass]="(compressed ? 'access-admin-compressed'  : 'access-admin')">
            <span class="left-col" [hidden]="isSingleUser" translate>ACCESSCONFIG_USERADMIN</span>
            <span [hidden]="!isSingleUser" translate>ACCESSCONFIG_USERADMIN_SINGLE</span>
         
            <!-- <mat-form-field appearance="outline"> -->
            <input formControlName="adminEmail" mat-input type="email" placeholder="{{'ACCESSCONFIG_USERADMIN_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" class="map-input-name" />
            <!-- </mat-form-field>
        <mat-form-field appearance="outline"> -->
            <input formControlName="adminName" mat-input class="map-input-name" type="text" placeholder="{{'ACCESSCONFIG_USERS_NAME_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" />
            <!-- </mat-form-field>
        <mat-form-field appearance="outline"> -->
            <input formControlName="adminNotes" mat-input class="map-input-name" type="text" placeholder="{{'ACCESSCONFIG_USERS_NOTE_PLACEHOLDER' | translate}}" autocomplete="off" spellcheck="false" />
            <!-- </mat-form-field> -->
            <span></span>

            <span *ngIf="f.adminEmail.errors" class="invalid-feedback">
                <span *ngIf="f['adminEmail'].errors['required']" translate>FIELD_REQUIRED</span>
                <span *ngIf="f.adminEmail.errors['email']" translate>NOT_VALID_EMAIL_MSG</span>
            </span>
            <span *ngIf="f.adminName.errors && !f.adminEmail.errors" class="invalid-feedback">
                <span translate>FIELD_REQUIRED</span>
            </span>
            <span *ngIf="f.adminNotes.errors && !f.adminName.errors && !f.adminEmail.errors" class="invalid-feedback">
                <span translate>FIELD_REQUIRED</span>
            </span>

        </mat-card-content>
        <span [hidden]="isSingleUser" class="access-desc" translate>ACCESSCONFIG_USERADMIN_DESC</span>
        <span [hidden]="!isSingleUser" class="access-desc" translate>ACCESSCONFIG_USERADMIN_DESC_SINGLE</span>
    </div>
</div>
